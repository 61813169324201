import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { getCatalogue, getResourceData } from '../../../api/books';
import { Navigation, PlayerCatalogue, PlayerPanel, PlayerSlider } from '../../../ebooks-components';
import './player-screen.css';
import Marquee from 'react-fast-marquee';
import { back, home } from '../../../assets';
import { getParam } from '../../../utils/event';
let page = 1;
let totalPage = null;
let init = false;
const Player = () => {
    const [boxHeight, setBoxHeight] = useState(0);
    const [catalogue, setCatalogue] = useState([]);
    const [catalogueStatus, setCatalogueStatus] = useState(false);
    const [resfresh, setResfresh] = useState(0);
    const [curIndex, setCurIndex] = useState(0);
    const [play, setPlay] = useState(false);
    const dataSource = JSON.parse(sessionStorage.getItem('BOOK-INFO'));
    const Id = dataSource?.id || dataSource?.resourceIDs?.audio || dataSource?.bookId;
    const bookType = getParam({ url: window.location.href, title: 'bookType' }) || getParam({ url: window.location.href, title: 'booktype' });
    const type = getParam({ url: window.location.href, title: 'type' });
    const apiKey = getParam({ url: window.location.href, title: 'apiKey' }) || getParam({ url: window.location.href, title: 'apikey' });

    function checkVal(val) {
        return String(val).indexOf('#') !== -1 ? String(val).split('#')[0] : val;
    }

    // const bookType = dataSource?.resourceIDs?.audio ? 2 : dataSource?.resourceIDs?.read ? 1 : 2;
    function toBlack() {
        const bookType = String(window.location.search).substring(1, String(window.location.search).length).split('=');
        document.referrer === '' ?
            window.location.href = `/?bookType=${bookType[1] || 2}` :
            window.history.back();
    }

    function toHome() {
        // const bookType = String(window.location.search).substring(1, String(window.location.search).length).split('=');
        window.location.href = `/?bookType=${bookType}${type ? `&type=${checkVal(type)}` : ``}${apiKey ? `&apiKey=${checkVal(apiKey)}` : ``}`;
    }

    async function _getCatalogue() {
        if (totalPage ? totalPage >= page : true) {
            try {
                let results = await getCatalogue({
                    uniqueID: Id,
                    page,
                    pageSize: 20,
                    bookType
                })
                if (results.code === 200) {
                    totalPage = results.data?.page?.totalPage;
                    _getResource({ id: Id, chapterID: results.data?.chapterList?.[curIndex]?.chapterId });
                    setCatalogue([...catalogue, ...results.data.chapterList]);
                } else {
                    console.error(results.msg);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function _getResource({ id, chapterID }) {
        try {
            let results = await getResourceData({
                id,
                chapterID,
                bookType
            })
            if (results.code === 200) {
                setAudio({ url: results.data.content.url });
            } else {
                console.error(results.msg);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function setAudio({ url }) {
        const audio = document.getElementById("myAudio");
        audio.src = url;
    }

    function toPause() {
        document.getElementById('panel-pause').style.display = 'block';
        document.getElementById('panel-player').style.display = 'none';
    }

    useEffect(() => {
        if (curIndex) {
            _getResource({ id: Id, chapterID: catalogue?.[curIndex]?.chapterID })
        }
    }, [curIndex])

    useEffect(() => {
        if (dataSource?.chapterIndex && !init) {
            setCurIndex(catalogue?.[dataSource?.chapterIndex] ? dataSource?.chapterIndex : 0);
            init = true;
        }
    }, [dataSource])

    useEffect(() => {
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ close: 'false' }))
        _getCatalogue();
        const Navigation_DOM = document.getElementById('navigation');
        if (Navigation_DOM) {
            setBoxHeight(parseFloat(Navigation_DOM.style.height));
        }
    }, [])
    const title = dataSource?.subtitle || dataSource.title;
    return <div className='player-main'>
        <header>
            <div onClick={toBlack} className='header-back'>
                <img src={back} className='back' />
            </div>
            <div className='title'>
                {title}
            </div>
            <div onClick={toHome} className='header-home'>
                <img src={home} className='home' />
            </div>
        </header>
        <div className='player-box' style={{
            height: `${window.innerHeight - boxHeight}px`
        }}>
            <div className='player-cover-main'>
                <div className={`player-cover ${play && 'rotate-animtion'}`} style={{
                    backgroundImage: `url(${dataSource?.cover})`
                }} />
            </div>
            {String(catalogue?.[curIndex]?.name).length > 10 ? (
                <Marquee
                    gradientColor={'rgba(0,0,0,0)'}
                    style={{ zIndex: 0, width: '50%' }}
                >
                    <div className='player-diversity-name'>
                        {catalogue?.[curIndex]?.name}
                    </div>
                </Marquee>
            ) : (
                <div className='player-diversity-name'>
                    {catalogue?.[curIndex]?.name}
                </div>
            )}
            {useMemo(() => {
                return <PlayerSlider resfresh={resfresh} />
            }, [resfresh])}
            {useMemo(() => {
                return <PlayerPanel id={Id} onStop={() => {
                    setPlay(false);
                    setResfresh(new Date().getTime());
                }} openCatalogue={() => {
                    setCatalogueStatus(true)
                }} onPlay={setPlay} play={play} curIndex={curIndex} onSwitch={setCurIndex} maxLong={catalogue?.length} onResfresh={() => {
                    setResfresh(new Date().getTime())
                }} />
            }, [curIndex, catalogue, play, Id])}
        </div>

        <audio id="myAudio" style={{ display: 'none' }}>
            <source src="horse.mp3" type="audio/mpeg" />
        </audio>
        {catalogueStatus && (
            <PlayerCatalogue
                onMore={() => {
                    page += 1;
                    _getCatalogue()
                }}
                setCurIndex={(event) => {
                    setCurIndex(event);
                    setCatalogueStatus(false);
                    toPause();
                }}
                curIndex={curIndex}
                dataSource={catalogue}
                onClose={() => {
                    setCatalogueStatus(false);
                }}
            />
        )}
    </div>
}
export default Player