// import { message } from 'antd';
import { useState, useEffect } from 'react';
import { getCatalogue } from '../../api/books';
import { catalogue_desc, catalogue_order } from '../../assets';
import { pullEnd, pullMove, pullStart } from '../../utils/touch';
import './catalogue.css';
let page = 1;
let pageSize = 20;
const Catalogue = ({ setResource, type, apiKey, dataSources }) => {
    const [sort, setSort] = useState(false);//true：当前为正序  false：当前为倒序
    const [list, setList] = useState();
    const [pageList, setPageList] = useState();
    const [contentHeight, setContentHeight] = useState();
    const bookType = sessionStorage.getItem('BOOKTYPE') || 1;
    const dataSource = dataSources || JSON.parse(sessionStorage.getItem('BOOK-INFO'));
    async function _getCatalogue(page, pageSize) {
        if (pageList) {
            if (pageList?.totalPage <= pageList?.page) return false;
        }
        try {
            let results = await getCatalogue({
                page,
                pageSize,
                uniqueID: dataSource.bookId,
                bookType
            });
            if (results.code === 200) {
                setPageList(results.data.page);
                if (!list) {
                    setList(results.data.chapterList);
                } else {
                    if (sort) {
                        let array = [];
                        results.data.chapterList.map((item, index) => {
                            array.push(results.data.chapterList[results.data.chapterList.length - 1 - index]);
                            if (results.data.chapterList.length - 1 === index) {
                                setList([...array, ...list]);
                                document.getElementById('catalogue-modal').scrollTo(0, 0);
                            }
                        })
                    } else {
                        setList([...list, ...results.data.chapterList]);
                    }
                }
            } else {
                // message.error(results.msg)
                console.error(results.msg)
            }
        } catch (error) {
            console.error(error);
        }
    }
    function sortEvent() {
        setSort(!sort);
        let array = [];
        list.map((item, index) => {
            array.push(list[list.length - 1 - index]);
            if (index === list.length - 1) {
                setList(array);
            }
        })
    }
    useEffect(() => {
        _getCatalogue(page, pageSize);
        setContentHeight(window.innerHeight - document.getElementById('catalogue-modal').offsetTop);
    }, [])
    return <div
        className='catalogue-main'
        id='catalogue-modal'
        style={{
            height: `${contentHeight}px`
        }}
        onTouchStart={pullStart}
        onTouchMove={pullMove}
        onTouchEnd={(event) => { pullEnd(event, _getCatalogue, pageList?.page || page, pageSize) }}
    >
        <div className='catalogue-head'>
            <div className='catalogue-label'>
                目录
            </div>
            <div className='catalogue-opertion' onClick={sortEvent}>
                <img src={sort ? catalogue_desc : catalogue_order} className='opertion-icon' />
                <div className='opertion-label'>
                    {sort ? '正序' : '倒序'}
                </div>
            </div>
        </div>
        <div className='catalogue-content'>
            {list?.map((item, index) => {
                return <div
                    onClick={() => {
                        if (type === 'h5') {
                            if (bookType === '2') {
                                dataSource.chapterIndex = index;
                                sessionStorage.setItem('BOOK-INFO', JSON.stringify(dataSource));
                                window.location.href = `/#/Player?bookType=${bookType}&type=${type}${apiKey ? `&apiKey=${apiKey}` : ``}`
                            } else {
                                setResource(item.chapterID)
                            }
                        } else {
                            setResource(item.chapterID)
                        }
                        // sessionStorage.setItem('BOOK-INFO', JSON.stringify(item));
                    }}
                    key={`catalogue-item-${index}`}
                    className={
                        index === 0 ?
                            'list-item-first' :
                            'list-item'
                    }
                >
                    {item.name}
                </div>
            })}
        </div>
    </div>
}
export default Catalogue