"use strict";
// #if B2_ENABLE_PARTICLE
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullScreenUI = void 0;
/**
 * Handles drawing and selection of full screen UI.
 */
var FullScreenUI = /** @class */ (function () {
    function FullScreenUI() {
        /**
         * Whether particle parameters are enabled.
         */
        this.m_particleParameterSelectionEnabled = false;
        this.Reset();
    }
    /**
     * Reset the UI to it's initial state.
     */
    FullScreenUI.prototype.Reset = function () {
        this.m_particleParameterSelectionEnabled = false;
    };
    /**
     * Enable / disable particle parameter selection.
     */
    FullScreenUI.prototype.SetParticleParameterSelectionEnabled = function (enable) {
        this.m_particleParameterSelectionEnabled = enable;
    };
    /**
     * Get whether particle parameter selection is enabled.
     */
    FullScreenUI.prototype.GetParticleParameterSelectionEnabled = function () {
        return this.m_particleParameterSelectionEnabled;
    };
    return FullScreenUI;
}());
exports.FullScreenUI = FullScreenUI;
// #endif
