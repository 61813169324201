/** 网站宽度 */
const GWidth = document.body.offsetWidth;
const GHeight = document.body.offsetHeight;
function SystemDevice() {//检测设备类型
    if (window.orientation === 90) {//平板
        return 'ipad'
    } else if (GWidth > GHeight && GWidth / GHeight > 2) {//电脑
        return 'pc';
    } else if (GHeight > GWidth) {//手机
        return 'phone'
    }
}
/** 比例 = 物理像素 / 设计图比例 */
const WLR = GWidth / ((SystemDevice() === 'ipad') ? 1800 : 900);
const MaxRow = (SystemDevice() === 'ipad') ? 6 : 3;
const WH = [];
// alert(SystemDevice());
function reckon(type, number) {
    if (type === 'ipad') {
        return number / 2.3;
    } else if (type === 'pc') {
        return number / 3
    } else if (type === 'phone') {
        return number / 1;
    }
}
const toWLR = (number) => {
    return number * WLR;
}
const StyleShell = (obj) => {
    function setHeightFitContent(obj, keyMian, key) {
        let keyMainArray = ['catalogueMainBox'];
        if (keyMainArray.indexOf(keyMian) !== -1 && SystemDevice(WH) === 'ipad' && key === 'height') {
            obj[keyMian]['height'] = 'fit-content';
        }
    }
    for (const keyMian in obj) {
        for (const key in obj[keyMian]) {
            let flag = false;
            setHeightFitContent(obj, keyMian, key);
            if (key === 'fontSize') {
                flag = false;
            }
            if (flag) {
                obj[keyMian]['lineHeight'] = (obj[keyMian]['fontSize'] + 34) * WLR;
            } else if (typeof obj[keyMian][key] === 'number') {
                if (keyMian.substring(key.length - 4) === 'isNo') {
                    obj[keyMian][key] *= WLR;
                } else {
                    obj[keyMian][key] = obj[keyMian][key] * WLR;
                }
            }
        }
    }
    return obj;
}
export {
    StyleShell,
    toWLR,
    SystemDevice,
    WLR,
    MaxRow
}