import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { request } from '../../scripts';
import BookCard from '../book-card';
import CardInfo from '../card-info';
import './index.scss';
let timer = null,
    tmp_count = 0,
    speed = 30,//15 ~ 19
    max_bottom = 100000,
    tmp_columns = [];
function Rendercard({ dataSource, onClick }) {
    let tmpArr = [];
    return dataSource.map((item, index) => {
        if ((index + 1) % 3 === 0) {
            tmpArr.push(item)
            return <div key={`book-card-${index}`} className={'row'}>
                {tmpArr.map((items, indexs) => {
                    if (indexs === tmpArr.length - 1) {
                        tmpArr = [];
                    }
                    return <BookCard
                        data={items}
                        current={indexs}
                        style={indexs % 2 !== 0 ? {
                            marginTop: `15px`
                        } : {}}
                        key={`book-card-${index}-${indexs}`}
                        onClick={() => onClick(items)}
                    />
                })}
            </div>
        } else {
            tmpArr.push(item)
        }
    })
}
export default function Waterfall() {
    const scrollRef = useRef();
    const [params, setParams] = useState(localStorage.getItem('WATERFALL-PAGE') ? JSON.parse(localStorage.getItem('WATERFALL-PAGE')) : null);
    const [columns, setColumns] = useState(localStorage.getItem('WATERFALL-SOURCE') ? JSON.parse(localStorage.getItem('WATERFALL-SOURCE')) : []);
    const [checked, setChecked] = useState();
    /*
* JSON数组去重
* @param: [array] json Array
* @param: [string] 唯一的key名，根据此键名进行去重
*/
    function uniqueArray(array, key) {
        var result = [array[0]];
        for (var i = 1; i < array.length; i++) {
            var item = array[i];
            var repeat = false;
            for (var j = 0; j < result.length; j++) {
                if (item[key] === result[j][key]) {
                    repeat = true;
                    break;
                }
            }
            if (!repeat) {
                result.push(item);
            }
        }
        return result;
    }
    function fetchMoreData() {
        let page = params?.page || 0;
        let pageSize = params?.pageSize || 20;
        let totalPage = params?.totalPage || 500;
        if (page && totalPage) {
            if (parseInt(page) < parseInt(totalPage)) {
                getRespones({ page: parseInt(page) + 1, pageSize: parseInt(pageSize), id: 93, bookType: Math.ceil(Math.random() * 2) })
            } else {
                max_bottom = scrollRef.current.el.scrollTop;
                clearInterval(timer)
                init();
                // setColumns([...columns, ...columns]);
            }
        }
    }
    function init() {
        timer = setInterval(() => {
            tmp_count = scrollRef.current._infScroll.scrollTop || 0;
            tmp_count = tmp_count + 1;
            if (max_bottom && tmp_count >= max_bottom) {
                tmp_count = 0;
                scrollRef.current._infScroll.scrollTop = 0;
            } else {
                scrollRef.current._infScroll.scrollTop = tmp_count;
            }
        }, speed)
    }
    function onTouchStart() {
        clearInterval(timer);
    }
    function onTouchEnd() {
        clearInterval(timer);
        init();
    }
    function getRespones({ page, pageSize, bookType, id }) {
        return new Promise((success) => {
            if ((localStorage.getItem('WATERFALL-PAGE') ? JSON.parse(localStorage.getItem('WATERFALL-PAGE')).page : 0) >= page) return;
            request({
                url: `https://nest.shijizhitu.com/aggregation/category/29`,
                method: 'GET',
                params: {
                    libraryCode: '667a56fe529e516c533a',
                    page,
                    pageSize,
                    bookType,
                    id
                }
            }).then(res => {
                res = res.data;
                tmp_columns = [...tmp_columns, ...res.bookList]
                let data = uniqueArray(tmp_columns, 'title');
                // let data = [...columns, ...res.bookList, ...res.bookList];
                // console.log(data);
                setColumns(data);
                localStorage.setItem('WATERFALL-PAGE', JSON.stringify(res.page));
                localStorage.setItem('WATERFALL-SOURCE', JSON.stringify(data))
                setParams(res.page);
                success(true)
            }).catch(() => {
                success(true)
            })
        })
    }
    useEffect(() => {
        clearInterval(timer);
        getRespones({ page: 1, pageSize: 60, bookType: 2, id: 93 }).then(() => {
            getRespones({ page: 2, pageSize: 60, bookType: 2, id: 93 }).then(() => {
                getRespones({ page: 3, pageSize: 60, bookType: 2, id: 93 });
            })
        })
    }, [])
    useEffect(() => {
        if (checked) {
            clearInterval(timer);
        } else {
            clearInterval(timer);
            init();
        }
    }, [checked])
    return <React.Fragment>
        <div
            className='waterfall-model'
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
        >
            <InfiniteScroll
                ref={scrollRef}
                dataLength={columns.length}
                hasMore={true}
                next={fetchMoreData}
                className='waterfall-container'
                height={"340.036563071298vw"}
                style={{
                    height: `340.036563071298vw`
                }}
            >
                <Rendercard dataSource={columns} onClick={setChecked} />
            </InfiniteScroll>
        </div>
        {checked && <CardInfo data={checked} onClose={() => setChecked(undefined)} />}
    </React.Fragment>
}