"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var b2 = require("./b2");
var settings_1 = require("./settings");
var event_1 = require("./event");
var draw_1 = require("./draw");
var bubble_1 = require("./common/bubble");
require("./styles/index.css");
var Main = /** @class */ (function () {
    function Main(time) {
        /** FPS 帧数 */
        // const fps_div: HTMLDivElement = this.m_fps_div = document.body.appendChild(document.createElement("div"));
        // fps_div.style.position = "absolute";
        // fps_div.style.left = "0px";
        // fps_div.style.bottom = "0px";
        // fps_div.style.backgroundColor = "rgba(0,0,255,0.75)";
        // fps_div.style.color = "white";
        // fps_div.style.font = "10pt Courier New";
        // fps_div.style.zIndex = "256";
        // fps_div.style.userSelect = "none";
        // fps_div.innerHTML = "FPS";
        var _this = this;
        var _a;
        this.m_time_last = 0;
        this.m_fps_time = 0;
        this.m_fps_frames = 0;
        this.m_fps = 0;
        // public m_fps_div: HTMLDivElement;
        this.m_settings = new settings_1.Settings();
        this.m_lMouseDown = false;
        this.m_rMouseDown = false;
        this.m_projection0 = new b2.Vec2();
        this.m_viewCenter0 = new b2.Vec2();
        this.m_ctx = null;
        //todo 设置背景颜色
        document.body.style.backgroundColor = "rgba(51, 51, 51, 1.0)";
        /** 页面整体 */
        var main_div = document.body.appendChild(document.createElement("div"));
        main_div.style.position = "absolute"; // relative to document.body
        main_div.style.left = "0px";
        main_div.style.top = "0px";
        /** 重新设置整体尺寸 */
        function resize_main_div() {
            main_div.style.width = window.innerWidth + "px";
            main_div.style.height = window.innerHeight + "px";
        }
        window.addEventListener("resize", function (e) { resize_main_div(); });
        window.addEventListener("orientationchange", function (e) { resize_main_div(); });
        resize_main_div();
        var view_div = main_div.appendChild(document.createElement("div"));
        //todo Canvas画布
        var canvas_div = this.m_canvas_div = view_div.appendChild(document.createElement("div"));
        canvas_div.style.position = "absolute";
        canvas_div.style.left = "0px";
        canvas_div.style.right = "0px";
        canvas_div.style.top = "0px";
        canvas_div.style.bottom = "0px";
        var canvas_2d = this.m_canvas_2d = canvas_div.appendChild(document.createElement("canvas"));
        function resize_canvas() {
            if (canvas_2d.width !== canvas_div.clientWidth) {
                draw_1.g_camera.m_width = canvas_2d.width = canvas_div.clientWidth;
            }
            if (canvas_2d.height !== canvas_div.clientHeight) {
                draw_1.g_camera.m_height = canvas_2d.height = canvas_div.clientHeight;
            }
        }
        window.addEventListener("resize", function (e) { resize_canvas(); });
        window.addEventListener("orientationchange", function (e) { resize_canvas(); });
        resize_canvas();
        draw_1.g_debugDraw.m_ctx = this.m_ctx = this.m_canvas_2d.getContext("2d");
        var test_select = document.createElement("select");
        var test_options = [];
        for (var i = 0; i < event_1.g_testEntries.length; ++i) {
            var option = document.createElement("option");
            option.text = "".concat(event_1.g_testEntries[i].category, ":").concat(event_1.g_testEntries[i].name);
            option.value = i.toString();
            test_options.push(option);
        }
        test_options.sort(function (a, b) { return a.text.localeCompare(b.text); });
        for (var i = 0; i < test_options.length; ++i) {
            var option = test_options[i];
            test_select.add(option);
        }
        test_select.selectedIndex = this.m_settings.m_testIndex = 77;
        test_select.addEventListener("change", function (e) {
            _this.m_settings.m_testIndex = test_select.selectedIndex;
            _this.LoadTest();
        });
        this.m_test_select = test_select;
        this.m_test_options = test_options;
        //todo 禁止邮件菜单
        window.addEventListener("contextmenu", function (e) { e.preventDefault(); }, true);
        //todo 鼠标操作事件
        canvas_div.addEventListener("mousemove", function (e) { _this.HandleMouseMove(e); });
        canvas_div.addEventListener("mousedown", function (e) { _this.HandleMouseDown(e); });
        canvas_div.addEventListener("mouseup", function (e) { _this.HandleMouseUp(e); });
        //todo 手指操作事件
        canvas_div.addEventListener("touchmove", function (e) { _this.HandleTouchMove(e); });
        canvas_div.addEventListener("touchstart", function (e) { _this.HandleTouchStart(e); });
        canvas_div.addEventListener("touchend", function (e) { _this.HandleTouchEnd(e); });
        this.LoadTest();
        this.m_time_last = time;
        //todo 获取网络数据
        (_a = this.m_test) === null || _a === void 0 ? void 0 : _a.getData(1);
        var params = new URLSearchParams(window.location.href.split('?')[1]);
        //todo 背景
        var bg = document.createElement('div');
        bg.style.backgroundSize = '100% 100%';
        bg.style.backgroundRepeat = 'not-repeat';
        bg.style.backgroundImage = "url(./bg_".concat(params.get('bg') || 1, ".png)");
        bg.style.position = 'fiexd';
        bg.style.width = '100%';
        bg.style.height = '100%';
        bg.style.top = '0';
        bg.style.left = '0';
        document.body.appendChild(bg);
    }
    Main.prototype.HandleMouseMove = function (e) {
        var element = new b2.Vec2(e.clientX, e.clientY);
        var world = draw_1.g_camera.ConvertScreenToWorld(element, new b2.Vec2());
        if (this.m_lMouseDown) {
            if (this.m_test) {
                this.m_test.MouseMove(world);
            }
        }
    };
    Main.prototype.HandleMouseDown = function (e) {
        var element = new b2.Vec2(e.clientX, e.clientY);
        var world = draw_1.g_camera.ConvertScreenToWorld(element, new b2.Vec2());
        if (e.button === 0) {
            this.m_lMouseDown = true;
            if (this.m_test) {
                this.m_test.MouseDown(world);
            }
            return;
        }
    };
    Main.prototype.HandleMouseUp = function (e) {
        var element = new b2.Vec2(e.clientX, e.clientY);
        var world = draw_1.g_camera.ConvertScreenToWorld(element, new b2.Vec2());
        if (e.button === 0) {
            this.m_lMouseDown = false;
            if (this.m_test) {
                this.m_test.MouseUp(world);
            }
        }
    };
    Main.prototype.HandleTouchMove = function (e) {
        var element = new b2.Vec2(e.touches[0].clientX, e.touches[0].clientY);
        var world = draw_1.g_camera.ConvertScreenToWorld(element, new b2.Vec2());
        if (this.m_test) {
            this.m_test.MouseMove(world);
        }
        e.preventDefault();
    };
    Main.prototype.HandleTouchStart = function (e) {
        var element = new b2.Vec2(e.touches[0].clientX, e.touches[0].clientY);
        var world = draw_1.g_camera.ConvertScreenToWorld(element, new b2.Vec2());
        if (this.m_test) {
            this.m_test.MouseDown(world);
        }
        e.preventDefault();
    };
    Main.prototype.HandleTouchEnd = function (e) {
        if (this.m_test) {
            this.m_test.MouseUp(this.m_test.m_mouseWorld);
        }
        e.preventDefault();
    };
    Main.prototype.LoadTest = function (restartTest) {
        if (restartTest === void 0) { restartTest = false; }
        event_1.Test.fullscreenUI.Reset();
        if (!restartTest) {
            event_1.Test.particleParameter.Reset();
        }
        if (this.m_test) {
            this.m_test.RestoreParticleParameters();
        }
        var bubble = new bubble_1.Bubble();
        this.m_test = bubble;
    };
    Main.prototype.SimulationLoop = function (time) {
        var _a;
        this.m_time_last = this.m_time_last || time;
        var time_elapsed = time - this.m_time_last;
        this.m_time_last = time;
        if (time_elapsed > 1000) {
            time_elapsed = 1000;
        } // clamp
        //todo 设置FTP
        // this.m_fps_time += time_elapsed;
        // this.m_fps_frames++;
        // if (this.m_fps_time >= 500) {
        //     this.m_fps = (this.m_fps_frames * 1000) / this.m_fps_time;
        //     this.m_fps_frames = 0;
        //     this.m_fps_time = 0;
        //     this.m_fps_div.innerHTML = this.m_fps.toFixed(1).toString();
        // }
        if (time_elapsed > 0) {
            var ctx = this.m_ctx;
            // #if B2_ENABLE_PARTICLE
            var restartTest = [false];
            // #endif
            if (ctx) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.save();
                ctx.translate(0.5 * ctx.canvas.width, 0.5 * ctx.canvas.height);
                ctx.scale(1, -1);
                // apply camera
                ctx.scale(1 / draw_1.g_camera.m_zoom, 1 / draw_1.g_camera.m_zoom);
                ctx.lineWidth *= draw_1.g_camera.m_zoom;
                if (this.m_test) {
                    this.m_test.Step(this.m_settings);
                }
                ctx.restore();
            }
            if (restartTest[0]) {
                this.LoadTest(true);
            }
        }
        (_a = this.m_test) === null || _a === void 0 ? void 0 : _a.drawBubbleDOM();
    };
    return Main;
}());
function start() {
    var id = "bubble-box-".concat(new Date().getTime());
    var app;
    var boot = function () {
        var init = function (time) {
            app = new Main(time);
            window.requestAnimationFrame(loop);
        };
        var loop = function (time) {
            window.requestAnimationFrame(loop);
            app.SimulationLoop(time);
        };
        window.requestAnimationFrame(init);
    };
    function getDOM() {
        if (!document.getElementById(id)) {
            setTimeout(function () {
                getDOM();
            }, 100);
        }
        else {
            boot();
        }
    }
    (0, react_1.useEffect)(function () {
        getDOM();
    }, []);
    return <div id={id}/>;
}

export default start