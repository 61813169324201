"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lengthUnitsPerMeter = exports.invalidParticleIndex = exports.epsilon_sq = exports.epsilon = exports.commit = exports.branch = exports.baumgarte = exports.barrierCollisionTime = exports.angularSlop = exports.angularSleepTolerance = exports.aabbMultiplier = exports.aabbExtension = exports.Version = exports.Swap = exports.Sq = exports.RandomRange = exports.Random = exports.RadToDeg = exports.NextPowerOfTwo = exports.Min = exports.Max = exports.IsPowerOfTwo = exports.InvSqrt = exports.DegToRad = exports.Clamp = exports.Vec2_zero = exports.Sqrt = exports.Sin = exports.Pow = exports.IsValid = exports.Cos = exports.Atan2 = exports.Asin = exports.Acos = exports.Abs = exports.two_pi = exports._180_over_pi = exports._pi_over_180 = exports.Vec3 = exports.Vec2 = exports.Transform = exports.Sweep = exports.Rot = exports.Mat33 = exports.Mat22 = exports.GrowableStack = exports.DrawFlags = exports.Color = exports.Draw = exports.BlockAllocator = void 0;
exports.ClipVertex = exports.AABB = exports.CollidePolygons = exports.CollideEdgeAndPolygon = exports.CollideEdgeAndCircle = exports.CollidePolygonAndCircle = exports.CollideCircles = exports.CircleShape = exports.ChainShape = exports.Pair = exports.BroadPhase = exports.Timer = exports.Counter = exports.StackAllocator = exports.ParseUInt = exports.ParseInt = exports.Maybe = exports.MakeNumberArray = exports.MakeNullArray = exports.MakeArray = exports.Log = exports.Free = exports.Assert = exports.Alloc = exports.version = exports.toiBaumgarte = exports.timeToSleep = exports.polygonRadius = exports.pi = exports.particleStride = exports.minParticleWeight = exports.minParticleSystemBufferCapacity = exports.maxTriadDistanceSquared = exports.maxTriadDistance = exports.maxTranslationSquared = exports.maxTranslation = exports.maxTOIContacts = exports.maxSubSteps = exports.maxRotationSquared = exports.maxRotation = exports.maxPolygonVertices = exports.maxParticlePressure = exports.maxParticleIndex = exports.maxParticleForce = exports.maxManifoldPoints = exports.maxLinearCorrection = exports.maxFloat = exports.maxAngularCorrection = exports.linearSlop = exports.linearSleepTolerance = void 0;
exports.AreaJointDef = exports.toiTime = exports.toiRootIters = exports.toiMaxTime = exports.toiMaxRootIters = exports.toiMaxIters = exports.toiIters = exports.toiCalls = exports.toi_reset = exports.TimeOfImpact = exports.TOIOutputState = exports.SeparationFunctionType = exports.TOIOutput = exports.TOIInput = exports.SeparationFunction = exports.ShapeType = exports.MassData = exports.Shape = exports.PolygonShape = exports.EdgeShape = exports.TreeNode = exports.DynamicTree = exports.gjkMaxIters = exports.gjkIters = exports.gjkCalls = exports.ShapeCast = exports.gjk_reset = exports.Distance = exports.SimplexVertex = exports.SimplexCache = exports.Simplex = exports.ShapeCastOutput = exports.ShapeCastInput = exports.DistanceProxy = exports.DistanceOutput = exports.DistanceInput = exports.TestOverlapShape = exports.TestOverlapAABB = exports.GetPointStates = exports.ClipSegmentToLine = exports.PointState = exports.ManifoldType = exports.ContactFeatureType = exports.WorldManifold = exports.RayCastOutput = exports.RayCastInput = exports.ManifoldPoint = exports.Manifold = exports.ContactID = exports.ContactFeature = void 0;
exports.MouseJointDef = exports.MotorJoint = exports.MotorJointDef = exports.LinearStiffness = exports.AngularStiffness = exports.JointType = exports.JointEdge = exports.Jacobian = exports.Joint = exports.JointDef = exports.Island = exports.GearJoint = exports.GearJointDef = exports.FrictionJoint = exports.FrictionJointDef = exports.FixtureProxy = exports.FixtureDef = exports.Fixture = exports.Filter = exports.EdgeAndPolygonContact = exports.EdgeAndCircleContact = exports.DistanceJoint = exports.DistanceJointDef = exports.MixRestitutionThreshold = exports.MixRestitution = exports.MixFriction = exports.ContactEdge = exports.Contact = exports.set_g_blockSolve = exports.get_g_blockSolve = exports.blockSolve = exports.VelocityConstraintPoint = exports.PositionSolverManifold = exports.ContactVelocityConstraint = exports.ContactSolverDef = exports.ContactSolver = exports.ContactPositionConstraint = exports.ContactManager = exports.ContactRegister = exports.ContactFactory = exports.CircleContact = exports.ChainAndPolygonContact = exports.ChainAndCircleContact = exports.dynamicBody = exports.kinematicBody = exports.staticBody = exports.BodyType = exports.BodyDef = exports.Body = exports.AreaJoint = void 0;
exports.FixtureParticleQueryCallback = exports.ParticleGroupFlag = exports.ParticleGroupDef = exports.ParticleGroup = exports.TensorDampingController = exports.GravityController = exports.ControllerEdge = exports.Controller = exports.ConstantForceController = exports.ConstantAccelController = exports.BuoyancyController = exports.xpbdStretchingModel = exports.pbdStretchingModel = exports.StretchingModel = exports.pbdTriangleBendingModel = exports.pbdHeightBendingModel = exports.pbdDistanceBendingModel = exports.xpbdAngleBendingModel = exports.pbdAngleBendingModel = exports.springAngleBendingModel = exports.BendingModel = exports.RopeTuning = exports.Rope = exports.RopeDef = exports.World = exports.RayCastCallback = exports.QueryCallback = exports.DestructionListener = exports.ContactListener = exports.ContactImpulse = exports.ContactFilter = exports.WheelJoint = exports.WheelJointDef = exports.WeldJoint = exports.WeldJointDef = exports.Velocity = exports.TimeStep = exports.SolverData = exports.Profile = exports.Position = exports.RevoluteJoint = exports.RevoluteJointDef = exports.minPulleyLength = exports.PulleyJoint = exports.PulleyJointDef = exports.PrismaticJoint = exports.PrismaticJointDef = exports.PolygonContact = exports.PolygonAndCircleContact = exports.MouseJoint = void 0;
exports.VoronoiDiagram_Task = exports.VoronoiDiagram_Generator = exports.VoronoiDiagram = exports.StackQueue = exports.CalculateParticleIterations = exports.ParticleFlag = exports.ParticleHandle = exports.ParticleDef = exports.ParticleTriad = exports.ParticleSystem_UserOverridableBuffer = exports.ParticleSystem_UpdateBodyContactsCallback = exports.ParticleSystem_SolveCollisionCallback = exports.ParticleSystem_ReactiveFilter = exports.ParticleSystem_Proxy = exports.ParticleSystem_ParticlePair = exports.ParticleSystem_ParticleListNode = exports.ParticleSystem_JoinParticleGroupsFilter = exports.ParticleSystem_InsideBoundsEnumerator = exports.ParticleSystem_FixtureParticleSet = exports.ParticleSystem_FixtureParticle = exports.ParticleSystem_FixedSetAllocator = exports.ParticleSystem_DestroyParticlesInShapeCallback = exports.ParticleSystemDef = exports.ParticleSystem_ConnectionFilter = exports.ParticleSystem_CompositeShape = exports.ParticleSystem = exports.ParticlePairSet = exports.ParticlePair = exports.ParticleContact = exports.ParticleBodyContact = exports.GrowableBuffer = void 0;
/* eslint-disable */
var b2_block_allocator_js_1 = require("./common/b2_block_allocator.js");
Object.defineProperty(exports, "BlockAllocator", { enumerable: true, get: function () { return b2_block_allocator_js_1.b2BlockAllocator; } });
var b2_draw_js_1 = require("./common/b2_draw.js");
Object.defineProperty(exports, "Draw", { enumerable: true, get: function () { return b2_draw_js_1.b2Draw; } });
var b2_draw_js_2 = require("./common/b2_draw.js");
Object.defineProperty(exports, "Color", { enumerable: true, get: function () { return b2_draw_js_2.b2Color; } });
var b2_draw_js_3 = require("./common/b2_draw.js");
Object.defineProperty(exports, "DrawFlags", { enumerable: true, get: function () { return b2_draw_js_3.b2DrawFlags; } });
// export { /*interface*/ RGB } from "./common/b2_draw.js";
// export { /*interface*/ RGBA } from "./common/b2_draw.js";
var b2_growable_stack_js_1 = require("./common/b2_growable_stack.js");
Object.defineProperty(exports, "GrowableStack", { enumerable: true, get: function () { return b2_growable_stack_js_1.b2GrowableStack; } });
var b2_math_js_1 = require("./common/b2_math.js");
Object.defineProperty(exports, "Mat22", { enumerable: true, get: function () { return b2_math_js_1.b2Mat22; } });
var b2_math_js_2 = require("./common/b2_math.js");
Object.defineProperty(exports, "Mat33", { enumerable: true, get: function () { return b2_math_js_2.b2Mat33; } });
var b2_math_js_3 = require("./common/b2_math.js");
Object.defineProperty(exports, "Rot", { enumerable: true, get: function () { return b2_math_js_3.b2Rot; } });
var b2_math_js_4 = require("./common/b2_math.js");
Object.defineProperty(exports, "Sweep", { enumerable: true, get: function () { return b2_math_js_4.b2Sweep; } });
var b2_math_js_5 = require("./common/b2_math.js");
Object.defineProperty(exports, "Transform", { enumerable: true, get: function () { return b2_math_js_5.b2Transform; } });
var b2_math_js_6 = require("./common/b2_math.js");
Object.defineProperty(exports, "Vec2", { enumerable: true, get: function () { return b2_math_js_6.b2Vec2; } });
var b2_math_js_7 = require("./common/b2_math.js");
Object.defineProperty(exports, "Vec3", { enumerable: true, get: function () { return b2_math_js_7.b2Vec3; } });
var b2_math_js_8 = require("./common/b2_math.js");
Object.defineProperty(exports, "_pi_over_180", { enumerable: true, get: function () { return b2_math_js_8.b2_pi_over_180; } });
var b2_math_js_9 = require("./common/b2_math.js");
Object.defineProperty(exports, "_180_over_pi", { enumerable: true, get: function () { return b2_math_js_9.b2_180_over_pi; } });
var b2_math_js_10 = require("./common/b2_math.js");
Object.defineProperty(exports, "two_pi", { enumerable: true, get: function () { return b2_math_js_10.b2_two_pi; } });
var b2_math_js_11 = require("./common/b2_math.js");
Object.defineProperty(exports, "Abs", { enumerable: true, get: function () { return b2_math_js_11.b2Abs; } });
var b2_math_js_12 = require("./common/b2_math.js");
Object.defineProperty(exports, "Acos", { enumerable: true, get: function () { return b2_math_js_12.b2Acos; } });
var b2_math_js_13 = require("./common/b2_math.js");
Object.defineProperty(exports, "Asin", { enumerable: true, get: function () { return b2_math_js_13.b2Asin; } });
var b2_math_js_14 = require("./common/b2_math.js");
Object.defineProperty(exports, "Atan2", { enumerable: true, get: function () { return b2_math_js_14.b2Atan2; } });
var b2_math_js_15 = require("./common/b2_math.js");
Object.defineProperty(exports, "Cos", { enumerable: true, get: function () { return b2_math_js_15.b2Cos; } });
var b2_math_js_16 = require("./common/b2_math.js");
Object.defineProperty(exports, "IsValid", { enumerable: true, get: function () { return b2_math_js_16.b2IsValid; } });
var b2_math_js_17 = require("./common/b2_math.js");
Object.defineProperty(exports, "Pow", { enumerable: true, get: function () { return b2_math_js_17.b2Pow; } });
var b2_math_js_18 = require("./common/b2_math.js");
Object.defineProperty(exports, "Sin", { enumerable: true, get: function () { return b2_math_js_18.b2Sin; } });
var b2_math_js_19 = require("./common/b2_math.js");
Object.defineProperty(exports, "Sqrt", { enumerable: true, get: function () { return b2_math_js_19.b2Sqrt; } });
var b2_math_js_20 = require("./common/b2_math.js");
Object.defineProperty(exports, "Vec2_zero", { enumerable: true, get: function () { return b2_math_js_20.b2Vec2_zero; } });
var b2_math_js_21 = require("./common/b2_math.js");
Object.defineProperty(exports, "Clamp", { enumerable: true, get: function () { return b2_math_js_21.b2Clamp; } });
var b2_math_js_22 = require("./common/b2_math.js");
Object.defineProperty(exports, "DegToRad", { enumerable: true, get: function () { return b2_math_js_22.b2DegToRad; } });
var b2_math_js_23 = require("./common/b2_math.js");
Object.defineProperty(exports, "InvSqrt", { enumerable: true, get: function () { return b2_math_js_23.b2InvSqrt; } });
var b2_math_js_24 = require("./common/b2_math.js");
Object.defineProperty(exports, "IsPowerOfTwo", { enumerable: true, get: function () { return b2_math_js_24.b2IsPowerOfTwo; } });
var b2_math_js_25 = require("./common/b2_math.js");
Object.defineProperty(exports, "Max", { enumerable: true, get: function () { return b2_math_js_25.b2Max; } });
var b2_math_js_26 = require("./common/b2_math.js");
Object.defineProperty(exports, "Min", { enumerable: true, get: function () { return b2_math_js_26.b2Min; } });
var b2_math_js_27 = require("./common/b2_math.js");
Object.defineProperty(exports, "NextPowerOfTwo", { enumerable: true, get: function () { return b2_math_js_27.b2NextPowerOfTwo; } });
var b2_math_js_28 = require("./common/b2_math.js");
Object.defineProperty(exports, "RadToDeg", { enumerable: true, get: function () { return b2_math_js_28.b2RadToDeg; } });
var b2_math_js_29 = require("./common/b2_math.js");
Object.defineProperty(exports, "Random", { enumerable: true, get: function () { return b2_math_js_29.b2Random; } });
var b2_math_js_30 = require("./common/b2_math.js");
Object.defineProperty(exports, "RandomRange", { enumerable: true, get: function () { return b2_math_js_30.b2RandomRange; } });
var b2_math_js_31 = require("./common/b2_math.js");
Object.defineProperty(exports, "Sq", { enumerable: true, get: function () { return b2_math_js_31.b2Sq; } });
var b2_math_js_32 = require("./common/b2_math.js");
Object.defineProperty(exports, "Swap", { enumerable: true, get: function () { return b2_math_js_32.b2Swap; } });
// export { /*interface*/ XY as XY } from "./common/b2_math.js";
// export { /*interface*/ XYZ as XYZ } from "./common/b2_math.js";
var b2_settings_js_1 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Version", { enumerable: true, get: function () { return b2_settings_js_1.b2Version; } });
var b2_settings_js_2 = require("./common/b2_settings.js");
Object.defineProperty(exports, "aabbExtension", { enumerable: true, get: function () { return b2_settings_js_2.b2_aabbExtension; } });
var b2_settings_js_3 = require("./common/b2_settings.js");
Object.defineProperty(exports, "aabbMultiplier", { enumerable: true, get: function () { return b2_settings_js_3.b2_aabbMultiplier; } });
var b2_settings_js_4 = require("./common/b2_settings.js");
Object.defineProperty(exports, "angularSleepTolerance", { enumerable: true, get: function () { return b2_settings_js_4.b2_angularSleepTolerance; } });
var b2_settings_js_5 = require("./common/b2_settings.js");
Object.defineProperty(exports, "angularSlop", { enumerable: true, get: function () { return b2_settings_js_5.b2_angularSlop; } });
var b2_settings_js_6 = require("./common/b2_settings.js");
Object.defineProperty(exports, "barrierCollisionTime", { enumerable: true, get: function () { return b2_settings_js_6.b2_barrierCollisionTime; } });
var b2_settings_js_7 = require("./common/b2_settings.js");
Object.defineProperty(exports, "baumgarte", { enumerable: true, get: function () { return b2_settings_js_7.b2_baumgarte; } });
var b2_settings_js_8 = require("./common/b2_settings.js");
Object.defineProperty(exports, "branch", { enumerable: true, get: function () { return b2_settings_js_8.b2_branch; } });
var b2_settings_js_9 = require("./common/b2_settings.js");
Object.defineProperty(exports, "commit", { enumerable: true, get: function () { return b2_settings_js_9.b2_commit; } });
var b2_settings_js_10 = require("./common/b2_settings.js");
Object.defineProperty(exports, "epsilon", { enumerable: true, get: function () { return b2_settings_js_10.b2_epsilon; } });
var b2_settings_js_11 = require("./common/b2_settings.js");
Object.defineProperty(exports, "epsilon_sq", { enumerable: true, get: function () { return b2_settings_js_11.b2_epsilon_sq; } });
var b2_settings_js_12 = require("./common/b2_settings.js");
Object.defineProperty(exports, "invalidParticleIndex", { enumerable: true, get: function () { return b2_settings_js_12.b2_invalidParticleIndex; } });
var b2_settings_js_13 = require("./common/b2_settings.js");
Object.defineProperty(exports, "lengthUnitsPerMeter", { enumerable: true, get: function () { return b2_settings_js_13.b2_lengthUnitsPerMeter; } });
var b2_settings_js_14 = require("./common/b2_settings.js");
Object.defineProperty(exports, "linearSleepTolerance", { enumerable: true, get: function () { return b2_settings_js_14.b2_linearSleepTolerance; } });
var b2_settings_js_15 = require("./common/b2_settings.js");
Object.defineProperty(exports, "linearSlop", { enumerable: true, get: function () { return b2_settings_js_15.b2_linearSlop; } });
var b2_settings_js_16 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxAngularCorrection", { enumerable: true, get: function () { return b2_settings_js_16.b2_maxAngularCorrection; } });
var b2_settings_js_17 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxFloat", { enumerable: true, get: function () { return b2_settings_js_17.b2_maxFloat; } });
var b2_settings_js_18 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxLinearCorrection", { enumerable: true, get: function () { return b2_settings_js_18.b2_maxLinearCorrection; } });
var b2_settings_js_19 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxManifoldPoints", { enumerable: true, get: function () { return b2_settings_js_19.b2_maxManifoldPoints; } });
var b2_settings_js_20 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxParticleForce", { enumerable: true, get: function () { return b2_settings_js_20.b2_maxParticleForce; } });
var b2_settings_js_21 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxParticleIndex", { enumerable: true, get: function () { return b2_settings_js_21.b2_maxParticleIndex; } });
var b2_settings_js_22 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxParticlePressure", { enumerable: true, get: function () { return b2_settings_js_22.b2_maxParticlePressure; } });
var b2_settings_js_23 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxPolygonVertices", { enumerable: true, get: function () { return b2_settings_js_23.b2_maxPolygonVertices; } });
var b2_settings_js_24 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxRotation", { enumerable: true, get: function () { return b2_settings_js_24.b2_maxRotation; } });
var b2_settings_js_25 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxRotationSquared", { enumerable: true, get: function () { return b2_settings_js_25.b2_maxRotationSquared; } });
var b2_settings_js_26 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxSubSteps", { enumerable: true, get: function () { return b2_settings_js_26.b2_maxSubSteps; } });
var b2_settings_js_27 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxTOIContacts", { enumerable: true, get: function () { return b2_settings_js_27.b2_maxTOIContacts; } });
var b2_settings_js_28 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxTranslation", { enumerable: true, get: function () { return b2_settings_js_28.b2_maxTranslation; } });
var b2_settings_js_29 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxTranslationSquared", { enumerable: true, get: function () { return b2_settings_js_29.b2_maxTranslationSquared; } });
var b2_settings_js_30 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxTriadDistance", { enumerable: true, get: function () { return b2_settings_js_30.b2_maxTriadDistance; } });
var b2_settings_js_31 = require("./common/b2_settings.js");
Object.defineProperty(exports, "maxTriadDistanceSquared", { enumerable: true, get: function () { return b2_settings_js_31.b2_maxTriadDistanceSquared; } });
var b2_settings_js_32 = require("./common/b2_settings.js");
Object.defineProperty(exports, "minParticleSystemBufferCapacity", { enumerable: true, get: function () { return b2_settings_js_32.b2_minParticleSystemBufferCapacity; } });
var b2_settings_js_33 = require("./common/b2_settings.js");
Object.defineProperty(exports, "minParticleWeight", { enumerable: true, get: function () { return b2_settings_js_33.b2_minParticleWeight; } });
var b2_settings_js_34 = require("./common/b2_settings.js");
Object.defineProperty(exports, "particleStride", { enumerable: true, get: function () { return b2_settings_js_34.b2_particleStride; } });
var b2_settings_js_35 = require("./common/b2_settings.js");
Object.defineProperty(exports, "pi", { enumerable: true, get: function () { return b2_settings_js_35.b2_pi; } });
var b2_settings_js_36 = require("./common/b2_settings.js");
Object.defineProperty(exports, "polygonRadius", { enumerable: true, get: function () { return b2_settings_js_36.b2_polygonRadius; } });
var b2_settings_js_37 = require("./common/b2_settings.js");
Object.defineProperty(exports, "timeToSleep", { enumerable: true, get: function () { return b2_settings_js_37.b2_timeToSleep; } });
var b2_settings_js_38 = require("./common/b2_settings.js");
Object.defineProperty(exports, "toiBaumgarte", { enumerable: true, get: function () { return b2_settings_js_38.b2_toiBaumgarte; } });
var b2_settings_js_39 = require("./common/b2_settings.js");
Object.defineProperty(exports, "version", { enumerable: true, get: function () { return b2_settings_js_39.b2_version; } });
var b2_settings_js_40 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Alloc", { enumerable: true, get: function () { return b2_settings_js_40.b2Alloc; } });
var b2_settings_js_41 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Assert", { enumerable: true, get: function () { return b2_settings_js_41.b2Assert; } });
var b2_settings_js_42 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Free", { enumerable: true, get: function () { return b2_settings_js_42.b2Free; } });
var b2_settings_js_43 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Log", { enumerable: true, get: function () { return b2_settings_js_43.b2Log; } });
var b2_settings_js_44 = require("./common/b2_settings.js");
Object.defineProperty(exports, "MakeArray", { enumerable: true, get: function () { return b2_settings_js_44.b2MakeArray; } });
var b2_settings_js_45 = require("./common/b2_settings.js");
Object.defineProperty(exports, "MakeNullArray", { enumerable: true, get: function () { return b2_settings_js_45.b2MakeNullArray; } });
var b2_settings_js_46 = require("./common/b2_settings.js");
Object.defineProperty(exports, "MakeNumberArray", { enumerable: true, get: function () { return b2_settings_js_46.b2MakeNumberArray; } });
var b2_settings_js_47 = require("./common/b2_settings.js");
Object.defineProperty(exports, "Maybe", { enumerable: true, get: function () { return b2_settings_js_47.b2Maybe; } });
var b2_settings_js_48 = require("./common/b2_settings.js");
Object.defineProperty(exports, "ParseInt", { enumerable: true, get: function () { return b2_settings_js_48.b2ParseInt; } });
var b2_settings_js_49 = require("./common/b2_settings.js");
Object.defineProperty(exports, "ParseUInt", { enumerable: true, get: function () { return b2_settings_js_49.b2ParseUInt; } });
var b2_stack_allocator_js_1 = require("./common/b2_stack_allocator.js");
Object.defineProperty(exports, "StackAllocator", { enumerable: true, get: function () { return b2_stack_allocator_js_1.b2StackAllocator; } });
var b2_timer_js_1 = require("./common/b2_timer.js");
Object.defineProperty(exports, "Counter", { enumerable: true, get: function () { return b2_timer_js_1.b2Counter; } });
var b2_timer_js_2 = require("./common/b2_timer.js");
Object.defineProperty(exports, "Timer", { enumerable: true, get: function () { return b2_timer_js_2.b2Timer; } });
var b2_broad_phase_js_1 = require("./collision/b2_broad_phase.js");
Object.defineProperty(exports, "BroadPhase", { enumerable: true, get: function () { return b2_broad_phase_js_1.b2BroadPhase; } });
var b2_broad_phase_js_2 = require("./collision/b2_broad_phase.js");
Object.defineProperty(exports, "Pair", { enumerable: true, get: function () { return b2_broad_phase_js_2.b2Pair; } });
var b2_chain_shape_js_1 = require("./collision/b2_chain_shape.js");
Object.defineProperty(exports, "ChainShape", { enumerable: true, get: function () { return b2_chain_shape_js_1.b2ChainShape; } });
var b2_circle_shape_js_1 = require("./collision/b2_circle_shape.js");
Object.defineProperty(exports, "CircleShape", { enumerable: true, get: function () { return b2_circle_shape_js_1.b2CircleShape; } });
var b2_collide_circle_js_1 = require("./collision/b2_collide_circle.js");
Object.defineProperty(exports, "CollideCircles", { enumerable: true, get: function () { return b2_collide_circle_js_1.b2CollideCircles; } });
var b2_collide_circle_js_2 = require("./collision/b2_collide_circle.js");
Object.defineProperty(exports, "CollidePolygonAndCircle", { enumerable: true, get: function () { return b2_collide_circle_js_2.b2CollidePolygonAndCircle; } });
var b2_collide_edge_js_1 = require("./collision/b2_collide_edge.js");
Object.defineProperty(exports, "CollideEdgeAndCircle", { enumerable: true, get: function () { return b2_collide_edge_js_1.b2CollideEdgeAndCircle; } });
var b2_collide_edge_js_2 = require("./collision/b2_collide_edge.js");
Object.defineProperty(exports, "CollideEdgeAndPolygon", { enumerable: true, get: function () { return b2_collide_edge_js_2.b2CollideEdgeAndPolygon; } });
var b2_collide_polygon_js_1 = require("./collision/b2_collide_polygon.js");
Object.defineProperty(exports, "CollidePolygons", { enumerable: true, get: function () { return b2_collide_polygon_js_1.b2CollidePolygons; } });
var b2_collision_js_1 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "AABB", { enumerable: true, get: function () { return b2_collision_js_1.b2AABB; } });
var b2_collision_js_2 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ClipVertex", { enumerable: true, get: function () { return b2_collision_js_2.b2ClipVertex; } });
var b2_collision_js_3 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ContactFeature", { enumerable: true, get: function () { return b2_collision_js_3.b2ContactFeature; } });
var b2_collision_js_4 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ContactID", { enumerable: true, get: function () { return b2_collision_js_4.b2ContactID; } });
var b2_collision_js_5 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "Manifold", { enumerable: true, get: function () { return b2_collision_js_5.b2Manifold; } });
var b2_collision_js_6 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ManifoldPoint", { enumerable: true, get: function () { return b2_collision_js_6.b2ManifoldPoint; } });
var b2_collision_js_7 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "RayCastInput", { enumerable: true, get: function () { return b2_collision_js_7.b2RayCastInput; } });
var b2_collision_js_8 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "RayCastOutput", { enumerable: true, get: function () { return b2_collision_js_8.b2RayCastOutput; } });
var b2_collision_js_9 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "WorldManifold", { enumerable: true, get: function () { return b2_collision_js_9.b2WorldManifold; } });
var b2_collision_js_10 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ContactFeatureType", { enumerable: true, get: function () { return b2_collision_js_10.b2ContactFeatureType; } });
var b2_collision_js_11 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ManifoldType", { enumerable: true, get: function () { return b2_collision_js_11.b2ManifoldType; } });
var b2_collision_js_12 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "PointState", { enumerable: true, get: function () { return b2_collision_js_12.b2PointState; } });
var b2_collision_js_13 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "ClipSegmentToLine", { enumerable: true, get: function () { return b2_collision_js_13.b2ClipSegmentToLine; } });
var b2_collision_js_14 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "GetPointStates", { enumerable: true, get: function () { return b2_collision_js_14.b2GetPointStates; } });
var b2_collision_js_15 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "TestOverlapAABB", { enumerable: true, get: function () { return b2_collision_js_15.b2TestOverlapAABB; } });
var b2_collision_js_16 = require("./collision/b2_collision.js");
Object.defineProperty(exports, "TestOverlapShape", { enumerable: true, get: function () { return b2_collision_js_16.b2TestOverlapShape; } });
var b2_distance_js_1 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "DistanceInput", { enumerable: true, get: function () { return b2_distance_js_1.b2DistanceInput; } });
var b2_distance_js_2 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "DistanceOutput", { enumerable: true, get: function () { return b2_distance_js_2.b2DistanceOutput; } });
var b2_distance_js_3 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "DistanceProxy", { enumerable: true, get: function () { return b2_distance_js_3.b2DistanceProxy; } });
var b2_distance_js_4 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "ShapeCastInput", { enumerable: true, get: function () { return b2_distance_js_4.b2ShapeCastInput; } });
var b2_distance_js_5 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "ShapeCastOutput", { enumerable: true, get: function () { return b2_distance_js_5.b2ShapeCastOutput; } });
var b2_distance_js_6 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "Simplex", { enumerable: true, get: function () { return b2_distance_js_6.b2Simplex; } });
var b2_distance_js_7 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "SimplexCache", { enumerable: true, get: function () { return b2_distance_js_7.b2SimplexCache; } });
var b2_distance_js_8 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "SimplexVertex", { enumerable: true, get: function () { return b2_distance_js_8.b2SimplexVertex; } });
var b2_distance_js_9 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "Distance", { enumerable: true, get: function () { return b2_distance_js_9.b2Distance; } });
var b2_distance_js_10 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "gjk_reset", { enumerable: true, get: function () { return b2_distance_js_10.b2_gjk_reset; } });
var b2_distance_js_11 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "ShapeCast", { enumerable: true, get: function () { return b2_distance_js_11.b2ShapeCast; } });
var b2_distance_js_12 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "gjkCalls", { enumerable: true, get: function () { return b2_distance_js_12.b2_gjkCalls; } });
var b2_distance_js_13 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "gjkIters", { enumerable: true, get: function () { return b2_distance_js_13.b2_gjkIters; } });
var b2_distance_js_14 = require("./collision/b2_distance.js");
Object.defineProperty(exports, "gjkMaxIters", { enumerable: true, get: function () { return b2_distance_js_14.b2_gjkMaxIters; } });
var b2_dynamic_tree_js_1 = require("./collision/b2_dynamic_tree.js");
Object.defineProperty(exports, "DynamicTree", { enumerable: true, get: function () { return b2_dynamic_tree_js_1.b2DynamicTree; } });
var b2_dynamic_tree_js_2 = require("./collision/b2_dynamic_tree.js");
Object.defineProperty(exports, "TreeNode", { enumerable: true, get: function () { return b2_dynamic_tree_js_2.b2TreeNode; } });
var b2_edge_shape_js_1 = require("./collision/b2_edge_shape.js");
Object.defineProperty(exports, "EdgeShape", { enumerable: true, get: function () { return b2_edge_shape_js_1.b2EdgeShape; } });
var b2_polygon_shape_js_1 = require("./collision/b2_polygon_shape.js");
Object.defineProperty(exports, "PolygonShape", { enumerable: true, get: function () { return b2_polygon_shape_js_1.b2PolygonShape; } });
var b2_shape_js_1 = require("./collision/b2_shape.js");
Object.defineProperty(exports, "Shape", { enumerable: true, get: function () { return b2_shape_js_1.b2Shape; } });
var b2_shape_js_2 = require("./collision/b2_shape.js");
Object.defineProperty(exports, "MassData", { enumerable: true, get: function () { return b2_shape_js_2.b2MassData; } });
var b2_shape_js_3 = require("./collision/b2_shape.js");
Object.defineProperty(exports, "ShapeType", { enumerable: true, get: function () { return b2_shape_js_3.b2ShapeType; } });
var b2_time_of_impact_js_1 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "SeparationFunction", { enumerable: true, get: function () { return b2_time_of_impact_js_1.b2SeparationFunction; } });
var b2_time_of_impact_js_2 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "TOIInput", { enumerable: true, get: function () { return b2_time_of_impact_js_2.b2TOIInput; } });
var b2_time_of_impact_js_3 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "TOIOutput", { enumerable: true, get: function () { return b2_time_of_impact_js_3.b2TOIOutput; } });
var b2_time_of_impact_js_4 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "SeparationFunctionType", { enumerable: true, get: function () { return b2_time_of_impact_js_4.b2SeparationFunctionType; } });
var b2_time_of_impact_js_5 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "TOIOutputState", { enumerable: true, get: function () { return b2_time_of_impact_js_5.b2TOIOutputState; } });
var b2_time_of_impact_js_6 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "TimeOfImpact", { enumerable: true, get: function () { return b2_time_of_impact_js_6.b2TimeOfImpact; } });
var b2_time_of_impact_js_7 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toi_reset", { enumerable: true, get: function () { return b2_time_of_impact_js_7.b2_toi_reset; } });
var b2_time_of_impact_js_8 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiCalls", { enumerable: true, get: function () { return b2_time_of_impact_js_8.b2_toiCalls; } });
var b2_time_of_impact_js_9 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiIters", { enumerable: true, get: function () { return b2_time_of_impact_js_9.b2_toiIters; } });
var b2_time_of_impact_js_10 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiMaxIters", { enumerable: true, get: function () { return b2_time_of_impact_js_10.b2_toiMaxIters; } });
var b2_time_of_impact_js_11 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiMaxRootIters", { enumerable: true, get: function () { return b2_time_of_impact_js_11.b2_toiMaxRootIters; } });
var b2_time_of_impact_js_12 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiMaxTime", { enumerable: true, get: function () { return b2_time_of_impact_js_12.b2_toiMaxTime; } });
var b2_time_of_impact_js_13 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiRootIters", { enumerable: true, get: function () { return b2_time_of_impact_js_13.b2_toiRootIters; } });
var b2_time_of_impact_js_14 = require("./collision/b2_time_of_impact.js");
Object.defineProperty(exports, "toiTime", { enumerable: true, get: function () { return b2_time_of_impact_js_14.b2_toiTime; } });
// export { /*interface*/ b2IAreaJointDef as IAreaJointDef } from "./dynamics/b2_area_joint.js";
var b2_area_joint_js_1 = require("./dynamics/b2_area_joint.js");
Object.defineProperty(exports, "AreaJointDef", { enumerable: true, get: function () { return b2_area_joint_js_1.b2AreaJointDef; } });
var b2_area_joint_js_2 = require("./dynamics/b2_area_joint.js");
Object.defineProperty(exports, "AreaJoint", { enumerable: true, get: function () { return b2_area_joint_js_2.b2AreaJoint; } });
var b2_body_js_1 = require("./dynamics/b2_body.js");
Object.defineProperty(exports, "Body", { enumerable: true, get: function () { return b2_body_js_1.b2Body; } });
// export { /*interface*/ b2IBodyDef as IBodyDef } from "./dynamics/b2_body.js";
var b2_body_js_2 = require("./dynamics/b2_body.js");
Object.defineProperty(exports, "BodyDef", { enumerable: true, get: function () { return b2_body_js_2.b2BodyDef; } });
var b2_body_js_3 = require("./dynamics/b2_body.js");
Object.defineProperty(exports, "BodyType", { enumerable: true, get: function () { return b2_body_js_3.b2BodyType; } });
var b2_body_js_4 = require("./dynamics/b2_body.js");
exports.staticBody = b2_body_js_4.b2BodyType.b2_staticBody;
exports.kinematicBody = b2_body_js_4.b2BodyType.b2_kinematicBody;
exports.dynamicBody = b2_body_js_4.b2BodyType.b2_dynamicBody;
var b2_chain_circle_contact_js_1 = require("./dynamics/b2_chain_circle_contact.js");
Object.defineProperty(exports, "ChainAndCircleContact", { enumerable: true, get: function () { return b2_chain_circle_contact_js_1.b2ChainAndCircleContact; } });
var b2_chain_polygon_contact_js_1 = require("./dynamics/b2_chain_polygon_contact.js");
Object.defineProperty(exports, "ChainAndPolygonContact", { enumerable: true, get: function () { return b2_chain_polygon_contact_js_1.b2ChainAndPolygonContact; } });
var b2_circle_contact_js_1 = require("./dynamics/b2_circle_contact.js");
Object.defineProperty(exports, "CircleContact", { enumerable: true, get: function () { return b2_circle_contact_js_1.b2CircleContact; } });
var b2_contact_factory_js_1 = require("./dynamics/b2_contact_factory.js");
Object.defineProperty(exports, "ContactFactory", { enumerable: true, get: function () { return b2_contact_factory_js_1.b2ContactFactory; } });
var b2_contact_factory_js_2 = require("./dynamics/b2_contact_factory.js");
Object.defineProperty(exports, "ContactRegister", { enumerable: true, get: function () { return b2_contact_factory_js_2.b2ContactRegister; } });
var b2_contact_manager_js_1 = require("./dynamics/b2_contact_manager.js");
Object.defineProperty(exports, "ContactManager", { enumerable: true, get: function () { return b2_contact_manager_js_1.b2ContactManager; } });
var b2_contact_solver_js_1 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "ContactPositionConstraint", { enumerable: true, get: function () { return b2_contact_solver_js_1.b2ContactPositionConstraint; } });
var b2_contact_solver_js_2 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "ContactSolver", { enumerable: true, get: function () { return b2_contact_solver_js_2.b2ContactSolver; } });
var b2_contact_solver_js_3 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "ContactSolverDef", { enumerable: true, get: function () { return b2_contact_solver_js_3.b2ContactSolverDef; } });
var b2_contact_solver_js_4 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "ContactVelocityConstraint", { enumerable: true, get: function () { return b2_contact_solver_js_4.b2ContactVelocityConstraint; } });
var b2_contact_solver_js_5 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "PositionSolverManifold", { enumerable: true, get: function () { return b2_contact_solver_js_5.b2PositionSolverManifold; } });
var b2_contact_solver_js_6 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "VelocityConstraintPoint", { enumerable: true, get: function () { return b2_contact_solver_js_6.b2VelocityConstraintPoint; } });
var b2_contact_solver_js_7 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "blockSolve", { enumerable: true, get: function () { return b2_contact_solver_js_7.g_blockSolve; } });
var b2_contact_solver_js_8 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "get_g_blockSolve", { enumerable: true, get: function () { return b2_contact_solver_js_8.get_g_blockSolve; } });
var b2_contact_solver_js_9 = require("./dynamics/b2_contact_solver.js");
Object.defineProperty(exports, "set_g_blockSolve", { enumerable: true, get: function () { return b2_contact_solver_js_9.set_g_blockSolve; } });
var b2_contact_js_1 = require("./dynamics/b2_contact.js");
Object.defineProperty(exports, "Contact", { enumerable: true, get: function () { return b2_contact_js_1.b2Contact; } });
var b2_contact_js_2 = require("./dynamics/b2_contact.js");
Object.defineProperty(exports, "ContactEdge", { enumerable: true, get: function () { return b2_contact_js_2.b2ContactEdge; } });
var b2_contact_js_3 = require("./dynamics/b2_contact.js");
Object.defineProperty(exports, "MixFriction", { enumerable: true, get: function () { return b2_contact_js_3.b2MixFriction; } });
var b2_contact_js_4 = require("./dynamics/b2_contact.js");
Object.defineProperty(exports, "MixRestitution", { enumerable: true, get: function () { return b2_contact_js_4.b2MixRestitution; } });
var b2_contact_js_5 = require("./dynamics/b2_contact.js");
Object.defineProperty(exports, "MixRestitutionThreshold", { enumerable: true, get: function () { return b2_contact_js_5.b2MixRestitutionThreshold; } });
// export { /*interface*/ b2IDistanceJointDef as IDistanceJointDef } from "./dynamics/b2_distance_joint.js";
var b2_distance_joint_js_1 = require("./dynamics/b2_distance_joint.js");
Object.defineProperty(exports, "DistanceJointDef", { enumerable: true, get: function () { return b2_distance_joint_js_1.b2DistanceJointDef; } });
var b2_distance_joint_js_2 = require("./dynamics/b2_distance_joint.js");
Object.defineProperty(exports, "DistanceJoint", { enumerable: true, get: function () { return b2_distance_joint_js_2.b2DistanceJoint; } });
var b2_edge_circle_contact_js_1 = require("./dynamics/b2_edge_circle_contact.js");
Object.defineProperty(exports, "EdgeAndCircleContact", { enumerable: true, get: function () { return b2_edge_circle_contact_js_1.b2EdgeAndCircleContact; } });
var b2_edge_polygon_contact_js_1 = require("./dynamics/b2_edge_polygon_contact.js");
Object.defineProperty(exports, "EdgeAndPolygonContact", { enumerable: true, get: function () { return b2_edge_polygon_contact_js_1.b2EdgeAndPolygonContact; } });
// export { /*interface*/ b2IFilter as IFilter } from "./dynamics/b2_fixture.js";
var b2_fixture_js_1 = require("./dynamics/b2_fixture.js");
Object.defineProperty(exports, "Filter", { enumerable: true, get: function () { return b2_fixture_js_1.b2Filter; } });
var b2_fixture_js_2 = require("./dynamics/b2_fixture.js");
Object.defineProperty(exports, "Fixture", { enumerable: true, get: function () { return b2_fixture_js_2.b2Fixture; } });
// export { /*interface*/ b2IFixtureDef as IFixtureDef } from "./dynamics/b2_fixture.js";
var b2_fixture_js_3 = require("./dynamics/b2_fixture.js");
Object.defineProperty(exports, "FixtureDef", { enumerable: true, get: function () { return b2_fixture_js_3.b2FixtureDef; } });
var b2_fixture_js_4 = require("./dynamics/b2_fixture.js");
Object.defineProperty(exports, "FixtureProxy", { enumerable: true, get: function () { return b2_fixture_js_4.b2FixtureProxy; } });
// export { /*interface*/ b2IFrictionJointDef as IFrictionJointDef } from "./dynamics/b2_friction_joint.js";
var b2_friction_joint_js_1 = require("./dynamics/b2_friction_joint.js");
Object.defineProperty(exports, "FrictionJointDef", { enumerable: true, get: function () { return b2_friction_joint_js_1.b2FrictionJointDef; } });
var b2_friction_joint_js_2 = require("./dynamics/b2_friction_joint.js");
Object.defineProperty(exports, "FrictionJoint", { enumerable: true, get: function () { return b2_friction_joint_js_2.b2FrictionJoint; } });
// export { /*interface*/ b2IGearJointDef as IGearJointDef } from "./dynamics/b2_gear_joint.js";
var b2_gear_joint_js_1 = require("./dynamics/b2_gear_joint.js");
Object.defineProperty(exports, "GearJointDef", { enumerable: true, get: function () { return b2_gear_joint_js_1.b2GearJointDef; } });
var b2_gear_joint_js_2 = require("./dynamics/b2_gear_joint.js");
Object.defineProperty(exports, "GearJoint", { enumerable: true, get: function () { return b2_gear_joint_js_2.b2GearJoint; } });
var b2_island_js_1 = require("./dynamics/b2_island.js");
Object.defineProperty(exports, "Island", { enumerable: true, get: function () { return b2_island_js_1.b2Island; } });
// export { /*interface*/ b2IJointDef as IJointDef } from "./dynamics/b2_joint.js";
var b2_joint_js_1 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "JointDef", { enumerable: true, get: function () { return b2_joint_js_1.b2JointDef; } });
var b2_joint_js_2 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "Joint", { enumerable: true, get: function () { return b2_joint_js_2.b2Joint; } });
var b2_joint_js_3 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "Jacobian", { enumerable: true, get: function () { return b2_joint_js_3.b2Jacobian; } });
var b2_joint_js_4 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "JointEdge", { enumerable: true, get: function () { return b2_joint_js_4.b2JointEdge; } });
var b2_joint_js_5 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "JointType", { enumerable: true, get: function () { return b2_joint_js_5.b2JointType; } });
var b2_joint_js_6 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "AngularStiffness", { enumerable: true, get: function () { return b2_joint_js_6.b2AngularStiffness; } });
var b2_joint_js_7 = require("./dynamics/b2_joint.js");
Object.defineProperty(exports, "LinearStiffness", { enumerable: true, get: function () { return b2_joint_js_7.b2LinearStiffness; } });
// export { /*interface*/ b2IMotorJointDef as IMotorJointDef } from "./dynamics/b2_motor_joint.js";
var b2_motor_joint_js_1 = require("./dynamics/b2_motor_joint.js");
Object.defineProperty(exports, "MotorJointDef", { enumerable: true, get: function () { return b2_motor_joint_js_1.b2MotorJointDef; } });
var b2_motor_joint_js_2 = require("./dynamics/b2_motor_joint.js");
Object.defineProperty(exports, "MotorJoint", { enumerable: true, get: function () { return b2_motor_joint_js_2.b2MotorJoint; } });
// export { /*interface*/ b2IMouseJointDef as IMouseJointDef } from "./dynamics/b2_mouse_joint.js";
var b2_mouse_joint_js_1 = require("./dynamics/b2_mouse_joint.js");
Object.defineProperty(exports, "MouseJointDef", { enumerable: true, get: function () { return b2_mouse_joint_js_1.b2MouseJointDef; } });
var b2_mouse_joint_js_2 = require("./dynamics/b2_mouse_joint.js");
Object.defineProperty(exports, "MouseJoint", { enumerable: true, get: function () { return b2_mouse_joint_js_2.b2MouseJoint; } });
var b2_polygon_circle_contact_js_1 = require("./dynamics/b2_polygon_circle_contact.js");
Object.defineProperty(exports, "PolygonAndCircleContact", { enumerable: true, get: function () { return b2_polygon_circle_contact_js_1.b2PolygonAndCircleContact; } });
var b2_polygon_contact_js_1 = require("./dynamics/b2_polygon_contact.js");
Object.defineProperty(exports, "PolygonContact", { enumerable: true, get: function () { return b2_polygon_contact_js_1.b2PolygonContact; } });
// export { /*interface*/ b2IPrismaticJointDef as IPrismaticJointDef } from "./dynamics/b2_prismatic_joint.js";
var b2_prismatic_joint_js_1 = require("./dynamics/b2_prismatic_joint.js");
Object.defineProperty(exports, "PrismaticJointDef", { enumerable: true, get: function () { return b2_prismatic_joint_js_1.b2PrismaticJointDef; } });
var b2_prismatic_joint_js_2 = require("./dynamics/b2_prismatic_joint.js");
Object.defineProperty(exports, "PrismaticJoint", { enumerable: true, get: function () { return b2_prismatic_joint_js_2.b2PrismaticJoint; } });
// export { /*interface*/ b2IPulleyJointDef as IPulleyJointDef } from "./dynamics/b2_pulley_joint.js";
var b2_pulley_joint_js_1 = require("./dynamics/b2_pulley_joint.js");
Object.defineProperty(exports, "PulleyJointDef", { enumerable: true, get: function () { return b2_pulley_joint_js_1.b2PulleyJointDef; } });
var b2_pulley_joint_js_2 = require("./dynamics/b2_pulley_joint.js");
Object.defineProperty(exports, "PulleyJoint", { enumerable: true, get: function () { return b2_pulley_joint_js_2.b2PulleyJoint; } });
var b2_pulley_joint_js_3 = require("./dynamics/b2_pulley_joint.js");
Object.defineProperty(exports, "minPulleyLength", { enumerable: true, get: function () { return b2_pulley_joint_js_3.b2_minPulleyLength; } });
// export { /*interface*/ b2IRevoluteJointDef as IRevoluteJointDef } from "./dynamics/b2_revolute_joint.js";
var b2_revolute_joint_js_1 = require("./dynamics/b2_revolute_joint.js");
Object.defineProperty(exports, "RevoluteJointDef", { enumerable: true, get: function () { return b2_revolute_joint_js_1.b2RevoluteJointDef; } });
var b2_revolute_joint_js_2 = require("./dynamics/b2_revolute_joint.js");
Object.defineProperty(exports, "RevoluteJoint", { enumerable: true, get: function () { return b2_revolute_joint_js_2.b2RevoluteJoint; } });
var b2_time_step_js_1 = require("./dynamics/b2_time_step.js");
Object.defineProperty(exports, "Position", { enumerable: true, get: function () { return b2_time_step_js_1.b2Position; } });
var b2_time_step_js_2 = require("./dynamics/b2_time_step.js");
Object.defineProperty(exports, "Profile", { enumerable: true, get: function () { return b2_time_step_js_2.b2Profile; } });
var b2_time_step_js_3 = require("./dynamics/b2_time_step.js");
Object.defineProperty(exports, "SolverData", { enumerable: true, get: function () { return b2_time_step_js_3.b2SolverData; } });
var b2_time_step_js_4 = require("./dynamics/b2_time_step.js");
Object.defineProperty(exports, "TimeStep", { enumerable: true, get: function () { return b2_time_step_js_4.b2TimeStep; } });
var b2_time_step_js_5 = require("./dynamics/b2_time_step.js");
Object.defineProperty(exports, "Velocity", { enumerable: true, get: function () { return b2_time_step_js_5.b2Velocity; } });
// export { /*interface*/ b2IWeldJointDef as IWeldJointDef } from "./dynamics/b2_weld_joint.js";
var b2_weld_joint_js_1 = require("./dynamics/b2_weld_joint.js");
Object.defineProperty(exports, "WeldJointDef", { enumerable: true, get: function () { return b2_weld_joint_js_1.b2WeldJointDef; } });
var b2_weld_joint_js_2 = require("./dynamics/b2_weld_joint.js");
Object.defineProperty(exports, "WeldJoint", { enumerable: true, get: function () { return b2_weld_joint_js_2.b2WeldJoint; } });
// export { /*interface*/ b2IWheelJointDef as IWheelJointDef } from "./dynamics/b2_wheel_joint.js";
var b2_wheel_joint_js_1 = require("./dynamics/b2_wheel_joint.js");
Object.defineProperty(exports, "WheelJointDef", { enumerable: true, get: function () { return b2_wheel_joint_js_1.b2WheelJointDef; } });
var b2_wheel_joint_js_2 = require("./dynamics/b2_wheel_joint.js");
Object.defineProperty(exports, "WheelJoint", { enumerable: true, get: function () { return b2_wheel_joint_js_2.b2WheelJoint; } });
var b2_world_callbacks_js_1 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "ContactFilter", { enumerable: true, get: function () { return b2_world_callbacks_js_1.b2ContactFilter; } });
var b2_world_callbacks_js_2 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "ContactImpulse", { enumerable: true, get: function () { return b2_world_callbacks_js_2.b2ContactImpulse; } });
var b2_world_callbacks_js_3 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "ContactListener", { enumerable: true, get: function () { return b2_world_callbacks_js_3.b2ContactListener; } });
var b2_world_callbacks_js_4 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "DestructionListener", { enumerable: true, get: function () { return b2_world_callbacks_js_4.b2DestructionListener; } });
var b2_world_callbacks_js_5 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "QueryCallback", { enumerable: true, get: function () { return b2_world_callbacks_js_5.b2QueryCallback; } });
var b2_world_callbacks_js_6 = require("./dynamics/b2_world_callbacks.js");
Object.defineProperty(exports, "RayCastCallback", { enumerable: true, get: function () { return b2_world_callbacks_js_6.b2RayCastCallback; } });
// export { /*type*/ b2QueryCallbackFunction as QueryCallbackFunction } from "./dynamics/b2_world_callbacks.js";
// export { /*type*/ b2RayCastCallbackFunction as RayCastCallbackFunction } from "./dynamics/b2_world_callbacks.js";
var b2_world_js_1 = require("./dynamics/b2_world.js");
Object.defineProperty(exports, "World", { enumerable: true, get: function () { return b2_world_js_1.b2World; } });
var b2_rope_js_1 = require("./rope/b2_rope.js");
Object.defineProperty(exports, "RopeDef", { enumerable: true, get: function () { return b2_rope_js_1.b2RopeDef; } });
var b2_rope_js_2 = require("./rope/b2_rope.js");
Object.defineProperty(exports, "Rope", { enumerable: true, get: function () { return b2_rope_js_2.b2Rope; } });
var b2_rope_js_3 = require("./rope/b2_rope.js");
Object.defineProperty(exports, "RopeTuning", { enumerable: true, get: function () { return b2_rope_js_3.b2RopeTuning; } });
var b2_rope_js_4 = require("./rope/b2_rope.js");
Object.defineProperty(exports, "BendingModel", { enumerable: true, get: function () { return b2_rope_js_4.b2BendingModel; } });
var b2_rope_js_5 = require("./rope/b2_rope.js");
exports.springAngleBendingModel = b2_rope_js_5.b2BendingModel.b2_springAngleBendingModel;
exports.pbdAngleBendingModel = b2_rope_js_5.b2BendingModel.b2_pbdAngleBendingModel;
exports.xpbdAngleBendingModel = b2_rope_js_5.b2BendingModel.b2_xpbdAngleBendingModel;
exports.pbdDistanceBendingModel = b2_rope_js_5.b2BendingModel.b2_pbdDistanceBendingModel;
exports.pbdHeightBendingModel = b2_rope_js_5.b2BendingModel.b2_pbdHeightBendingModel;
exports.pbdTriangleBendingModel = b2_rope_js_5.b2BendingModel.b2_pbdTriangleBendingModel;
var b2_rope_js_6 = require("./rope/b2_rope.js");
Object.defineProperty(exports, "StretchingModel", { enumerable: true, get: function () { return b2_rope_js_6.b2StretchingModel; } });
var b2_rope_js_7 = require("./rope/b2_rope.js");
exports.pbdStretchingModel = b2_rope_js_7.b2StretchingModel.b2_pbdStretchingModel;
exports.xpbdStretchingModel = b2_rope_js_7.b2StretchingModel.b2_xpbdStretchingModel;
var b2_buoyancy_controller_js_1 = require("./controllers/b2_buoyancy_controller.js");
Object.defineProperty(exports, "BuoyancyController", { enumerable: true, get: function () { return b2_buoyancy_controller_js_1.b2BuoyancyController; } });
var b2_constant_accel_controller_js_1 = require("./controllers/b2_constant_accel_controller.js");
Object.defineProperty(exports, "ConstantAccelController", { enumerable: true, get: function () { return b2_constant_accel_controller_js_1.b2ConstantAccelController; } });
var b2_constant_force_controller_js_1 = require("./controllers/b2_constant_force_controller.js");
Object.defineProperty(exports, "ConstantForceController", { enumerable: true, get: function () { return b2_constant_force_controller_js_1.b2ConstantForceController; } });
var b2_controller_js_1 = require("./controllers/b2_controller.js");
Object.defineProperty(exports, "Controller", { enumerable: true, get: function () { return b2_controller_js_1.b2Controller; } });
var b2_controller_js_2 = require("./controllers/b2_controller.js");
Object.defineProperty(exports, "ControllerEdge", { enumerable: true, get: function () { return b2_controller_js_2.b2ControllerEdge; } });
var b2_gravity_controller_js_1 = require("./controllers/b2_gravity_controller.js");
Object.defineProperty(exports, "GravityController", { enumerable: true, get: function () { return b2_gravity_controller_js_1.b2GravityController; } });
var b2_tensor_damping_controller_js_1 = require("./controllers/b2_tensor_damping_controller.js");
Object.defineProperty(exports, "TensorDampingController", { enumerable: true, get: function () { return b2_tensor_damping_controller_js_1.b2TensorDampingController; } });
var b2_particle_group_js_1 = require("./particle/b2_particle_group.js");
Object.defineProperty(exports, "ParticleGroup", { enumerable: true, get: function () { return b2_particle_group_js_1.b2ParticleGroup; } });
// export { /*interface*/ b2IParticleGroupDef as IParticleGroupDef } from "./particle/b2_particle_group.js";
var b2_particle_group_js_2 = require("./particle/b2_particle_group.js");
Object.defineProperty(exports, "ParticleGroupDef", { enumerable: true, get: function () { return b2_particle_group_js_2.b2ParticleGroupDef; } });
var b2_particle_group_js_3 = require("./particle/b2_particle_group.js");
Object.defineProperty(exports, "ParticleGroupFlag", { enumerable: true, get: function () { return b2_particle_group_js_3.b2ParticleGroupFlag; } });
var b2_particle_system_js_1 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "FixtureParticleQueryCallback", { enumerable: true, get: function () { return b2_particle_system_js_1.b2FixtureParticleQueryCallback; } });
var b2_particle_system_js_2 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "GrowableBuffer", { enumerable: true, get: function () { return b2_particle_system_js_2.b2GrowableBuffer; } });
var b2_particle_system_js_3 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleBodyContact", { enumerable: true, get: function () { return b2_particle_system_js_3.b2ParticleBodyContact; } });
var b2_particle_system_js_4 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleContact", { enumerable: true, get: function () { return b2_particle_system_js_4.b2ParticleContact; } });
var b2_particle_system_js_5 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticlePair", { enumerable: true, get: function () { return b2_particle_system_js_5.b2ParticlePair; } });
var b2_particle_system_js_6 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticlePairSet", { enumerable: true, get: function () { return b2_particle_system_js_6.b2ParticlePairSet; } });
var b2_particle_system_js_7 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem", { enumerable: true, get: function () { return b2_particle_system_js_7.b2ParticleSystem; } });
var b2_particle_system_js_8 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_CompositeShape", { enumerable: true, get: function () { return b2_particle_system_js_8.b2ParticleSystem_CompositeShape; } });
var b2_particle_system_js_9 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_ConnectionFilter", { enumerable: true, get: function () { return b2_particle_system_js_9.b2ParticleSystem_ConnectionFilter; } });
var b2_particle_system_js_10 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystemDef", { enumerable: true, get: function () { return b2_particle_system_js_10.b2ParticleSystemDef; } });
var b2_particle_system_js_11 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_DestroyParticlesInShapeCallback", { enumerable: true, get: function () { return b2_particle_system_js_11.b2ParticleSystem_DestroyParticlesInShapeCallback; } });
var b2_particle_system_js_12 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_FixedSetAllocator", { enumerable: true, get: function () { return b2_particle_system_js_12.b2ParticleSystem_FixedSetAllocator; } });
var b2_particle_system_js_13 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_FixtureParticle", { enumerable: true, get: function () { return b2_particle_system_js_13.b2ParticleSystem_FixtureParticle; } });
var b2_particle_system_js_14 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_FixtureParticleSet", { enumerable: true, get: function () { return b2_particle_system_js_14.b2ParticleSystem_FixtureParticleSet; } });
var b2_particle_system_js_15 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_InsideBoundsEnumerator", { enumerable: true, get: function () { return b2_particle_system_js_15.b2ParticleSystem_InsideBoundsEnumerator; } });
var b2_particle_system_js_16 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_JoinParticleGroupsFilter", { enumerable: true, get: function () { return b2_particle_system_js_16.b2ParticleSystem_JoinParticleGroupsFilter; } });
var b2_particle_system_js_17 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_ParticleListNode", { enumerable: true, get: function () { return b2_particle_system_js_17.b2ParticleSystem_ParticleListNode; } });
var b2_particle_system_js_18 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_ParticlePair", { enumerable: true, get: function () { return b2_particle_system_js_18.b2ParticleSystem_ParticlePair; } });
var b2_particle_system_js_19 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_Proxy", { enumerable: true, get: function () { return b2_particle_system_js_19.b2ParticleSystem_Proxy; } });
var b2_particle_system_js_20 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_ReactiveFilter", { enumerable: true, get: function () { return b2_particle_system_js_20.b2ParticleSystem_ReactiveFilter; } });
var b2_particle_system_js_21 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_SolveCollisionCallback", { enumerable: true, get: function () { return b2_particle_system_js_21.b2ParticleSystem_SolveCollisionCallback; } });
var b2_particle_system_js_22 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_UpdateBodyContactsCallback", { enumerable: true, get: function () { return b2_particle_system_js_22.b2ParticleSystem_UpdateBodyContactsCallback; } });
var b2_particle_system_js_23 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleSystem_UserOverridableBuffer", { enumerable: true, get: function () { return b2_particle_system_js_23.b2ParticleSystem_UserOverridableBuffer; } });
var b2_particle_system_js_24 = require("./particle/b2_particle_system.js");
Object.defineProperty(exports, "ParticleTriad", { enumerable: true, get: function () { return b2_particle_system_js_24.b2ParticleTriad; } });
// export { /*type*/ b2ParticleIndex as ParticleIndex } from "./particle/b2_particle_system.js";
// export { /*interface*/ b2IParticleDef as IParticleDef } from "./particle/b2_particle.js";
var b2_particle_js_1 = require("./particle/b2_particle.js");
Object.defineProperty(exports, "ParticleDef", { enumerable: true, get: function () { return b2_particle_js_1.b2ParticleDef; } });
var b2_particle_js_2 = require("./particle/b2_particle.js");
Object.defineProperty(exports, "ParticleHandle", { enumerable: true, get: function () { return b2_particle_js_2.b2ParticleHandle; } });
var b2_particle_js_3 = require("./particle/b2_particle.js");
Object.defineProperty(exports, "ParticleFlag", { enumerable: true, get: function () { return b2_particle_js_3.b2ParticleFlag; } });
var b2_particle_js_4 = require("./particle/b2_particle.js");
Object.defineProperty(exports, "CalculateParticleIterations", { enumerable: true, get: function () { return b2_particle_js_4.b2CalculateParticleIterations; } });
var b2_stack_queue_js_1 = require("./particle/b2_stack_queue.js");
Object.defineProperty(exports, "StackQueue", { enumerable: true, get: function () { return b2_stack_queue_js_1.b2StackQueue; } });
var b2_voronoi_diagram_js_1 = require("./particle/b2_voronoi_diagram.js");
Object.defineProperty(exports, "VoronoiDiagram", { enumerable: true, get: function () { return b2_voronoi_diagram_js_1.b2VoronoiDiagram; } });
var b2_voronoi_diagram_js_2 = require("./particle/b2_voronoi_diagram.js");
Object.defineProperty(exports, "VoronoiDiagram_Generator", { enumerable: true, get: function () { return b2_voronoi_diagram_js_2.b2VoronoiDiagram_Generator; } });
var b2_voronoi_diagram_js_3 = require("./particle/b2_voronoi_diagram.js");
Object.defineProperty(exports, "VoronoiDiagram_Task", { enumerable: true, get: function () { return b2_voronoi_diagram_js_3.b2VoronoiDiagram_Task; } });
// export { /*type*/ b2VoronoiDiagram_NodeCallback as VoronoiDiagram_NodeCallback } from "./particle/b2_voronoi_diagram.js";
