import { useState } from 'react';
import { catalogue_player, collect, Last, Last1, Next, Next1, Pause, Player, collect_checked } from '../../assets'
import './player-panel.css'
import { load, save } from 'react-cookies'
const PlayerPanel = ({
    id,
    curIndex = 0,
    maxLong = 0,
    onSwitch = () => { },
    onResfresh = () => { },
    onPlay = () => { },
    play = false,
    openCatalogue = () => { }
}) => {
    const [count, setCount] = useState(parseInt(load(`${id}-fabulous`)) || 0);
    const [fabulous, setFabulous] = useState(false);
    const audio = document.getElementById("myAudio");
    /** 转换数字 */
    function formatNumber(num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
    }
    function toPause() {
        document.getElementById('panel-pause').style.display = 'block';
        document.getElementById('panel-player').style.display = 'none';
        onPlay(false);
    }
    function toPlay() {
        document.getElementById('panel-pause').style.display = 'none';
        document.getElementById('panel-player').style.display = 'block';
        onPlay(true);
    }
    /** 播放 */
    function onAudio() {
        if (!audio.paused) {
            audio.pause(); onPlay(false);
            toPause();
        } else {
            audio.load()
            let playPromise = audio.play()
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    audio.play();
                    onPlay(true);
                    toPlay();
                }).catch(() => {

                })
            };
        }
        onResfresh();
    }
    return <div className='player-panel-mains'>
        {/* <div style={{ width: 0, height: 0 }} /> */}

        <div className='catalogue-player-btn background btns' onClick={() => {
            openCatalogue()
        }} style={{
            backgroundImage: `url(${catalogue_player})`
        }} />
        <div className='last-player background btns' onClick={() => {
            if (curIndex - 1 >= 0) {
                onSwitch(curIndex - 1);
                toPause();
            }
        }} style={{
            backgroundImage: `url(${curIndex === 0 ? Last : Last1})`
        }} />
        <div className='play-player background btns' id='panel-pause' onClick={onAudio} style={{
            backgroundImage: `url(${Player})`
        }} />
        <div className='play-player background btns' id='panel-player' onClick={onAudio} style={{
            backgroundImage: `url(${Pause})`,
            display: 'none'
        }} />
        <div className='last-player background btns' onClick={() => {
            if (curIndex + 1 <= maxLong - 1) {
                onSwitch(curIndex + 1);
                toPause();
            }
        }} style={{
            backgroundImage: `url(${curIndex === maxLong - 1 ? Next : Next1})`
        }} />
        <div className='collect-player background btns' onClick={() => {
            if (!fabulous) {
                setCount(count + 1);
                setFabulous(true);
                save(`${id}-fabulous`, count + 1);
            }
        }} style={{
            backgroundImage: `url(${fabulous ? collect_checked : collect})`
        }}>
            <div className='collect-number'>
                {formatNumber(count)}
            </div>
        </div>
    </div>
}
export default PlayerPanel