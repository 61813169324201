import { useEffect, useState } from "react";
import { BookList, Navigation, Tabs } from "../../../ebooks-components";
import { StyleShell } from "../../../utils/styles";
import { getBookClass } from "../../../api/books";
import './card-screen.css';
import { getParam } from "../../../utils/event";

const CardScreen = () => {
    let search = String(window.location.href);
    const bookType = getParam({ url: search, title: 'bookType' }) || getParam({ url: search, title: 'booktype' });
    const type = getParam({ url: search, title: 'type' });
    const apiKey = getParam({ url: search, title: 'apiKey' }) || getParam({ url: search, title: 'apikey' });

    // search = search.substring(1, search.length).split('=');
    // let pageClass = search?.[2] || false;
    // let devices = search?.[2] || false;
    // pageClass = String(pageClass);
    // if (pageClass.indexOf('&') !== -1) {
    //     pageClass = pageClass.substring(0, pageClass.indexOf('&'));
    // }
    // devices = String(devices);
    // if (devices.indexOf('&') !== -1) {
    //     devices = devices.substring(devices.indexOf('&') + 1, devices.length);
    // }

    sessionStorage.setItem('BOOKTYPE', bookType);
    // sessionStorage.setItem('DEVICES', devices);
    const [curIndex, setCurIndex] = useState(1);
    const [bookClass, setBookClass] = useState(0);
    const [tabSource, setTabSource] = useState([]);
    function switchTab(curIndex, id) {
        setCurIndex(curIndex);
        setBookClass(id);
    }
    async function _getBookClass() {
        try {
            let data = await getBookClass(bookType);
            if (data.code === 200) {
                data.data[data.data.length] = data.data[3];
                data.data[3] = data.data[0];
                delete data.data[0]
                if (apiKey) {
                    setTabSource([data.data[1], data.data[2], data.data[4]])
                } else {
                    setTabSource(data.data);
                }
                setBookClass(data.data[1].id);
            }
        } catch (err) {
            console.log(err);
        }
    }
    document.addEventListener('message', function (event) {
        const javascript = JSON.parse(event)?.javascript;
        if (javascript) {
            eval(javascript);
        }
    });
    useEffect(() => {
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ close: 'true' }))
        _getBookClass();
    }, [])
    return <div style={styles.body} className='card-screen'>
        <div style={styles.headerMain} id='cardHeader'>
            <Navigation
                title={bookType === 1 ? '在线阅读' : bookType === 2 ? '在线听书' : bookType === 4 ? '音乐荟萃' : '电子资源'}
            />
            <Tabs
                dataSource={tabSource}
                switchTab={switchTab}
                curIndex={curIndex}
            />
        </div>
        <BookList
            type={type}
            // switchTab={switchTab}
            // curIndex={curIndex}
            bookClass={bookClass}
            // setBookDataScource={() => { }}
            // selectRol={() => { }}
            bookType={bookType || 1}
        // setThirdParty={() => { }}
        // pageClass={pageClass}
        />
    </div>
}
const styles = StyleShell({
    body: {
        backgroundColor: '#ffffff'
    },
    headerMain: {
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: '999'
    },
    perct: {
        height: 420,
        width: '100%'
    },
    suspension: {
        position: 'fixed',
        right: 0,
        top: '50%',
        // marginRight: 10,x`
        paddingLeft: 40,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: 'rgb(105, 157, 223)',
        color: 'rgba(255,255,255,1)',
        // borderRadius: 5
        borderRadius: '50% 0 0 50%',
        zIndex: '999'
    },
    suspensionClose: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        zIndex: '998'
    }
})
export default CardScreen