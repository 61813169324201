export function pullStart(event) {
    console.log();
    console.log();
}

export function pullMove(event) {

}

export function pullEnd(event, getCatalogue, page, pageSize) {
    const scrollHeight = document.getElementById('catalogue-modal').scrollHeight;
    const clientHeight = document.getElementById('catalogue-modal').clientHeight;
    const scrollTop = document.getElementById('catalogue-modal').scrollTop;
    if (scrollTop + 20 >= scrollHeight - clientHeight) {
        getCatalogue(parseInt(page) + 1, pageSize);
        return true;
    }else{
        return false;
    }
}