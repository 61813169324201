"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawDOM = void 0;
var miniCode = null;
var drawDOM = /** @class */ (function () {
    function drawDOM(props) {
        var _this = this;
        this.frame = document.getElementById('frame');
        this.cover_id = "cover";
        this.cover_box_id = "cover-box";
        this.border_box_id = "border-box";
        this.bubble_id = "bubble";
        this.title_id = "title";
        this.summary_id = "summary";
        this.coverNode = null;
        this.coverBoxNode = null;
        this.borderBoxNode = null;
        this.borderNode = null;
        this.bubbleNode = null;
        this.titleNode = null;
        this.summaryNode = null;
        this.id = 0;
        this.r = 0;
        this.x = 0;
        this.y = 0;
        this.cover = '';
        this.check_id = null;
        this.allDeviation = 15;
        this.titleDeviation = 70;
        this.summaryDeviation = 20;
        this.isChecked = false;
        this.isChecked = props.check_id === props.id;
        this.id = props.id;
        this.x = props.x;
        this.y = props.y;
        this.r = props.r;
        this.cover = props.cover;
        this.check_id = props.check_id;
        //todo 承载体不存在 创建承载体
        if (!this.frame)
            this.createFrame();
        this.cover_id = "cover-".concat(props.id);
        this.cover_box_id = "cover-box-".concat(props.id);
        this.border_box_id = "border-box-".concat(props.id);
        this.bubble_id = "bubble-".concat(props.id);
        this.title_id = "title-".concat(props.id);
        this.summary_id = "summary-".concat(props.id);
        this.coverNode = document.getElementById(this.cover_id);
        this.coverBoxNode = document.getElementById(this.cover_box_id);
        this.borderBoxNode = document.getElementById(this.border_box_id);
        this.bubbleNode = document.getElementById(this.bubble_id);
        this.titleNode = document.getElementById(this.title_id);
        this.summaryNode = document.getElementById(this.summary_id);
        //todo 封面框操作
        this.onCoverBox(props.x, props.y, props.r);
        //todo 封面操作
        this.onCover(props.cover, props.blank, props.qrCode);
        //todo 泡泡覆盖层操作
        this.onBubble();
        //todo 标题操作
        if (this.isChecked) {
            this.onTitle(props.x, props.y, props.r, props.title);
        }
        else if (this.titleNode) {
            this.titleNode.className = "title-close";
            var that_1 = this;
            setTimeout(function () {
                var _a;
                if (that_1.titleNode) {
                    var titleNode = document.getElementById(_this.title_id);
                    if (titleNode) {
                        (_a = that_1.frame) === null || _a === void 0 ? void 0 : _a.removeChild(titleNode);
                    }
                }
            }, 500);
        }
        //todo 书籍介绍操作
        if (this.isChecked) {
            this.onSummary(props.x, props.y, props.r, props.summary);
        }
        else if (this.summaryNode) {
            this.summaryNode.className = "summary-close";
            var that_2 = this;
            setTimeout(function () {
                var _a;
                var summaryNode = document.getElementById(_this.summary_id);
                if (summaryNode) {
                    (_a = that_2.frame) === null || _a === void 0 ? void 0 : _a.removeChild(summaryNode);
                }
            }, 500);
        }
        //todo 边框外层操作
        // this.onBorderBox(props.r);
    }
    /** 创建承载体 */
    drawDOM.prototype.createFrame = function () {
        var frame = document.createElement('div');
        frame.id = 'frame';
        frame.style.overflow = 'hidden';
        frame.style.position = 'absolute';
        frame.style.top = '0';
        frame.style.left = '0';
        frame.style.width = "".concat(window.innerWidth, "px");
        frame.style.height = "".concat(window.innerHeight, "px");
        frame.style.pointerEvents = 'none';
        this.frame = frame;
        document.body.appendChild(frame);
    };
    /** 创建或移动封面框 */
    drawDOM.prototype.onCoverBox = function (x, y, r) {
        var _a;
        if (this.coverBoxNode) {
            this.coverBoxNode.style.top = "".concat(y, "px");
            this.coverBoxNode.style.left = "".concat(x, "px");
            this.coverBoxNode.style.width = "".concat(r * 2, "px");
            this.coverBoxNode.style.height = "".concat(r * 2, "px");
            if (this.isChecked) {
                this.coverBoxNode.style.zIndex = '1000000';
            }
            else {
                this.coverBoxNode.style.zIndex = '10';
            }
        }
        else {
            var coverBox = document.createElement('div');
            coverBox.id = this.cover_box_id;
            coverBox.className = 'frame-common-box';
            coverBox.style.position = 'absolute';
            coverBox.style.top = "".concat(y, "px");
            coverBox.style.left = "".concat(x, "px");
            coverBox.style.width = "".concat(r * 2, "px");
            coverBox.style.height = "".concat(r * 2, "px");
            coverBox.style.borderRadius = "50%";
            coverBox.style.pointerEvents = 'none';
            coverBox.style.backgroundColor = 'rgba(255,255,255,0)';
            coverBox.style.zIndex = '1';
            this.coverBoxNode = coverBox;
            (_a = this.frame) === null || _a === void 0 ? void 0 : _a.appendChild(coverBox);
        }
    };
    /** 创建或移动封面 */
    drawDOM.prototype.onCover = function (cover, blank, qrCode) {
        var _a;
        if (this.coverNode) {
            //todo 二维码改为由获取到二维码后DOM控制
            if (this.isChecked && qrCode) {
                // this.coverNode.style.backgroundImage = `url(${qrCode})`;
                this.coverNode.style.backgroundSize = "80% 80%";
                this.coverNode.style.backgroundPosition = 'center';
                this.coverNode.style.backgroundOrigin = '50%';
                this.coverNode.style.backgroundColor = '#ffffff';
                this.coverNode.style.flexShrink = '0';
            }
            else {
                if (!blank) {
                    this.coverNode.style.opacity = "1";
                    this.coverNode.style.backgroundImage = "url(".concat(cover, ")");
                }
                else {
                    this.coverNode.style.opacity = "0";
                    this.coverNode.style.backgroundImage = "none";
                }
                this.coverNode.style.backgroundSize = "100% 100%";
            }
            this.coverNode.style.width = "".concat(this.r * 2, "px");
            this.coverNode.style.height = "".concat(this.r * 2, "px");
        }
        else {
            var tmpCoverNode = document.createElement('div');
            tmpCoverNode.id = this.cover_id;
            tmpCoverNode.style.position = 'absolute';
            tmpCoverNode.style.backgroundSize = "100% 100%";
            tmpCoverNode.style.backgroundRepeat = "no-repeat";
            tmpCoverNode.style.transform = "scale(1)";
            tmpCoverNode.id = this.cover_id;
            tmpCoverNode.className = 'size-transition';
            tmpCoverNode.style.width = "".concat(this.r * 2, "px");
            tmpCoverNode.style.height = "".concat(this.r * 2, "px");
            tmpCoverNode.style.borderRadius = "50%";
            tmpCoverNode.style.pointerEvents = 'none';
            tmpCoverNode.style.flexShrink = '0';
            this.coverNode = tmpCoverNode;
            (_a = this.coverBoxNode) === null || _a === void 0 ? void 0 : _a.appendChild(tmpCoverNode);
        }
    };
    /** 创建或移动泡泡覆盖层 */
    drawDOM.prototype.onBubble = function () {
        var _a;
        if (this.bubbleNode) {
            if (this.isChecked) {
                this.bubbleNode.style.opacity = '0';
            }
            else {
                this.bubbleNode.style.opacity = "1";
            }
            this.bubbleNode.style.width = "".concat(this.r * 2, "px");
            this.bubbleNode.style.height = "".concat(this.r * 2, "px");
        }
        else {
            var bubble_images = [
                // '/special_1.png',
                // '/special_2.png',
                // '/special_3.png',
                // '/special_4.png',
                // '/special_5.png',
                // '/special_6.png'
                '/special_7.png',
                '/special_8.png',
                '/special_9.png',
                '/special_10.png'
            ];
            var bubbleNode = document.createElement('div');
            bubbleNode.id = this.bubble_id;
            bubbleNode.style.position = 'absolute';
            bubbleNode.style.backgroundSize = "100% 100%";
            bubbleNode.style.backgroundRepeat = "no-repeat";
            bubbleNode.style.transform = "scale(1)";
            bubbleNode.className = 'size-transition';
            bubbleNode.style.width = "".concat(this.r * 2, "px");
            bubbleNode.style.height = "".concat(this.r * 2, "px");
            bubbleNode.style.borderRadius = "50%";
            bubbleNode.style.pointerEvents = 'none';
            bubbleNode.style.flexShrink = '0';
            bubbleNode.style.backgroundImage = "url(".concat(bubble_images[Math.floor(Math.random() * bubble_images.length)], ")");
            this.bubbleNode = bubbleNode;
            (_a = this.coverBoxNode) === null || _a === void 0 ? void 0 : _a.appendChild(bubbleNode);
        }
    };
    /** 创建或移动书籍标题 */
    drawDOM.prototype.onTitle = function (x, y, r, title) {
        var _a;
        if (!title)
            return;
        if (this.titleNode) {
            this.titleNode.style.top = "".concat(y - this.titleDeviation, "px");
            this.titleNode.style.left = "".concat(x, "px");
            this.titleNode.style.width = "".concat(r * 2 - this.allDeviation, "px");
            if (this.isChecked) {
                this.titleNode.style.zIndex = "999999";
            }
            else {
                this.titleNode.style.zIndex = "10";
            }
        }
        else {
            var titleNode = document.createElement('div');
            titleNode.id = this.title_id;
            titleNode.innerHTML = title;
            titleNode.className = "title-open";
            titleNode.style.position = 'absolute';
            titleNode.style.top = "".concat(y - this.titleDeviation, "px");
            titleNode.style.left = "".concat(x, "px");
            titleNode.style.width = "".concat(r * 2 - this.allDeviation, "px");
            titleNode.style.pointerEvents = 'none';
            titleNode.style.color = '#FFFFFF';
            titleNode.style.overflow = 'hidden';
            titleNode.style.textOverflow = 'ellipsis';
            titleNode.style.whiteSpace = 'nowrap';
            titleNode.style.textAlign = 'center';
            titleNode.style.fontSize = '28px';
            titleNode.style.fontWeight = 'bold';
            titleNode.style.zIndex = '3';
            titleNode.style.webkitTextStroke = "1px #002153";
            this.titleNode = titleNode;
            (_a = this.frame) === null || _a === void 0 ? void 0 : _a.appendChild(titleNode);
        }
    };
    /** 创建或移动书籍介绍 */
    drawDOM.prototype.onSummary = function (x, y, r, summary) {
        var _a;
        if (!summary)
            return;
        if (this.summaryNode) {
            this.summaryNode.style.top = "".concat(y + (r * 2) + this.summaryDeviation, "px");
            this.summaryNode.style.left = "".concat(x, "px");
            this.summaryNode.style.width = "".concat(r * 2 - this.allDeviation, "px");
            if (this.isChecked) {
                this.summaryNode.style.zIndex = "999999";
            }
            else {
                this.summaryNode.style.zIndex = "10";
            }
        }
        else {
            var summaryNode = document.createElement('div');
            summaryNode.id = this.summary_id;
            summaryNode.innerHTML = summary;
            summaryNode.className = "summary-open";
            summaryNode.style.position = 'absolute';
            summaryNode.style.top = "".concat(y + this.summaryDeviation, "px");
            summaryNode.style.left = "".concat(x, "px");
            summaryNode.style.width = "".concat(r * 2 - this.allDeviation, "px");
            summaryNode.style.pointerEvents = 'none';
            summaryNode.style.color = '#FFFFFF';
            summaryNode.style.overflow = 'hidden';
            summaryNode.style.textOverflow = 'ellipsis';
            summaryNode.style.webkitLineClamp = '3';
            summaryNode.style.webkitBoxOrient = 'vertical';
            summaryNode.style.display = '-webkit-box';
            summaryNode.style.textAlign = 'center';
            summaryNode.style.fontSize = '18px';
            summaryNode.style.webkitTextStroke = "thin 1px #002153";
            summaryNode.style.zIndex = '3';
            this.summaryNode = summaryNode;
            (_a = this.frame) === null || _a === void 0 ? void 0 : _a.appendChild(summaryNode);
        }
    };
    /** 创建或移动泡泡边框 */
    drawDOM.prototype.onBorderBox = function (r) {
        var _this = this;
        var _a;
        if (this.borderBoxNode) {
            if (this.isChecked) {
                this.borderBoxNode.style.opacity = '1';
            }
            else {
                setTimeout(function () {
                    if (_this.borderBoxNode) {
                        _this.borderBoxNode.style.opacity = "0";
                    }
                }, 500);
            }
            this.borderBoxNode.style.width = "".concat(r * 2 + (this.isChecked ? 70 : 35), "px");
            this.borderBoxNode.style.height = "".concat(r * 2 + (this.isChecked ? 70 : 35), "px");
        }
        else {
            var borderBoxNode = document.createElement('div');
            borderBoxNode.id = this.border_box_id;
            borderBoxNode.style.position = 'absolute';
            borderBoxNode.style.backgroundSize = "100% 100%";
            borderBoxNode.style.backgroundRepeat = "no-repeat";
            borderBoxNode.style.transform = "scale(1)";
            borderBoxNode.className = 'size-transition';
            borderBoxNode.style.width = "".concat(r * 2 + 70, "px");
            borderBoxNode.style.height = "".concat(r * 2 + 70, "px");
            borderBoxNode.style.borderRadius = "50%";
            borderBoxNode.style.pointerEvents = 'none';
            borderBoxNode.style.flexShrink = '0';
            borderBoxNode.style.zIndex = '100';
            borderBoxNode.style.opacity = '0';
            borderBoxNode.style.backgroundImage = "url(/border.gif)";
            this.borderBoxNode = borderBoxNode;
            (_a = this.coverBoxNode) === null || _a === void 0 ? void 0 : _a.appendChild(borderBoxNode);
        }
    };
    return drawDOM;
}());
exports.drawDOM = drawDOM;
