import { images } from '../../../assets'
import { PowerFall } from '../../../components'
import './index.scss'
export default function Power() {
    return <div
        className='power_container'
        style={{ backgroundImage: `url(${images.power_bg})` }}
    >
        <div
            className='power_header'
            style={{ backgroundImage: `url(${images.power_header})` }}
        />
        <div className='content'>
            <PowerFall />
        </div>
        <div
            className='power_footer'
            style={{ backgroundImage: `url(${images.power_footer})` }}
        />
    </div>
}