import purple_1 from './purple_1.png';
import purple_2 from './purple_2.png';
import purple_3 from './purple_3.png';
import purple_4 from './purple_4.png';

export default {
    purple_1,
    purple_2,
    purple_3,
    purple_4
}