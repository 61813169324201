"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.b2RayCastCallback = exports.b2QueryCallback = exports.b2ContactListener = exports.b2ContactImpulse = exports.b2ContactFilter = exports.b2DestructionListener = void 0;
var b2_settings_js_1 = require("../common/b2_settings.js");
var b2_body_js_1 = require("./b2_body.js");
// #endif
/// Joints and fixtures are destroyed when their associated
/// body is destroyed. Implement this listener so that you
/// may nullify references to these joints and shapes.
var b2DestructionListener = /** @class */ (function () {
    function b2DestructionListener() {
    }
    /// Called when any joint is about to be destroyed due
    /// to the destruction of one of its attached bodies.
    b2DestructionListener.prototype.SayGoodbyeJoint = function (joint) { };
    /// Called when any fixture is about to be destroyed due
    /// to the destruction of its parent body.
    b2DestructionListener.prototype.SayGoodbyeFixture = function (fixture) { };
    // #if B2_ENABLE_PARTICLE
    /// Called when any particle group is about to be destroyed.
    b2DestructionListener.prototype.SayGoodbyeParticleGroup = function (group) { };
    /// Called when a particle is about to be destroyed.
    /// The index can be used in conjunction with
    /// b2ParticleSystem::GetUserDataBuffer() or
    /// b2ParticleSystem::GetParticleHandleFromIndex() to determine which
    /// particle has been destroyed.
    b2DestructionListener.prototype.SayGoodbyeParticle = function (system, index) { };
    return b2DestructionListener;
}());
exports.b2DestructionListener = b2DestructionListener;
/// Implement this class to provide collision filtering. In other words, you can implement
/// this class if you want finer control over contact creation.
var b2ContactFilter = exports.b2ContactFilter = /** @class */ (function () {
    function b2ContactFilter() {
    }
    /// Return true if contact calculations should be performed between these two shapes.
    /// @warning for performance reasons this is only called when the AABBs begin to overlap.
    b2ContactFilter.prototype.ShouldCollide = function (fixtureA, fixtureB) {
        var bodyA = fixtureA.GetBody();
        var bodyB = fixtureB.GetBody();
        // At least one body should be dynamic or kinematic.
        if (bodyB.GetType() === b2_body_js_1.b2BodyType.b2_staticBody && bodyA.GetType() === b2_body_js_1.b2BodyType.b2_staticBody) {
            return false;
        }
        // Does a joint prevent collision?
        if (!bodyB.ShouldCollideConnected(bodyA)) {
            return false;
        }
        var filter1 = fixtureA.GetFilterData();
        var filter2 = fixtureB.GetFilterData();
        if (filter1.groupIndex === filter2.groupIndex && filter1.groupIndex !== 0) {
            return (filter1.groupIndex > 0);
        }
        var collide = (((filter1.maskBits & filter2.categoryBits) !== 0) && ((filter1.categoryBits & filter2.maskBits) !== 0));
        return collide;
    };
    // #if B2_ENABLE_PARTICLE
    b2ContactFilter.prototype.ShouldCollideFixtureParticle = function (fixture, system, index) {
        return true;
    };
    b2ContactFilter.prototype.ShouldCollideParticleParticle = function (system, indexA, indexB) {
        return true;
    };
    // #endif
    b2ContactFilter.b2_defaultFilter = new b2ContactFilter();
    return b2ContactFilter;
}());
/// Contact impulses for reporting. Impulses are used instead of forces because
/// sub-step forces may approach infinity for rigid body collisions. These
/// match up one-to-one with the contact points in b2Manifold.
var b2ContactImpulse = /** @class */ (function () {
    function b2ContactImpulse() {
        this.normalImpulses = (0, b2_settings_js_1.b2MakeNumberArray)(b2_settings_js_1.b2_maxManifoldPoints);
        this.tangentImpulses = (0, b2_settings_js_1.b2MakeNumberArray)(b2_settings_js_1.b2_maxManifoldPoints);
        this.count = 0;
    }
    return b2ContactImpulse;
}());
exports.b2ContactImpulse = b2ContactImpulse;
/// Implement this class to get contact information. You can use these results for
/// things like sounds and game logic. You can also get contact results by
/// traversing the contact lists after the time step. However, you might miss
/// some contacts because continuous physics leads to sub-stepping.
/// Additionally you may receive multiple callbacks for the same contact in a
/// single time step.
/// You should strive to make your callbacks efficient because there may be
/// many callbacks per time step.
/// @warning You cannot create/destroy Box2D entities inside these callbacks.
var b2ContactListener = exports.b2ContactListener = /** @class */ (function () {
    function b2ContactListener() {
    }
    /// Called when two fixtures begin to touch.
    b2ContactListener.prototype.BeginContact = function (contact) { };
    /// Called when two fixtures cease to touch.
    b2ContactListener.prototype.EndContact = function (contact) { };
    // #if B2_ENABLE_PARTICLE
    b2ContactListener.prototype.BeginContactFixtureParticle = function (system, contact) { };
    b2ContactListener.prototype.EndContactFixtureParticle = function (system, contact) { };
    b2ContactListener.prototype.BeginContactParticleParticle = function (system, contact) { };
    b2ContactListener.prototype.EndContactParticleParticle = function (system, contact) { };
    // #endif
    /// This is called after a contact is updated. This allows you to inspect a
    /// contact before it goes to the solver. If you are careful, you can modify the
    /// contact manifold (e.g. disable contact).
    /// A copy of the old manifold is provided so that you can detect changes.
    /// Note: this is called only for awake bodies.
    /// Note: this is called even when the number of contact points is zero.
    /// Note: this is not called for sensors.
    /// Note: if you set the number of contact points to zero, you will not
    /// get an EndContact callback. However, you may get a BeginContact callback
    /// the next step.
    b2ContactListener.prototype.PreSolve = function (contact, oldManifold) { };
    /// This lets you inspect a contact after the solver is finished. This is useful
    /// for inspecting impulses.
    /// Note: the contact manifold does not include time of impact impulses, which can be
    /// arbitrarily large if the sub-step is small. Hence the impulse is provided explicitly
    /// in a separate data structure.
    /// Note: this is only called for contacts that are touching, solid, and awake.
    b2ContactListener.prototype.PostSolve = function (contact, impulse) { };
    b2ContactListener.b2_defaultListener = new b2ContactListener();
    return b2ContactListener;
}());
/// Callback class for AABB queries.
/// See b2World::Query
var b2QueryCallback = /** @class */ (function () {
    function b2QueryCallback() {
    }
    /// Called for each fixture found in the query AABB.
    /// @return false to terminate the query.
    b2QueryCallback.prototype.ReportFixture = function (fixture) {
        return true;
    };
    // #if B2_ENABLE_PARTICLE
    b2QueryCallback.prototype.ReportParticle = function (system, index) {
        return false;
    };
    b2QueryCallback.prototype.ShouldQueryParticleSystem = function (system) {
        return true;
    };
    return b2QueryCallback;
}());
exports.b2QueryCallback = b2QueryCallback;
/// Callback class for ray casts.
/// See b2World::RayCast
var b2RayCastCallback = /** @class */ (function () {
    function b2RayCastCallback() {
    }
    /// Called for each fixture found in the query. You control how the ray cast
    /// proceeds by returning a float:
    /// return -1: ignore this fixture and continue
    /// return 0: terminate the ray cast
    /// return fraction: clip the ray to this point
    /// return 1: don't clip the ray and continue
    /// @param fixture the fixture hit by the ray
    /// @param point the point of initial intersection
    /// @param normal the normal vector at the point of intersection
    /// @return -1 to filter, 0 to terminate, fraction to clip the ray for
    /// closest hit, 1 to continue
    b2RayCastCallback.prototype.ReportFixture = function (fixture, point, normal, fraction) {
        return fraction;
    };
    // #if B2_ENABLE_PARTICLE
    b2RayCastCallback.prototype.ReportParticle = function (system, index, point, normal, fraction) {
        return 0;
    };
    b2RayCastCallback.prototype.ShouldQueryParticleSystem = function (system) {
        return true;
    };
    return b2RayCastCallback;
}());
exports.b2RayCastCallback = b2RayCastCallback;
