import { api_mini, api_pro } from '../utils/config';
import request from '../utils/request';

/**
 * 获取分类下的书籍
 */
const getBooks = (id, page, toles = 20, bookType = 1) => {
    return request({
        // url: '/books/classification/bookinfo',
        url: `${api_pro}/aggregation/category/${id}`,
        method: 'GET',
        params: {
            page: page,
            pageSize: 20,
            bookType
        }
    })
}

/**
 * 获取书籍额外信息
 */
const getBooksData = (id, type) => {
    return request({
        url: `${api_pro}/books/extraBookinfo`,
        method: 'GET',
        params: {
            id: id,
            type: type
        }
    })
}

/**
 * 获取书籍听书资源分集
 */
const getBookOnlineResource = (id) => {
    return request({
        url: `${api_pro}/books/onlineResource`,
        method: 'GET',
        params: {
            type: 'audio',
            id: id,
            page: 1,
            per_page: 22
        }
    })
}

/**
 * 获取音频链接
 */
const getBookOnlineResourceUrl = (id) => {
    return request({
        url: `${api_pro}/books/onlineResourceUrl`,
        method: 'GET',
        params: {
            chapterId: id
        }
    })
}

/**
 * 获取智图精选
 */
const getSelected = () => {
    return request({
        url: 'http://service.shijizhitu.com/api/v1/config/resource',
        method: 'GET',
        params: {
            libraryCode: '667a56fe529e516c533a'
        }
    })
}

/**
 * 获取书籍分类
 */
const getBookClass = (bookType = 1) => {
    return request({
        url: `${api_pro}/aggregation/category`,
        method: 'GET',
        params: {
            libraryCode: '667a56fe529e516c533a',
            bookType
        }
    })
}

/** 获取书籍章节 */
const getCatalogue = ({ uniqueID, page, pageSize, bookType }) => {
    if (!uniqueID) return false;
    return request({
        url: `${api_pro}/aggregation/chapterlist`,
        method: 'GET',
        params: {
            bookType,
            page,
            pageSize,
            uniqueID,
            machineID: 2
        }
    })
}

/** 获取电子资源地址 */
const getResource = ({ id, chapterID, bookType = 1 }) => {
    return request({
        url: `${api_pro}/aggregation/resource`,
        method: 'GET',
        params: {
            bookType,
            uniqueID: id,
            chapterID
        }
    })
}

/** 获取电子资源数据 */
const getResourceData = ({ id, chapterID, bookType = 1 }) => {
    return request({
        url: `${api_pro}/aggregation/resource/data`,
        method: 'GET',
        params: {
            bookType,
            uniqueID: id,
            chapterID
        }
    })
}

const searchBook = ({ page, pageSize, type, keyword, libraryCode }) => {
    return request({
        url: `${api_pro}/aggregation/search`,
        method: 'GET',
        params: {
            page,
            pageSize,
            bookType: type,
            keyword,
            libraryCode
        }
    })
}

/** 生成小程序码 */
export const createMiniCode = ({ uniqueID }) => {
    return request({
        url: `${api_mini}/api/v2/mobile/unlimitedQRCode`,
        method: 'POST',
        data: {
            scene: uniqueID,
            page: "otherPages/pages/book-details/index",
            check_path: true,
            width: 430,
        }
    })
}

/** 音乐列表 */
export const getMusicList = ({
    id = 93,
    page = 1,
    pageSize = 20
}) => {
    return request({
        url: `${api_pro}/aggregation/category/${id}`,
        method: 'GET',
        params: {
            page,
            pageSize,
            bookType: 4
        }
    })
}

export {
    getBooks,
    getBooksData,
    getBookOnlineResource,
    getBookOnlineResourceUrl,
    getSelected,
    getBookClass,
    getResource,
    getResourceData,
    getCatalogue,
    searchBook
}