import i1 from './i1.png';
import i2 from './i2.png';
import i3 from './i3.png';
import i4 from './i4.png';
import i5 from './i5.png';
import i6 from './i6.png';
import i7 from './i7.png';
import i8 from './i8.png';
import i9 from './i9.png';
import i10 from './i10.png';
import i11 from './i11.png';
import i12 from './i12.png';
export default {
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
}