import { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import './book-card.css'
const QRCode = require('qrcode')
const BookCard = ({ dataSources, type }) => {
    const dataSource = dataSources || JSON.parse(sessionStorage.getItem("BOOK-INFO"));
    useEffect(() => {
        var opts = {
            errorCorrectionLevel: 'M',
            type: 'image/png',
            quality: 0.5,
            margin: 1,
            color: {
                dark: "#000000",
                light: "#d8d8d8"
            }
        }
        QRCode.toDataURL(`https://mini.shijizhitu.com/wx_mini_search/?title=${dataSource?.title}&libraryCode=auto`, opts, function (err, url) {
            if (err) throw err
            if (document.getElementById('qrmain')) {
                document.getElementById('qrmain').src = url
            }
        })
    }, [])
    return <div className='book-card-main'>
        <div className='book-card-box'>
            <div className='book-card-block'>
                <img className='cover' src={dataSource?.cover} />
                <div className='info-box' style={type === 'h5' ? { width: `55%` } : {}}>
                    {String(dataSource?.title)?.length >= 5 ?
                        (<Marquee
                            gradientColor={'rgba(0,0,0,0)'}
                            style={{ zIndex: 0 }}
                        >
                            <div className='title'>
                                {dataSource?.title}
                            </div>
                        </Marquee>) : (
                            <div className='title'>
                                {dataSource?.title}
                            </div>
                        )}
                    {String(dataSource?.author)?.length >= 10 ?
                        (<Marquee
                            gradientColor={'rgba(0,0,0,0)'}
                            style={{ zIndex: 0 }}
                        >
                            <div className='author'>
                                {dataSource?.title}
                            </div>
                        </Marquee>) : (
                            <div className='author'>
                                {dataSource?.title}
                            </div>
                        )
                    }
                    <div className='desc'>
                        {dataSource?.summary}
                    </div>
                </div>
                {type !== 'h5' && (
                    <div className='qrcode-main'>
                        <img className='qrcode-logo' id='qrmain' />
                        <div className='qrcode-tips'>
                            扫一扫阅读电子版
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
}
export default BookCard