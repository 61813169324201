import waterfall_bg from './waterfall_bg.png';
import waterfall_bg_2 from './waterfall_bg_2.png';
import waterfall_bg_2_cloud from './waterfall_bg_2_cloud.png';
import book_info from './book_info.png';
import bubble from './bubble';
import power_bg from './power_bg.png';
import power_header from './power_header.png';
import power_footer from './power_footer.png';
import cultural_bg from './cultural_bg.png';
import cultural_card_bg_left from './cultural_card_bg_left.png';
import cultural_card_bg_right from './cultural_card_bg_right.png';
import music_bg from './music_bg.png';
// import img1 from './1.jpg';
import img2 from './2.jpg';
import img3 from './3.png';
//todo 光环
import ring from './ring.png';
// //todo 边框
// import border from './border.png';
//todo 小程序码
import wxcode from './wxcode.png';
//todo 默认书籍封面
import defaults_cover_1 from './defaults_cover1.png';
import defaults_cover_2 from './defaults_cover2.png';
import defaults_cover_3 from './defaults_cover3.png';
import defaults_cover_4 from './defaults_cover4.png';
import cultural_head_img from './cultural_head_img';
import cultural_footer from './cultural_footer.png';
import light_disk from './light_disk.png';
import light_disk_son from './light_disk_son.png';
import layout_bottom from './layout_bottom.png';
import music_item_bottom from './music_item_bottom.png';
import music_bottom from './music_bottom.png';
import music_header from './music_header.png';

/** 边框 */
import border from './border';

const img1 = `https://p.qqan.com/up/2023-2/16769398417783750.jpg`;


export default {
    waterfall_bg,
    waterfall_bg_2,
    waterfall_bg_2_cloud,
    book_info,
    bubble,
    power_bg,
    ring,
    // border,
    border,
    wxcode,
    defaults_cover_1,
    defaults_cover_2,
    defaults_cover_3,
    defaults_cover_4,

    img1,
    img2,
    img3,

    power_header,
    power_footer,
    cultural_bg,
    cultural_card_bg_left,
    cultural_card_bg_right,
    cultural_head_img,
    cultural_footer,
    music_bg,
    light_disk,
    light_disk_son,
    layout_bottom,
    music_item_bottom,
    music_bottom,
    music_header
}