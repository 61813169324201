import { StyleShell } from "../../utils/styles";
import { Black_white } from '../../assets';
import { useEffect, useState } from "react";

const Suspension = ({ onClick }) => {
    const [dragStatus, setDragStatus] = useState(false);
    // let dragStatus = false;
    let direction = 'right';
    let dragShrink = null;
    function dragStart() {
        setDragStatus(true);
        clearTimeout(dragShrink);
    }
    function dragMove(event) {
        if (!dragStatus) return;
        let windowWidth = document.body.clientWidth;
        let windowHeight = document.body.clientHeight;
        let dragBolck = document.getElementById('dragBolcker');
        let moveX = event.clientX ? event.clientX : event.changedTouches[0].clientX;
        let moveY = event.clientY ? event.clientY : event.changedTouches[0].clientY;
        if (moveX <= windowWidth - parseFloat(dragBolck.style.width) && moveX >= 0) {
            if (moveX >= windowWidth - parseFloat(dragBolck.style.width) - StyleShell({ width: { width: 100 } }).width.width) {
                dragBolck.style.left = `${windowWidth - parseFloat(dragBolck.style.width)}px`;
            } else if (moveX <= StyleShell({ width: { width: 100 } }).width.width) {
                dragBolck.style.left = `${0}px`;
            } else {
                dragBolck.style.left = `${moveX}px`;
            }
        }
        if (moveY <= windowHeight - StyleShell({ width: { width: 100 } }).width.width && moveY >= 0) {
            dragBolck.style.top = `${moveY}px`;
        }
    }
    function dragEnd(event) {
        let windowWidth = document.body.clientWidth;
        let dragBolck = document.getElementById('dragBolcker');
        let moveX = event.clientX ? event.clientX : event.changedTouches[0].clientX;
        if (moveX <= windowWidth - parseFloat(dragBolck.style.width) && moveX >= 0) {
            if (moveX >= (windowWidth - parseFloat(dragBolck.style.width)) / 2) {
                dragBolck.style.left = `${windowWidth - parseFloat(dragBolck.style.width)}px`;
                direction = 'right'
            } else if (moveX < (windowWidth - parseFloat(dragBolck.style.width)) / 2) {
                dragBolck.style.left = `${0}px`;
                direction = 'left'
            } else {
                dragBolck.style.left = `${moveX}px`;
            }
        }
        dragShrinkConf();
        setDragStatus(false);
    }
    function dragShrinkConf() {
        let windowWidth = document.body.clientWidth;
        let dragBolck = document.getElementById('dragBolcker');
        dragShrink = setTimeout(() => {
            if (direction === 'right') {
                dragBolck.style.left = `${windowWidth - (parseFloat(dragBolck.style.width) / 2)}px`;
            }
        }, 2000)
    }
    useEffect(() => {
        return (() => {
            setDragStatus(false);
        })
    }, [])
    return (
        <div
            style={styles.suspensionMain}
            onTouchStart={dragStart}
            onTouchMove={dragMove}
            onTouchEnd={dragEnd}
            onMouseDown={dragStart}
            onMouseMove={dragMove}
            onMouseUp={dragEnd}
            id="dragBolcker"
            onClick={() => onClick ? onClick() : {}}
        >
            <img style={styles.suspensionMainIcon} src={Black_white} />
        </div>
    )
}
const styles = StyleShell({
    suspensionMain: {
        height: 100,
        width: 100,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        cursor: 'pointer',
        zIndex: '99999',
        WebkitTapHighlightColor: 'transparent',
        position: 'absolute',
        top: '20%',
        right: -(100 / 2),
        zIndex: 999999999
    },
    suspensionMainIcon: {
        position: 'absolute',
        height: 40,
        width: 40,
        top: 30.28,
        left: 38,
        right: 22,
        bottom: 29.72,
        transform: 'rotate(45deg)'
    }
})
export default Suspension