import axios from "axios"

export default function request(config) {
    return new Promise((success, error) => {
        (async () => {
            let result = await axios(config);
            if (result.status === 200) {
                result = result.data;
                if (result.code === 200) {
                    success(result)
                } else {
                    console.error(result.msg);
                    error(true)
                }
            } else {
                console.error(result);
                error(true);
            }
        })()
    })
}