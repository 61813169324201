import images from '../../../../../assets/images';
import './index.scss';
export default function LightDisk({
    title = '',
    cover = images.defaults_cover_1,
    size,
    onClick
}) {
    return <div className={`light-disk-container ${size}`} onClick={onClick}>
        <div
            style={{
                backgroundImage: `url(${images.light_disk})`
            }}
            className={`light-disk-img ${size}`}
        />
        <div
            style={{
                backgroundImage: `url(${cover})`
            }}
            className={`cover-img ${size}`}
        />
        <div
            style={{
                backgroundImage: `url(${images.light_disk_son})`
            }}
            className='light-disk-son-img'
        />
        <div
            style={{
                backgroundImage: `url(${images.music_item_bottom})`
            }}
            className={`music-item-bottom ${size}`}
        >
            {title}
        </div>
    </div>
}