import { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getBooks, getCatalogue, getResource } from '../../api/books';
import BookInfo from '../book-info/book-info';
import './booklist.css'
let pages = 1;
let toles = 20;
let pull_more = true;
let move_start = false;
let move_load = false;
const BookList = ({ bookType, bookClass, type, pageClass }) => {
    const [top, setTop] = useState(0);
    const [books, setBooks] = useState();
    const [dataSource, setDataSource] = useState();

    /** 获取书籍列表 */
    async function _getBooks(bookClass, pages, toles, type) {
        let data;
        try {
            let dataScource = await getBooks(bookClass, pages, toles, bookType);
            data = dataScource;
            if (dataScource.code === 200 && !type) {
                setBooks(dataScource.data.bookList);
                if (dataScource.data.page.totalPage <= dataScource.data.page.pageSize) {
                    pull_more = true;
                } else {
                    pull_more = false;
                }
            }
        } catch (err) {
            console.log(err);
        }
        return data;
    }

    /** 获取电子资源数据 */
    async function checkResource(item, chapterID = 1, type) {
        const id = item?.resourceIDs?.read || item?.resourceIDs?.audio;
        try {
            let data = await getResource({ id, chapterID, bookType });
            if (data.code === 200) {
                item.url = data.data.url;
                setDataSource(item);
            }
        } catch (err) {
            console.log(err);
        }
    }

    /** 获取书籍目录 */
    async function _getCatalogue(item, type) {
        const id = item?.resourceIDs?.read || item?.resourceIDs?.audio;
        try {
            let results = await getCatalogue({
                uniqueID: id,
                page: 1,
                pageSize: 1,
                bookType
            })
            const chapterID = results?.data?.chapterList?.[0]?.chapterID;
            checkResource(item, chapterID, type);
        } catch (error) {
            console.error(error);
        }
    }

    /** 上拉开始 */
    function PullUpStart(e) {
        move_start = e.touches ? e.touches[0] : e;
    }

    /** 上拉中 */
    function PullUpMove(e) {
        if (-(e.changedTouches?.[0].clientY - move_start.clientY) >= 50) {
            move_load = true;
        } else {
            move_load = false;
        }
    }

    /** 上拉结束 */
    function PullUpEnd(e) {
        if (move_load) {
            if (pull_more) return false;
            pages = pages + 1;
            _getBooks(bookClass, pages, toles, true).then(data => {
                if (data) {
                    if (data.data.page.page === data.data.page.total) {
                        pull_more = true;
                    }
                    if (data.data.bookList.length === 0) {
                        pull_more = false;
                    } else {
                        const List = [];
                        data.data.bookList.map(items => {
                            List.push(items);
                        })
                        if (books) {
                            setBooks(books.concat(List));
                        } else {
                            setBooks(List);
                        }
                    }
                }
            })
        }
        move_load = false;
    }

    useEffect(() => {
        pages = 1;
        move_load = false;
        if (!bookClass || bookClass === ' ') return;
        _getBooks(bookClass, pages, toles);
        setTop(document.getElementById('cardHeader').offsetHeight);
        window.scrollTo(0, 0);
    }, [bookClass])
    return <div
        className={`booklist-mian ${bookType === 4 && 'booklist-mian-4'}`}
        onTouchStart={(e) => PullUpStart(e)}
        onTouchMove={(e) => PullUpMove(e)}
        onTouchEnd={(e) => PullUpEnd(e)}
        onMouseDown={(e) => PullUpStart(e)}
        onMouseMove={(e) => PullUpMove(e)}
        onMouseUp={(e) => PullUpEnd(e)}
        style={{
            marginTop: `${top}px`
        }}
    >
        {useMemo(() => {
            return books?.map((item, index) => {
                let bookId = bookType === `1` ? item.resourceIDs.read : bookType === `2` ? item.resourceIDs.audio : bookType === `4` ? item.id : null;
                bookId = typeof bookId === 'object' ? bookId?.[0] : bookId;
                return pageClass !== 'not-details' ? (
                    <Link
                        className='bookcard-main'
                        key={`booklist-card-${index}`}
                        to={bookType === 4 ? `/Player` : type === 'h5' ? `/DetailsPhone` : `/Details`}
                        onClick={() => {
                            item.bookId = bookId;
                            sessionStorage.setItem('BOOK-INFO', JSON.stringify(item));
                            return true;
                        }}
                        type={index % 3 === 0 ? 'frist' : `item-${index}`}
                    >
                        <div
                            onClick={() => bookType === 4 ? {} : _getCatalogue(item, false)}

                        >

                            <img src={item.cover} />
                            <div className={`title ${bookType === 4 && 'title_column_2'}`}>
                                {item.title}
                            </div>
                        </div>
                    </Link>
                ) : (
                    <div
                        onClick={() => _getCatalogue(item, true)}
                        className='bookcard-main'
                        key={`booklist-card-${index}`}
                    >
                        <img src={item.cover} />
                        <div className='title'>
                            {item.title}
                        </div>
                    </div>
                )
            })
        }, [books])}
        {dataSource && (
            <BookInfo bookType={bookType} dataSource={dataSource} onClose={() => {
                setDataSource()
            }} />
        )}
        <div
            style={{
                marginTop: `${top}px`
            }}
            className='limit'
        />
    </div>
}
export default BookList