import images from "../../../../../assets/images";
import './index.scss';
export default function RightCard({
    renders = true,
    data,
    style = {},
    onClick = () => { }
}) {
    return <div style={style} onClick={onClick} className="right-card-container">
        <div
            className='cultural_card_bg'
            style={renders ? {
                backgroundImage: `url(${images.cultural_card_bg_right})`
            } : {}}
        />
        <div
            className='cultural_head_img'
            style={renders ? {
                backgroundImage: `url(${data.cover})`
            } : {}}
        />
        <div className='user-info'>
            <div className='name'>
                {renders && data.name}
            </div>
            {renders && <div className='limit' />}
            <div className='honor'>
                {renders && data.honor}
            </div>
            <div className='description'>
                {renders && data.description}
            </div>
        </div>
    </div>
}