import './index.css'
const BookCard = ({ id, dataSource, style, onClick }) => {
    return <div onClick={() => onClick(dataSource)} id={id} style={{ ...{ backgroundImage: `url(${dataSource?.cover})` }, ...style }} className='power-fall-book-card-container'>
        <div className='bottom-bar'>
            <div className='text'>
                {dataSource?.title}
            </div>
        </div>
    </div>
}
export default BookCard