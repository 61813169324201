import { useEffect } from 'react';
import './player-slider.css';
let audioTimer = null, move = false, lastPx = null;
const PlayerSlider = ({ resfresh, onStop = () => { } }) => {
    function toDouble(number) {
        return String(number).length === 1 ? `0${number}` : number;
    }
    /** 时间计算 */
    function TimeConversion(second) {
        var secondTime = parseInt(second);
        var minuteTime = 0;
        var hourTime = 0;
        if (secondTime > 60) {
            minuteTime = parseInt(secondTime / 60);
            secondTime = parseInt(secondTime % 60);
            if (minuteTime > 60) {
                hourTime = parseInt(minuteTime / 60);
                minuteTime = parseInt(minuteTime % 60);
            }
        }
        return `${parseInt(hourTime) ? `${toDouble(parseInt(hourTime))}:` : ''}${parseInt(minuteTime) ? `${toDouble(parseInt(minuteTime))}:` : '00:'
            }${toDouble(parseInt(secondTime))}`;
    }
    function toPause() {
        document.getElementById('panel-pause').style.display = 'block';
        document.getElementById('panel-player').style.display = 'none';
    }
    function toPlay() {
        document.getElementById('panel-pause').style.display = 'none';
        document.getElementById('panel-player').style.display = 'block';
    }
    /** 初始化 */
    function init() {
        const audio = document.getElementById("myAudio");
        clearInterval(audioTimer);
        audioTimer = setInterval(() => {
            const currentTime = audio?.currentTime;
            const duration = audio?.duration;
            const slider = document.getElementById("slider-block");
            if (audio?.paused) {
                clearInterval(audioTimer);
            }
            if (slider) {
                const time = `${TimeConversion(currentTime)}/${TimeConversion(duration)}`
                if (!currentTime || !duration) {
                    slider.innerHTML = `00:00/00:00`;
                    const SliderSpeedProgress = document.getElementById('slider-speed-progress');
                    const SliderBlockDom = document.getElementById('slider-block');
                    SliderBlockDom.style.left = `0px`;
                    SliderSpeedProgress.style.width = `0px`;
                } else {
                    if (currentTime === duration) {
                        // audio.play();
                        onStop();
                        toPause();
                    }
                    slider.innerHTML = time;
                }

                const sliderInTotal = document.getElementById('slider-in-total');
                const sliderSpeedProgress = document.getElementById('slider-speed-progress');
                if (sliderInTotal && sliderSpeedProgress) {
                    const long = currentTime / duration * 100;
                    sliderSpeedProgress.style.width = `${long}%`;
                    slider.style.left = `calc(${long}% - ${slider.offsetWidth / 2}px)`
                }
            }
        }, 200)
    }

    function onTouchStart() {
        const audio = document.getElementById("myAudio");
        if (!audio?.paused) {
            move = true;
        }
    }
    function onTouchMove(event) {
        const audio = document.getElementById("myAudio");
        const SliderBlockDom = document.getElementById('slider-block');
        const MaxTotalDOM = document.getElementById('slider-in-total');
        const SliderSpeedProgress = document.getElementById('slider-speed-progress');
        if (move && SliderBlockDom && MaxTotalDOM && SliderSpeedProgress) {
            clearInterval(audioTimer);
            const nowPx = event.touches[0].clientX;
            if (lastPx) {
                audio.pause();
                const deviation = -(lastPx - nowPx);
                const envPx = parseFloat(SliderSpeedProgress.offsetWidth) + parseFloat(deviation);
                const maxWidth = MaxTotalDOM.offsetWidth;
                if (envPx <= maxWidth && envPx >= 0) {
                    SliderBlockDom.style.left = `${envPx - (parseFloat(SliderBlockDom.offsetWidth) / 2)}px`;
                    SliderSpeedProgress.style.width = `${envPx}px`
                }
            }
            lastPx = nowPx;
        }
    }
    function onTouchEnd() {
        const audio = document.getElementById("myAudio");
        const MaxTotalDOM = document.getElementById('slider-in-total');
        const SliderSpeedProgress = document.getElementById('slider-speed-progress');
        const nowCurrentTime = SliderSpeedProgress.offsetWidth / MaxTotalDOM.offsetWidth * audio.duration;
        audio.currentTime = nowCurrentTime;
        audio.play();
        init();
        toPlay();
        lastPx = null;
    }
    useEffect(() => {
        init();
        return () => {
            clearInterval(audioTimer);
        }
    }, [resfresh])
    return <div className='player-slider-main'>
        <div className='in-total' id='slider-in-total' />
        <div className='speed-progress' id='slider-speed-progress' />
        <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className='slider-block'
            id='slider-block'
        >
            00:00/00:00
        </div>
    </div>
}
export default PlayerSlider