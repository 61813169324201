import { drop_1, catalogue_player_1, playing } from '../../assets';
import './player-catalogue.css';
const PlayerCatalogue = ({ setCurIndex, curIndex, dataSource, onClose, onMore }) => {
    return <div className='player-catalogue-main' onScrollCapture={(e) => {
        if (e.target.scrollTop + e.target.offsetHeight + 10 >= e.target.scrollHeight) {
            onMore()
        }
    }}>
        <div className='mark' onClick={onClose} />
        <div className='player-catalogue-box'>
            <div className='header'>
                <div className='label'>
                    当前播放
                </div>
                <div className='opertion'>
                    <img className='catalogue-player' src={catalogue_player_1} onClick={() => setCurIndex(0)} />
                    <div className='order-main'>
                        <img className='order-png' src={drop_1} />
                        <div className='order-title'>
                            降序
                        </div>
                    </div>
                </div>
            </div>
            <div className='player-catalogue-content'>
                {dataSource?.map((item, index) => {
                    return <div onClick={() => {
                        curIndex !== index && (
                            setCurIndex(index)
                        )
                    }} className='catalogue-item' key={`player-item-${index}`}>
                        {curIndex === index && (
                            <img src={playing} className="catalogue-playing" />
                        )}
                        <div className={curIndex === index ? 'catalogue-title-active' : 'catalogue-title'}>
                            {item?.subtitle || item?.title || item?.name}
                        </div>
                    </div>
                })}
                <div className='limit' />
            </div>
        </div>
    </div>
}
export default PlayerCatalogue