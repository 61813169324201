import { getResource } from "../../../api/books";
import { Black } from "../../../assets";
import { BookCard, Catalogue } from "../../../ebooks-components";
import { StyleShell, SystemDevice } from "../../../utils/styles"
// import { message } from 'antd'
import { useState, useEffect } from "react";
import Suspension from "../../../ebooks-components/suspension/suspension";
import { getParam } from "../../../utils/event";
const DetailsScreen = () => {
    const [resource, setResource] = useState();
    const dataSource = JSON.parse(sessionStorage.getItem('BOOK-INFO'));
    const bookType = sessionStorage.getItem('BOOKTYPE');
    const apiKey = getParam({ url: window.location.href, title: 'apiKey' }) || getParam({ url: window.location.href, title: 'apikey' });
    function checkVal(val) {
        return String(val).indexOf('#') !== -1 ? String(val).split('#')[0] : val;
    }
    function toBlack() {
        const bookType = String(window.location.search).substring(1, String(window.location.search).length).split('=');
        document.referrer === '' ?
            window.location.href = `/?bookType=${bookType[1]}${apiKey ? `&apiKey=${checkVal(apiKey)}` : ``}` :
            window.history.back();
    }

    async function _getResource(chapterID) {
        try {
            let results = await getResource({
                id: dataSource.bookId,
                chapterID,
                bookType
            });
            if (results.code === 200) {
                setResource(results.data.url);
            } else {
                // message.error(results.msg);
                console.error(results.msg);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // document.body.style.overflowY = 'hidden';
        // if (resource) {
        //     document.getElementById('root').scrollTo(0, 0);
        //     document.getElementById('root').style.overflowY = 'hidden'
        // } else {
        //     document.getElementById('root').scrollTo(0, 0);
        //     document.getElementById('root').style.overflowY = 'scroll'
        // }
        // return () => {
        //     document.body.style.overflowY = 'scroll';
        // }
    }, [resource])

    return <div className='loadView' style={styles.DetailsMian}>
        <div style={styles.titleBlockMain} id="titleblock">
            <div style={styles.titleBlockMainTop} onClick={toBlack}>
                <img className='loadimg' style={styles.titleBlockMainLogo} src={Black} />
                {'返回'}
            </div>
        </div>
        <BookCard />
        <Catalogue apiKey={checkVal(apiKey)} setResource={_getResource} />
        {resource && (
            <>
                <iframe
                    name='listen_read_web'
                    style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
                    src={resource}
                    className="iframes"
                    id='iframes'
                    frameborder="no"
                >
                </iframe>
                <Suspension onClick={() => setResource(null)} />
            </>
        )}
    </div>
}
const styles = StyleShell({
    DetailsMian: {
        display: 'block',
        // height: SystemDevice([width,height]) === 'ipad' ? 'auto' : '100%',
        backgroundColor: '#d8d8d8',
    },
    titleBlockMain: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D8D8D8',
        left: 5,
        zIndex: 999,
        paddingTop: 30,
        color: '#6B6B6B'
    },
    titleBlockMainTop: {
        position: 'relative',
        zIndex: 999999,
        marginLeft: SystemDevice() === 'ipad' ? 70 : '7.78%',
        display: 'flex',
        alignItems: 'center',
        fontSize: SystemDevice() === 'ipad' ? 40 : 30,
        width: 'auto'
    },
    titleBlockMainLogo: {
        height: SystemDevice() === 'ipad' ? 40 : 32,
        width: SystemDevice() === 'ipad' ? 40 : 32,
    }
})
export default DetailsScreen