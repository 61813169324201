import images from "../../../../assets/images";

export const DATASOURCE = [
    {
        name: '耶稣',
        honor: '基督教创始人',
        description: '耶稣相传的耶稣基督诞生年即公元元年,耶稣是基督教的创始人,其称号基督为希伯来文弥赛亚（意为受膏者，原指上帝派来拯救以色列的救世主）之希腊文译音。',
        cover: images.cultural_head_img.i1,
        direction: 'left',
        qrcode_description:'耶 稣相传的耶稣基督诞生年即公元元年,耶稣是基督教的创始人,其称号基督为希伯来文弥赛亚（意为受膏者，原指上帝派来拯救以色列的救世主）之希腊文译音。根据《新约》四福音书的记载，耶稣是天主之子，由童贞女玛丽亚受圣神感孕，生于伯利恒城客店的马厩之中。耶稣曾引用《旧约》决非偶然,虽然他是基督教的祖先，但是他是个虔诚的犹太人。自从公元1世纪耶稣诞生在以色列伯利恒的小镇上至今，没有一个教派和党派超越其对世人的影响力。虽然现代科学、文明和经济的发展对宗教产生冲击,但从基督教在历史和现实的影响看,耶稣的影响显然是巨大的。基督教的教义及其神学思想，对西方文明具有全面而深刻的影响,耶稣完全是作为道德和精神领袖而使人感知他的存在,他在本册中名列第一。【以色列】'
    },
    {
        name: '牛顿',
        honor: '物理学家、数学家',
        description: '牛顿他于1642年圣诞节出生在英格兰伍尔斯索蒲村，艾萨克·牛顿是曾出现过的最伟大、最有影响的科学家。',
        cover: images.cultural_head_img.i2,
        direction: 'right',
        qrcode_description:'牛 顿他于1642年圣诞节出生在英格兰伍尔斯索蒲村，艾萨克·牛顿是曾出现过的最伟大、最有影响的科学家。仅就光学方面的成就或许就可以使他在本书中占有一席之地，但是他在这方面的成就比起他在数学或力学方面的成就来，那就相形见绌了。他对数学的贡献主要是发明了积分，这一发明是当代数学中最伟大的成就，但是牛顿最重要的发现是在力学方面,特别是发现了万有引力。牛顿定律可以而且已被用来解决极其广泛的科学和工程学方面的问题。牛顿奠定了某些学科的理论基础,导致以后世界上的一系列科学革命。在2005年，英国皇家学会进行一场“谁是科学史上最有影响力的人”的民意调查，在被调查的皇家学会会员和纲民投票中，牛顿被认为比爱因斯坦更具有影响力。【英国】'
    },
    {
        name: '巴赫',
        honor: '伟大的作曲家',
        description: '他是成功地把西欧不同民族的音乐风格浑然溶为一体的开山大师。',
        cover: images.cultural_head_img.i11,
        direction: 'left',
        qrcode_description:'巴赫伟大的作曲家约翰·塞巴斯蒂安·巴赫于1685年出生在德国爱森纳赫。他是成功地把西欧不同民族的音乐风格浑然溶为一体的开山大师。他萃集意大利、法国和德国传统音乐中的精华，曲尽其妙，珠联璧合，天衣无缝。在近来一个半世纪中他的名气在不断地增长，今天一般认为他是超乎时空的最伟大的两三位作曲家之一，而且有些人认为他是其中最伟大的作曲家。【德国】'
    },
    {
        name: '门捷列夫',
        honor: '化学家',
        description: '门捷列夫元素周期表的发现，是近代化学史上的一个创举，对于促进化学的发展，起了巨大的作用。',
        cover: images.cultural_head_img.i12,
        direction: 'right',
        qrcode_description:'门捷列夫元素周期表的发现，是近代化学史上的一个创举，对于促进化学的发展，起了巨大的作用。看到这张表，人们便会想到它的最早发明者——门捷列夫。俄罗斯化学家德米特里·伊万诺维奇·门捷列夫于1834生在西伯利亚。他立志从事这项探索工作起，一直花了大约20年的功夫，才终于在1869年发表了元素周期律。现在，我们知道，在人类生活的浩瀚的宇宙里，一切物质都是由这100多种元素组成的，包括我们人本身在内。【俄罗斯】'
    },
    {
        name: '穆罕默德',
        honor: '伊斯兰教的先知',
        description: '他将一个散漫无纪的阿拉伯民族统一起来了，并将一个伟大的宗教带来给他们。人类从愚昧走向文明会产生宗教,宗教会推动和制约着文明的发展。',
        cover: images.cultural_head_img.i3,
        direction: 'left',
        qrcode_description:'穆罕默德伊斯兰教的先知穆罕默德是世界上几个最有影响的人物之一，他是在宗教和非宗教领域内都取得了无比辉煌的成就。穆罕默德于570年出生在阿拉伯南部的麦加市贫寒家，当时世界的一个落后地区。他将一个散漫无纪的阿拉伯民族统一起来了，并将一个伟大的宗教带来给他们。人类从愚昧走向文明会产生宗教,宗教会推动和制约着文明的发展。在他死后十三个世纪后的今天，他的影响仍然强烈而巨大。既然基督教徒比伊斯兰教徒大约多一倍，且西方文明长期居世界领先水平,把穆罕默德排在耶稣之后看起来更正确!【沙特阿拉伯】'
    },
    {
        name: '孔子',
        honor: '儒家创始人',
        description: '孔子与穆罕默德、耶稣和释伽牟尼一起,被称为缔造世界文化的"四圣".',
        cover: images.cultural_head_img.i4,
        direction: 'right',
        qrcode_description:'孔 子孔子是世界十大历史名人之一,出生于公元前551年的鲁国(中国山东省),是儒家创始人。孔子与穆罕默德、耶稣和释伽牟尼一起,被称为缔造世界文化的"四圣".孔子常常被誉为是一种宗教的创始人，但是这种说法失为确切。孔子是中国最伟大的哲学家，他概括了中国人的基本思想，成为独创一套信仰体系的鼻祖。他的哲学根基于人生道德和用道德典范来教育人、管理人的政治观念，贯穿于中国人的生活和文化之中长达两千年之久，对世界上许多人都产生过很大的影响。虽然现代物质文明和共产主义对传统道德和儒家带来冲击,但他作为东方文明首屈一指的代表人物是不容致疑的!【中国】'
    },
    {
        name: '释迦牟尼',
        honor: '佛教的创始人',
        description: '悉达多是卡皮拉瓦斯图的一位国王之子，如今皮拉瓦斯图是一座位于尼泊尔边界附近的印度东北部城市。',
        cover: images.cultural_head_img.i5,
        direction: 'left',
        qrcode_description:'释迦牟尼 乔达摩·释迦牟尼，原名悉达多王子，是世界上主要的宗教之一 ──佛教的创始人。悉达多是卡皮拉瓦斯图的一位国王之子，如今皮拉瓦斯图是一座位于尼泊尔边界附近的印度东北部城市。公元前三世纪，伟大的印度国王改信佛教。世界上大约仅有两亿佛教徒，却有五亿多伊斯兰教徒和约十亿基督教徒，所以显而易见释迦牟尼比穆罕默德或耶稣影响的人数要少些，在中国许多人虽然不自称是佛教徒，但是他们却深深受着佛教哲学的影响。佛教给人的感觉是被动和安静的,因而他对历史的影响较小,但对人们的内心世界影响依然巨大!【印度】'
    },
    {
        name: '达尔文',
        honor: '自然选择生物进化论的创立者',
        description: '达尔文对人类思想的影响是巨大的,对世界宗教产生巨大的冲击,当然从纯科学的角度来看，达尔文使整个生物学科发生了革命。',
        cover: images.cultural_head_img.i6,
        direction: 'right',
        qrcode_description:'达尔文人也是动物!自然选择生物进化论的创立者查理·达尔文于1809年出生在英国什鲁斯伯里。承认人类是猴子进化来的,也就是否认了人类不是上帝创造的,那么上帝是否存在也是一个大疑问了,人们的世界观和人生观从此发生了变化。达尔文对人类思想的影响是巨大的,对世界宗教产生巨大的冲击,当然从纯科学的角度来看，达尔文使整个生物学科发生了革命。自然选择确实是一项非常广泛的原理，人们试图把它应用到许多别的领域中去，如人类学，社会学，政治学和经济学。【英国】'
    },
    {
        name: '爱因斯坦',
        honor: '科学家',
        description: '爱因斯坦以其狭义和广义相对论而最为世人所知',
        cover: images.cultural_head_img.i7,
        direction: 'left',
        qrcode_description:'爱因斯坦阿尔伯特·爱因斯坦是二十世纪最伟大的科学家，永远属于智慧超群行列中的天才，爱因斯坦以其狭义和广义相对论而最为世人所知。但是即使对爱因斯坦相对论做详细的解释也极难理解，因此创立这样的学说比创立牛顿学说要难多少倍啊！爱因斯坦在科学思想上的贡献,在历史上也许只有牛顿和达尔文可以媲美。今天,相对论已成为原子能科学、宇宙航行和天文学的理论基础。当然切须记住许多其他人也对发展原子弹发挥了重要的作用，但是爱因斯坦为之做出的重大贡献是不言而喻的。【德国/美国】'
    },
    {
        name: '马克思',
        honor: '“科学社会主义”的主要创始人',
        description: '马克思著作是共产主义的理论基础,且他的理论也对资本主义产生不可低估的影响。',
        cover: images.cultural_head_img.i8,
        direction: 'right',
        qrcode_description:'马克思 “科学社会主义”的主要创始人卡尔·马克思 1818年诞生在德国的特里尔镇。马克思著作是共产主义的理论基础,且他的理论也对资本主义产生不可低估的影响。从共产主义运动在二十世纪以空前的规模迅速发展来看，马克思显然应在本册居于高位，问题是他应高挂第几位呢？但一个哲学家的重要性并不在于他的观点是否正确，而在于他的思想驱使人们付诸于行动。基于这种认识，马克思无疑是位非常重要的人物。【德国】'
    },
    {
        name: '秦始皇',
        honor: '秦始皇是中国第一个皇帝',
        description: '他在位期间（公元前238～前210），用武力统一了六国，并实行了一系列影响广泛的改革。',
        cover: images.cultural_head_img.i9,
        direction: 'left',
        qrcode_description:'秦始皇秦始皇是中国第一个皇帝,也是一位伟大的皇帝。他在位期间（公元前238～前210），用武力统一了六国，并实行了一系列影响广泛的改革。随后中国之所以一直保持着文化统一，这些改革是一个主要因素。现在从秦始皇对中国和世界的关键作用从整体来看就一目了然了,西方人对庞大的中国一直怀有恐惧心理，两者之间的差别在于欧洲总被分裂成许多小国，而中国则是一个统一的大国。现存的万里长城和兵马俑,反映出当时的文明程度。在古代世界所有的帝王中秦始皇是位列第一位的。【中国】'
    },
    {
        name: '柏拉图',
        honor: '古希腊哲学家',
        description: '是全部西方哲学乃至整个西方文化最伟大的哲学家和思想家之一，他和老师苏格拉底，学生亚里士多德并称为古希腊三大哲学家。',
        cover: images.cultural_head_img.i10,
        direction: 'right',
        qrcode_description:'柏拉图古希腊哲学家，也是全部西方哲学乃至整个西方文化最伟大的哲学家和思想家之一，他和老师苏格拉底，学生亚里士多德并称为古希腊三大哲学家。柏拉图于公元前427年出生在雅典一个贵族家庭,他的哲学思想已被阅读和研究两千三百多年之久。柏拉图写了36部书，其中大多数是有关政治和道德问题的，也有关于神学和玄学的。估价柏拉图的重要性难就难在他对世世代代的影响虽然广泛而深入，但是却微妙而间接。【古希腊】'
    },
]