"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.b2Island = void 0;
// DEBUG: import { b2Assert } from "../common/b2_settings.js";
var b2_settings_js_1 = require("../common/b2_settings.js");
var b2_settings_js_2 = require("../common/b2_settings.js");
var b2_settings_js_3 = require("../common/b2_settings.js");
var b2_settings_js_4 = require("../common/b2_settings.js");
var b2_math_js_1 = require("../common/b2_math.js");
var b2_timer_js_1 = require("../common/b2_timer.js");
var b2_contact_solver_js_1 = require("./b2_contact_solver.js");
var b2_body_js_1 = require("./b2_body.js");
var b2_time_step_js_1 = require("./b2_time_step.js");
var b2_world_callbacks_js_1 = require("./b2_world_callbacks.js");
var b2Island = exports.b2Island = /** @class */ (function () {
    function b2Island() {
        this.m_bodies = [ /*1024*/]; // TODO: b2Settings
        this.m_contacts = [ /*1024*/]; // TODO: b2Settings
        this.m_joints = [ /*1024*/]; // TODO: b2Settings
        this.m_positions = b2_time_step_js_1.b2Position.MakeArray(1024); // TODO: b2Settings
        this.m_velocities = b2_time_step_js_1.b2Velocity.MakeArray(1024); // TODO: b2Settings
        this.m_bodyCount = 0;
        this.m_jointCount = 0;
        this.m_contactCount = 0;
        this.m_bodyCapacity = 0;
        this.m_contactCapacity = 0;
        this.m_jointCapacity = 0;
    }
    b2Island.prototype.Initialize = function (bodyCapacity, contactCapacity, jointCapacity, listener) {
        this.m_bodyCapacity = bodyCapacity;
        this.m_contactCapacity = contactCapacity;
        this.m_jointCapacity = jointCapacity;
        this.m_bodyCount = 0;
        this.m_contactCount = 0;
        this.m_jointCount = 0;
        this.m_listener = listener;
        // TODO:
        // while (this.m_bodies.length < bodyCapacity) {
        //   this.m_bodies[this.m_bodies.length] = null;
        // }
        // TODO:
        // while (this.m_contacts.length < contactCapacity) {
        //   this.m_contacts[this.m_contacts.length] = null;
        // }
        // TODO:
        // while (this.m_joints.length < jointCapacity) {
        //   this.m_joints[this.m_joints.length] = null;
        // }
        // TODO:
        if (this.m_positions.length < bodyCapacity) {
            var new_length = (0, b2_math_js_1.b2Max)(this.m_positions.length * 2, bodyCapacity);
            while (this.m_positions.length < new_length) {
                this.m_positions[this.m_positions.length] = new b2_time_step_js_1.b2Position();
            }
        }
        // TODO:
        if (this.m_velocities.length < bodyCapacity) {
            var new_length = (0, b2_math_js_1.b2Max)(this.m_velocities.length * 2, bodyCapacity);
            while (this.m_velocities.length < new_length) {
                this.m_velocities[this.m_velocities.length] = new b2_time_step_js_1.b2Velocity();
            }
        }
    };
    b2Island.prototype.Clear = function () {
        this.m_bodyCount = 0;
        this.m_contactCount = 0;
        this.m_jointCount = 0;
    };
    b2Island.prototype.AddBody = function (body) {
        // DEBUG: b2Assert(this.m_bodyCount < this.m_bodyCapacity);
        body.m_islandIndex = this.m_bodyCount;
        this.m_bodies[this.m_bodyCount++] = body;
    };
    b2Island.prototype.AddContact = function (contact) {
        // DEBUG: b2Assert(this.m_contactCount < this.m_contactCapacity);
        this.m_contacts[this.m_contactCount++] = contact;
    };
    b2Island.prototype.AddJoint = function (joint) {
        // DEBUG: b2Assert(this.m_jointCount < this.m_jointCapacity);
        this.m_joints[this.m_jointCount++] = joint;
    };
    b2Island.prototype.Solve = function (profile, step, gravity, allowSleep) {
        var timer = b2Island.s_timer.Reset();
        var h = step.dt;
        // Integrate velocities and apply damping. Initialize the body state.
        for (var i = 0; i < this.m_bodyCount; ++i) {
            var b = this.m_bodies[i];
            // const c: b2Vec2 =
            this.m_positions[i].c.Copy(b.m_sweep.c);
            var a = b.m_sweep.a;
            var v = this.m_velocities[i].v.Copy(b.m_linearVelocity);
            var w = b.m_angularVelocity;
            // Store positions for continuous collision.
            b.m_sweep.c0.Copy(b.m_sweep.c);
            b.m_sweep.a0 = b.m_sweep.a;
            if (b.m_type === b2_body_js_1.b2BodyType.b2_dynamicBody) {
                // Integrate velocities.
                // v += h * b->m_invMass * (b->m_gravityScale * b->m_mass * gravity + b->m_force);
                v.x += h * b.m_invMass * (b.m_gravityScale * b.m_mass * gravity.x + b.m_force.x);
                v.y += h * b.m_invMass * (b.m_gravityScale * b.m_mass * gravity.y + b.m_force.y);
                w += h * b.m_invI * b.m_torque;
                // Apply damping.
                // ODE: dv/dt + c * v = 0
                // Solution: v(t) = v0 * exp(-c * t)
                // Time step: v(t + dt) = v0 * exp(-c * (t + dt)) = v0 * exp(-c * t) * exp(-c * dt) = v * exp(-c * dt)
                // v2 = exp(-c * dt) * v1
                // Pade approximation:
                // v2 = v1 * 1 / (1 + c * dt)
                v.SelfMul(1.0 / (1.0 + h * b.m_linearDamping));
                w *= 1.0 / (1.0 + h * b.m_angularDamping);
            }
            // this.m_positions[i].c = c;
            this.m_positions[i].a = a;
            // this.m_velocities[i].v = v;
            this.m_velocities[i].w = w;
        }
        timer.Reset();
        // Solver data
        var solverData = b2Island.s_solverData;
        solverData.step.Copy(step);
        solverData.positions = this.m_positions;
        solverData.velocities = this.m_velocities;
        // Initialize velocity constraints.
        var contactSolverDef = b2Island.s_contactSolverDef;
        contactSolverDef.step.Copy(step);
        contactSolverDef.contacts = this.m_contacts;
        contactSolverDef.count = this.m_contactCount;
        contactSolverDef.positions = this.m_positions;
        contactSolverDef.velocities = this.m_velocities;
        var contactSolver = b2Island.s_contactSolver.Initialize(contactSolverDef);
        contactSolver.InitializeVelocityConstraints();
        if (step.warmStarting) {
            contactSolver.WarmStart();
        }
        for (var i = 0; i < this.m_jointCount; ++i) {
            this.m_joints[i].InitVelocityConstraints(solverData);
        }
        profile.solveInit = timer.GetMilliseconds();
        // 当鼠标选中物体 拖动时计算移动速度
        timer.Reset();
        for (var i = 0; i < step.velocityIterations; ++i) {
            for (var j = 0; j < this.m_jointCount; ++j) {
                // console.log(this.m_joints[j]);
                this.m_joints[j].SolveVelocityConstraints(solverData);
            }
            contactSolver.SolveVelocityConstraints();
        }
        // Store impulses for warm starting
        contactSolver.StoreImpulses();
        profile.solveVelocity = timer.GetMilliseconds();
        // Integrate positions.
        for (var i = 0; i < this.m_bodyCount; ++i) {
            var c = this.m_positions[i].c;
            var a = this.m_positions[i].a;
            var v = this.m_velocities[i].v;
            var w = this.m_velocities[i].w;
            // Check for large velocities
            var translation = b2_math_js_1.b2Vec2.MulSV(h, v, b2Island.s_translation);
            if (b2_math_js_1.b2Vec2.DotVV(translation, translation) > b2_settings_js_2.b2_maxTranslationSquared) {
                var ratio = b2_settings_js_2.b2_maxTranslation / translation.Length();
                v.SelfMul(ratio);
            }
            var rotation = h * w;
            if (rotation * rotation > b2_settings_js_3.b2_maxRotationSquared) {
                var ratio = b2_settings_js_3.b2_maxRotation / (0, b2_math_js_1.b2Abs)(rotation);
                w *= ratio;
            }
            // Integrate
            c.x += h * v.x;
            c.y += h * v.y;
            a += h * w;
            // this.m_positions[i].c = c;
            this.m_positions[i].a = a;
            // this.m_velocities[i].v = v;
            this.m_velocities[i].w = w;
        }
        // Solve position constraints
        timer.Reset();
        var positionSolved = false;
        for (var i = 0; i < step.positionIterations; ++i) {
            var contactsOkay = contactSolver.SolvePositionConstraints();
            var jointsOkay = true;
            for (var j = 0; j < this.m_jointCount; ++j) {
                var jointOkay = this.m_joints[j].SolvePositionConstraints(solverData);
                jointsOkay = jointsOkay && jointOkay;
            }
            if (contactsOkay && jointsOkay) {
                // Exit early if the position errors are small.
                positionSolved = true;
                break;
            }
        }
        // Copy state buffers back to the bodies
        for (var i = 0; i < this.m_bodyCount; ++i) {
            var body = this.m_bodies[i];
            body.m_sweep.c.Copy(this.m_positions[i].c);
            body.m_sweep.a = this.m_positions[i].a;
            body.m_linearVelocity.Copy(this.m_velocities[i].v);
            body.m_angularVelocity = this.m_velocities[i].w;
            body.SynchronizeTransform();
        }
        profile.solvePosition = timer.GetMilliseconds();
        this.Report(contactSolver.m_velocityConstraints);
        if (allowSleep) {
            var minSleepTime = b2_settings_js_1.b2_maxFloat;
            var linTolSqr = b2_settings_js_4.b2_linearSleepTolerance * b2_settings_js_4.b2_linearSleepTolerance;
            var angTolSqr = b2_settings_js_4.b2_angularSleepTolerance * b2_settings_js_4.b2_angularSleepTolerance;
            for (var i = 0; i < this.m_bodyCount; ++i) {
                var b = this.m_bodies[i];
                if (b.GetType() === b2_body_js_1.b2BodyType.b2_staticBody) {
                    continue;
                }
                if (!b.m_autoSleepFlag ||
                    b.m_angularVelocity * b.m_angularVelocity > angTolSqr ||
                    b2_math_js_1.b2Vec2.DotVV(b.m_linearVelocity, b.m_linearVelocity) > linTolSqr) {
                    b.m_sleepTime = 0;
                    minSleepTime = 0;
                }
                else {
                    b.m_sleepTime += h;
                    minSleepTime = (0, b2_math_js_1.b2Min)(minSleepTime, b.m_sleepTime);
                }
            }
            if (minSleepTime >= b2_settings_js_1.b2_timeToSleep && positionSolved) {
                for (var i = 0; i < this.m_bodyCount; ++i) {
                    var b = this.m_bodies[i];
                    b.SetAwake(false);
                }
            }
        }
    };
    b2Island.prototype.SolveTOI = function (subStep, toiIndexA, toiIndexB) {
        // DEBUG: b2Assert(toiIndexA < this.m_bodyCount);
        // DEBUG: b2Assert(toiIndexB < this.m_bodyCount);
        // Initialize the body state.
        for (var i = 0; i < this.m_bodyCount; ++i) {
            var b = this.m_bodies[i];
            this.m_positions[i].c.Copy(b.m_sweep.c);
            this.m_positions[i].a = b.m_sweep.a;
            this.m_velocities[i].v.Copy(b.m_linearVelocity);
            this.m_velocities[i].w = b.m_angularVelocity;
        }
        var contactSolverDef = b2Island.s_contactSolverDef;
        contactSolverDef.contacts = this.m_contacts;
        contactSolverDef.count = this.m_contactCount;
        contactSolverDef.step.Copy(subStep);
        contactSolverDef.positions = this.m_positions;
        contactSolverDef.velocities = this.m_velocities;
        var contactSolver = b2Island.s_contactSolver.Initialize(contactSolverDef);
        // Solve position constraints.
        for (var i = 0; i < subStep.positionIterations; ++i) {
            var contactsOkay = contactSolver.SolveTOIPositionConstraints(toiIndexA, toiIndexB);
            if (contactsOkay) {
                break;
            }
        }
        /*
        #if 0
          // Is the new position really safe?
          for (int32 i = 0; i < this.m_contactCount; ++i) {
            b2Contact* c = this.m_contacts[i];
            b2Fixture* fA = c.GetFixtureA();
            b2Fixture* fB = c.GetFixtureB();
      
            b2Body* bA = fA.GetBody();
            b2Body* bB = fB.GetBody();
      
            int32 indexA = c.GetChildIndexA();
            int32 indexB = c.GetChildIndexB();
      
            b2DistanceInput input;
            input.proxyA.Set(fA.GetShape(), indexA);
            input.proxyB.Set(fB.GetShape(), indexB);
            input.transformA = bA.GetTransform();
            input.transformB = bB.GetTransform();
            input.useRadii = false;
      
            b2DistanceOutput output;
            b2SimplexCache cache;
            cache.count = 0;
            b2Distance(&output, &cache, &input);
      
            if (output.distance === 0 || cache.count === 3) {
              cache.count += 0;
            }
          }
        #endif
        */
        // Leap of faith to new safe state.
        this.m_bodies[toiIndexA].m_sweep.c0.Copy(this.m_positions[toiIndexA].c);
        this.m_bodies[toiIndexA].m_sweep.a0 = this.m_positions[toiIndexA].a;
        this.m_bodies[toiIndexB].m_sweep.c0.Copy(this.m_positions[toiIndexB].c);
        this.m_bodies[toiIndexB].m_sweep.a0 = this.m_positions[toiIndexB].a;
        // No warm starting is needed for TOI events because warm
        // starting impulses were applied in the discrete solver.
        contactSolver.InitializeVelocityConstraints();
        // Solve velocity constraints.
        for (var i = 0; i < subStep.velocityIterations; ++i) {
            contactSolver.SolveVelocityConstraints();
        }
        // Don't store the TOI contact forces for warm starting
        // because they can be quite large.
        var h = subStep.dt;
        // Integrate positions
        for (var i = 0; i < this.m_bodyCount; ++i) {
            var c = this.m_positions[i].c;
            var a = this.m_positions[i].a;
            var v = this.m_velocities[i].v;
            var w = this.m_velocities[i].w;
            // Check for large velocities
            var translation = b2_math_js_1.b2Vec2.MulSV(h, v, b2Island.s_translation);
            if (b2_math_js_1.b2Vec2.DotVV(translation, translation) > b2_settings_js_2.b2_maxTranslationSquared) {
                var ratio = b2_settings_js_2.b2_maxTranslation / translation.Length();
                v.SelfMul(ratio);
            }
            var rotation = h * w;
            if (rotation * rotation > b2_settings_js_3.b2_maxRotationSquared) {
                var ratio = b2_settings_js_3.b2_maxRotation / (0, b2_math_js_1.b2Abs)(rotation);
                w *= ratio;
            }
            // Integrate
            c.SelfMulAdd(h, v);
            a += h * w;
            // this.m_positions[i].c = c;
            this.m_positions[i].a = a;
            // this.m_velocities[i].v = v;
            this.m_velocities[i].w = w;
            // Sync bodies
            var body = this.m_bodies[i];
            body.m_sweep.c.Copy(c);
            body.m_sweep.a = a;
            body.m_linearVelocity.Copy(v);
            body.m_angularVelocity = w;
            body.SynchronizeTransform();
        }
        this.Report(contactSolver.m_velocityConstraints);
    };
    b2Island.prototype.Report = function (constraints) {
        if (this.m_listener === null) {
            return;
        }
        for (var i = 0; i < this.m_contactCount; ++i) {
            var c = this.m_contacts[i];
            if (!c) {
                continue;
            }
            var vc = constraints[i];
            var impulse = b2Island.s_impulse;
            impulse.count = vc.pointCount;
            for (var j = 0; j < vc.pointCount; ++j) {
                impulse.normalImpulses[j] = vc.points[j].normalImpulse;
                impulse.tangentImpulses[j] = vc.points[j].tangentImpulse;
            }
            this.m_listener.PostSolve(c, impulse);
        }
    };
    b2Island.s_timer = new b2_timer_js_1.b2Timer();
    b2Island.s_solverData = new b2_time_step_js_1.b2SolverData();
    b2Island.s_contactSolverDef = new b2_contact_solver_js_1.b2ContactSolverDef();
    b2Island.s_contactSolver = new b2_contact_solver_js_1.b2ContactSolver();
    b2Island.s_translation = new b2_math_js_1.b2Vec2();
    b2Island.s_impulse = new b2_world_callbacks_js_1.b2ContactImpulse();
    return b2Island;
}());
