import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom'
import WaterfallFlow from './pages/waterfall/waterfall_flow';
import Power from './pages/waterfall/power';
import CardScreen from './pages/ebooks/card-screen/card-screen';
import DetailsScreen from './pages/ebooks/details-screen/details-screen';
import Player from './pages/ebooks/player-screen/player-screen';
// import Bubble from './pages/bubble';
import BubbleBox2d from './pages/bubble-box2d/main';
import DetailsPhoneScreen from './pages/ebooks/details-phone-screen/details-phone-screen';
import Cultural from './pages/waterfall/cultural';
import Music from './pages/waterfall/music';
function App() {
  return <HashRouter>
    <Routes>
      <Route
        path='/'
        element={<CardScreen />}
        key={`c-screen`}
      />
      <Route
        path='/Details'
        element={<DetailsScreen />}
        key={`d-screen`}
      />
      <Route
        path='/DetailsPhone'
        element={<DetailsPhoneScreen />}
        key={`d-phone-screen`}
      />
      <Route
        path='/Player'
        element={<Player />}
        key={`p-screen`}
      />

      <Route
        path="/bubble"
        element={
        <BubbleBox2d />
      }
      />
      <Route
        path="/waterfall_exhibition"
        element={<WaterfallFlow />}
      />
      <Route
        path="/waterfall"
        element={<Power />}
      />
      <Route
        path="/cultural"
        element={<Cultural />}
      />
      <Route
        path="/music"
        element={<Music />}
      />
    </Routes>
  </HashRouter>;
}

export default App;
