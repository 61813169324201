import { images } from '../../assets'
import QRCode from 'qrcode.react';
import './index.scss';
export default function CardInfo({
    data,
    onClose
}) {
    const front = String(data?.summary).substring(0, 63);
    const after = String(data?.summary).substring(63, String(data?.summary).length);
    return <div className="card-info-container">
        <div className='mark' onClick={onClose} />
        <div className='model'>
            <div className='info-frame' style={{ backgroundImage: `url(${images.book_info})` }}>
                <div className='tips'>
                    手机扫一扫看书
                </div>
                <div className='title'>
                    {data?.title}
                </div>
                <div className='author'>
                    {data?.author}
                </div>
                <div className='code'>
                    <QRCode
                        id={new Date().getTime()}
                        value={`https://mini.shijizhitu.com/wx_mini_search/?title=${data?.title}&libraryCode=${`6b12eb390b45e380471bfedd`}`}
                        size={90 / 290 * window.innerWidth}
                        fgColor="#333333"
                    />
                </div>
                <div id={`summary-${new Date().getTime()}`} className='summary'>
                    {front}
                </div>
                <div className='summary-after'>
                    {after}
                </div>
            </div>
            <div className='close' onClick={onClose}>
                点击任意区域回到主页面
            </div>
        </div>
    </div>
}