import { useState } from 'react';
import { useEffect } from 'react';
import './book-info.css';
const BookInfo = ({
    bookType,
    dataSource,
    onClose = () => { }
}) => {
    const [resource, setResource] = useState();
    const QRCode = require('qrcode');
    useEffect(() => {
        var opts = {
            errorCorrectionLevel: 'M',
            type: 'image/png',
            quality: 0.5,
            margin: 1,
            color: {
                dark: "#000000",
                light: "#FFFFFF"
            }
        }
        QRCode.toDataURL(`https://mini.shijizhitu.com/wx_mini_search/?title=${dataSource?.title}&libraryCode=auto`, opts, function (err, url) {
            if (err) throw err
            const QR_DOM = document.getElementById('qrmains');
            if (QR_DOM) {
                QR_DOM.src = url
            }
        })
    }, [dataSource])
    return <div className='bookinfo-main'>
        <div className='mark' onClick={onClose} />
        <div className='box'>
            <div className='info-main'>
                <img src={dataSource?.cover} className='cover' />
                <div className='info-box'>
                    <div className='title'>
                        {dataSource?.title}
                    </div>
                    <div className='author'>
                        {dataSource?.author}
                    </div>
                    <div className='summary'>
                        {dataSource?.summary}
                    </div>
                </div>
                <div className='qrcode-main'>
                    <img id='qrmains' />
                    <div className='bookinfo-tips'>
                        电子资源
                    </div>
                </div>
            </div>
            <div className='start-button'>
                <div className='btns' style={{
                    background: sessionStorage.getItem('DEVICES') === 'devices' && 'rgb(0,47,167)'
                }} onClick={() => {
                    window.location.href = dataSource?.url;
                    window.ReactNativeWebView.postMessage(JSON.stringify({ close: 'false' }))
                }}>
                    开始{bookType === 1 ? '阅读' : '听书'}
                </div>
            </div>
        </div>
    </div>
}
export default BookInfo