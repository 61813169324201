import { useEffect } from 'react';
import './index.scss';
export default function InfoToast({
    data,
    onClose = () => { }
}) {
    const QRCode = require('qrcode');
    useEffect(() => {
        var opts = {
            errorCorrectionLevel: 'M',
            type: 'image/png',
            quality: 0.5,
            margin: 1,
            color: {
                dark: "#000000",
                light: "#FFFFFF"
            }
        }
        QRCode.toDataURL(`https://mini.shijizhitu.com/wx_mini_search/?title=${data?.name}&libraryCode=auto`, opts, function (err, url) {
            if (err) throw err
            const QR_DOM = document.getElementById('cultural-qrcode');
            if (QR_DOM) {
                QR_DOM.src = url
            }
        })
    }, [data])
    return <div className="info-toast-container" onClick={onClose}>
        <div className='mark' />
        <div className='info-toast-main'>
            <div className='info-toast-box'>
                <div className='box-tips'>
                    手机扫一扫看书
                </div>
                <div className='box-title'>
                    {data.name}
                </div>
                <div className='box-honor'>
                    {data.honor}
                </div>
                <img className='box-qrcode' id='cultural-qrcode' />
                <div className='box-description'>
                    {data.qrcode_description}
                </div>
            </div>
        </div>
        <div className='toast-close-btn'>
            点击任意区域回到主页面
        </div>
    </div>
}