import special_1_160 from './special_1_160.png';
import special_2_160 from './special_2_160.png';
import special_3_160 from './special_3_160.png';
import special_4_160 from './special_4_160.png';
import special_5_160 from './special_5_160.png';
import special_6_160 from './special_6_160.png';
import special_7_160 from './special_7_160.png';
import special_8_160 from './special_8_160.png';
const open_status = [
    {
        160: special_1_160,
    }, {
        160: special_2_160,
    },{
        160: special_3_160,
    },{
        160: special_4_160,
    },{
        160: special_5_160,
    },{
        160: special_6_160,
    },{
        160: special_7_160,
    },{
        160: special_8_160,
    }
]
export default open_status