import QRCode from 'qrcode.react';
import { useState } from 'react';
import { useEffect } from 'react';
import './index.css'
let book_time = null;
let tmp_count = 180;
const BookInfo = ({
    dataSource,
    onClose = () => { }
}) => {
    const [count, setCount] = useState(180);
    useEffect(() => {
        clearInterval(book_time);
        tmp_count = 180;
        book_time = setInterval(() => {
            tmp_count = tmp_count - 1;
            setCount(tmp_count);
            if (tmp_count === 0) {
                clearInterval(book_time);
                onClose();
            }
        }, 1000)
        return () => {
            clearInterval(book_time)
            setCount(180);
            book_time = 180;
        }
    }, [dataSource])
    return dataSource && <div className='bookinfo-container'>
        <div className='reciprocal' onClick={onClose}>
            {count} S
        </div>
        <div className='mark' onClick={onClose} />
        <div className='bookinfo-card'>
            <div className='header-tips'>
                微信扫一扫看书
            </div>
            <div className='book-title'>
                {dataSource?.title}
            </div>
            <div className='book-author'>
                {dataSource?.author}
            </div>
            <div className='book-qrcode'>
                <QRCode
                    value={`https://mini.shijizhitu.com/wx_mini_search/?title=${dataSource?.title}&libraryCode=${'6b12eb390b45e380471bfedd'}`}
                    size={window.innerWidth * (160 / 616)}
                    fgColor="#000000"
                />
            </div>
            <div className='book-summary'>
                {dataSource?.summary}
            </div>
        </div>
        <div className='tips'>
            点击任意区域回到主页面
        </div>
    </div>
}
export default BookInfo