"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bubble = void 0;
var b2 = require("../b2");
var box2d_1 = require("../b2/box2d");
var event_1 = require("../event");
var Bubble = /** @class */ (function (_super) {
    __extends(Bubble, _super);
    function Bubble() {
        var _this = _super.call(this) || this;
        _this.WIDTH = window.innerWidth;
        _this.HEIGHT = window.innerHeight;
        _this.blank_count = 0;
        //todo 创建墙体
        _this.CreateWalls();
        //todo 创建泡泡
        _this.CreateBubble();
        return _this;
    }
    /** 创建墙体 */
    Bubble.prototype.CreateWalls = function () {
        {
            var bd = new b2.BodyDef();
            var ground = this.m_world.CreateBody(bd);
            //todo 底部
            // {
            //     const shape = new b2.PolygonShape();
            //     const vertices = [
            //         new b2.Vec2(-(this.WIDTH / 2), -(this.HEIGHT / 2)),
            //         new b2.Vec2(this.WIDTH / 2, -(this.HEIGHT / 2) + 1),
            //         new b2.Vec2(this.WIDTH / 2, -(this.HEIGHT / 2)),
            //         new b2.Vec2(-(this.WIDTH / 2), -(this.HEIGHT / 2)),
            //     ];
            //     shape.Set(vertices, 4);
            //     ground.CreateFixture(shape, 0.0);
            // }
            //todo 左侧
            {
                var shape = new b2.PolygonShape();
                var vertices = [
                    new b2.Vec2(-(this.WIDTH / 2) + 5, this.HEIGHT),
                    new b2.Vec2(-(this.WIDTH / 2) + 5, -(this.HEIGHT) + 1),
                    new b2.Vec2(-(this.WIDTH / 2) + 5, this.HEIGHT),
                    new b2.Vec2(-(this.WIDTH / 2) + 5, -(this.HEIGHT)),
                ];
                shape.Set(vertices, 4);
                ground.CreateFixture(shape, 0.0);
            }
            //todo 右侧
            {
                var shape = new b2.PolygonShape();
                var vertices = [
                    new b2.Vec2(this.WIDTH / 2 - 5, this.HEIGHT),
                    new b2.Vec2(this.WIDTH / 2 - 5, -(this.HEIGHT) + 1),
                    new b2.Vec2(this.WIDTH / 2 - 5, this.HEIGHT),
                    new b2.Vec2(this.WIDTH / 2 - 5, -(this.HEIGHT)),
                ];
                shape.Set(vertices, 4);
                ground.CreateFixture(shape, 0.0);
            }
            //todo 顶部
            // {
            //     const shape = new b2.PolygonShape();
            //     const vertices = [
            //         new b2.Vec2(-(this.WIDTH / 2), this.HEIGHT / 2),
            //         new b2.Vec2(this.WIDTH / 2, (this.HEIGHT / 2) + 1),
            //         new b2.Vec2(this.WIDTH / 2, this.HEIGHT / 2),
            //         new b2.Vec2(-(this.WIDTH / 2), this.HEIGHT / 2),
            //     ];
            //     shape.Set(vertices, 4);
            //     ground.CreateFixture(shape, 0.0);
            // }
        }
    };
    /** 创建泡泡 */
    Bubble.prototype.CreateBubble = function () {
        var _this = this;
        {
            var _loop_1 = function (i) {
                var that = this_1;
                var create = function () {
                    var r = (0, event_1.RandomFloat)(40, 100);
                    var x = (0, event_1.RandomFloat)(-(that.WIDTH / 2), that.WIDTH / 2);
                    var y = (0, event_1.RandomFloat)(-(that.HEIGHT / 2), that.HEIGHT / 2);
                    var bd = new b2.BodyDef();
                    bd.type = b2.dynamicBody;
                    bd.id = i + 1;
                    bd.current = i + 1;
                    bd.gravityScale = r < 50 ? -100 : r < 60 ? -80 : r < 70 ? -60 : -0.2;
                    bd.position.Set(x, y);
                    bd.allowSleep = false;
                    bd.r = r;
                    if (_this.blank_count < 4) {
                        bd.blank = true;
                        _this.blank_count += 1;
                    }
                    var fixDef = new box2d_1.b2FixtureDef();
                    var bubbleShape = new b2.CircleShape();
                    bubbleShape.m_radius = r;
                    fixDef.shape = bubbleShape;
                    fixDef.density = 0.000001;
                    fixDef.friction = 0;
                    fixDef.restitution = 100;
                    that.m_world.CreateBody(bd).CreateFixture(fixDef);
                };
                create();
            };
            var this_1 = this;
            for (var i = 0; i < this.count; i++) {
                _loop_1(i);
            }
        }
    };
    return Bubble;
}(event_1.Test));
exports.Bubble = Bubble;
