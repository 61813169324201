import { useEffect } from "react";
import { StyleShell, toWLR } from "../../utils/styles"
import './tabs.css';

const Tabs = ({
    dataSource,
    switchTab,
    curIndex
}) => {
    function setScrollect(e, index, id, length,class_id) {
        switchTab(index, class_id);
        /** 选中的元素 */
        const THIS_ELEMENT = document.getElementById(id);
        /** 当前元素的位置 */
        const THIS_ELEMENT_LEFT = THIS_ELEMENT.offsetLeft;
        /** 当前元素的宽 */
        const THIS_ELEMENT_WIDTH = THIS_ELEMENT.offsetWidth;
        /** tab元素的LEFT */
        const TAB_ELEMENT_LEFT = (number) => document.getElementById('cardTaber').scrollTo(number, 0);
        /** 第一个元素和第二个元素不执行 */
        if (length - 1 === index || length - 2 === index) return;
        TAB_ELEMENT_LEFT(THIS_ELEMENT_LEFT + THIS_ELEMENT_WIDTH / 2 - window.innerWidth / 2);

    }
    function refreshPage(e, index, length) {
        /** 选中的元素 */
        const THIS_ELEMENT = document.getElementById('SJZT_TAB_CHECKS');
        /** 当前元素的位置 */
        const THIS_ELEMENT_LEFT = THIS_ELEMENT.offsetLeft;
        /** 当前元素的宽 */
        const THIS_ELEMENT_WIDTH = THIS_ELEMENT.offsetWidth;
        /** tab元素的LEFT */
        const TAB_ELEMENT_LEFT = (number) => document.getElementById('cardTaber').scrollTo(number, 0);
        /** 第一个元素和第二个元素不执行 */
        if (length - 1 === index || length - 2 === index) return;
        TAB_ELEMENT_LEFT(THIS_ELEMENT_LEFT + THIS_ELEMENT_WIDTH / 2 - window.innerWidth / 2);
    }
    function IdClick() {
        if (document.getElementById('SJZT_TAB_CHECKS')) {
            document.getElementById('SJZT_TAB_CHECKS').click();
            return;
        } else {
            setTimeout(() => {
                return IdClick();
            }, 100)
        }
    }
    useEffect(() => {
        IdClick();
    }, [])
    return <div style={styles.tabMain} id='tabMain'>
        <div style={styles.cardTabMain} id='cardTaber'>
            {
                dataSource?.map((item, index) => {
                    if (index === curIndex) {
                        return (
                            <div key={`tabs-${index}`} style={styles.cardTabItemActive} id='SJZT_TAB_CHECKS' onClick={(e) => refreshPage(e, index, dataSource?.length)}>
                                <div style={styles.cardTabItemActiveText}>
                                    {item.name}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={`tabs-${index}`} style={styles.cardTabMainItem} id={`tabs-id-${item.id}`} onClick={(e) => setScrollect(e, index, `tabs-id-${item.id}`, dataSource.length, item.id)}>
                                <div style={styles.cardTabItemText}>
                                    {item.name}
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    </div>
}
const styles = StyleShell({
    tabMain: {
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: '100',
        height: 140,
        paddingTop: 30,
        backgroundColor: '#ffffff'
    },
    cardTabMain: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        height: 'fit-content',
        paddingRight: 40,
        scrollBehavior: 'smooth',
        transition: '1s'
    },
    cardTabMainItem: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: 20,
        backgroundColor: '#F9F9F9',
        color: 'black',
        fontSize: 44,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 32,
        paddingRight: 32,
        marginRight: 20,
        marginLeft: 60,
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none'
    },
    cardTabItemActive: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: 20,
        backgroundColor: '#699DDF',
        color: '#FBFBFB',
        fontSize: 44,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 32,
        paddingRight: 32,
        marginRight: 20,
        marginLeft: 60,
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none'
    },
    cardTabItemActiveText: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center'
    },
    cardTabItemText: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center'
    }
})
export default Tabs