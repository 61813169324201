// import { images } from '../../../assets'
import { Waterfall } from '../../../components'
import './index.scss'
export default function WaterfallFlow() {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    return <div
        className='waterfall_flow_container'
        style={{ backgroundImage: `url(./bg_${params.get('bg') || 1}.png)` }}
    >
        {/* <div
            className='cloud'
            style={{ backgroundImage: `url(${images.waterfall_bg_2_cloud})` }}
        /> */}
        <div className='content'>
            <Waterfall />
        </div>
    </div>
}