export function getParam({ url, title }) {
    if (title) {
        let urls = String(url);
        let params = urls.slice(urls.indexOf('?'), urls.length);
        var reg = new RegExp("(^|&)" + title + "=([^&]*)(&|$)");
        var r = params.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    } else {
        let urls = String(url);
        urls = urls.slice(urls.lastIndexOf('/') + 1, urls.length);
        return urls.slice(0, urls.length);
    }
}