import special_1_100 from './special_1_100.png';
import special_2_100 from './special_2_100.png';
import special_3_100 from './special_3_100.png';
import special_4_100 from './special_4_100.png';
import special_5_100 from './special_5_100.png';
import special_6_100 from './special_6_100.png';
import special_1_90 from './special_1_90.png';
import special_2_90 from './special_2_90.png';
import special_3_90 from './special_3_90.png';
import special_4_90 from './special_4_90.png';
import special_5_90 from './special_5_90.png';
import special_6_90 from './special_6_90.png';
import special_1_110 from './special_1_110.png';
import special_2_110 from './special_2_110.png';
import special_3_110 from './special_3_110.png';
import special_4_110 from './special_4_110.png';
import special_5_110 from './special_5_110.png';
import special_6_110 from './special_6_110.png';
import special_1_140 from './special_1_140.png';
import special_2_140 from './special_2_140.png';
import special_3_140 from './special_3_140.png';
import special_4_140 from './special_4_140.png';
import special_5_140 from './special_5_140.png';
import special_6_140 from './special_6_140.png';
const close_status = [
    {
        140: special_1_140,
        110: special_1_110,
        100: special_1_100,
        90: special_1_90
    }, {
        140: special_2_140,
        110: special_2_110,
        100: special_2_100,
        90: special_2_90
    }, {
        140: special_3_140,
        110: special_3_110,
        100: special_3_100,
        90: special_3_90
    }, {
        140: special_4_140,
        110: special_4_110,
        100: special_4_100,
        90: special_4_90
    }, {
        140: special_5_140,
        110: special_5_110,
        100: special_5_100,
        90: special_5_90
    }, {
        140: special_6_140,
        110: special_6_110,
        100: special_6_100,
        90: special_6_90
    }
]
export default close_status