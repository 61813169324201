import React, { useMemo, useRef, useState } from 'react';
import images from '../../../assets/images';
import { LightDisk } from './components';
import { useEffect } from 'react';
import { getMusicList } from '../../../api/books';
import './index.scss';

let timer = null,
    speed = 25,
    tmp_top = 0,
    tmp_data = [];

function ItemRow({
    dataSource,
    onChange
}) {
    return <div className='layout-contaienr'>
        {/* <div className='layout-content'>
            <LightDisk
                key={`light-disk-1`}
                title={dataSource?.[0]?.title}
                cover={dataSource?.[0]?.cover}
                onClick={() => onChange(dataSource?.[0])}
            />
            <LightDisk
                key={`light-disk-2`}
                title={dataSource?.[1]?.title}
                cover={dataSource?.[1]?.cover}
                onClick={() => onChange(dataSource?.[1])}
            />
        </div> */}
        <div
            style={{
                backgroundImage: `url(${images.layout_bottom})`
            }}
            className='layout-bottom'
        />
    </div>
}

export default function Music() {
    const con = useRef();
    const [dataSource, setDataSource] = useState([]);
    async function _getMusicList({
        id,
        page,
        pageSize
    }) {
        try {
            const data = await getMusicList({
                id,
                page,
                pageSize
            });
            setDataSource([...dataSource, ...data.data.bookList]);
        } catch (err) {
            console.error(err);
        }
    }
    function onScroll() {
        if (con.current.scrollTop >= con.current.scrollHeight * 0.35) {
            setDataSource([...dataSource, ...dataSource]);
        }
    }
    function init() {
        clearInterval(timer);
        timer = setInterval(() => {
            tmp_top = con.current.scrollTop;
            tmp_top += 1;
            con.current.scrollTop = tmp_top;
        }, speed);
    }
    function onTouchStart() {
        clearInterval(timer);
    }
    function onTouchEnd() {
        clearInterval(timer);
        init();
    }
    function onChecked() {

    }
    useEffect(() => {
        clearInterval(timer);
        _getMusicList({
            id: 93,
            page: 1,
            pageSize: 20
        });
        init();
        return () => {
            clearInterval(timer);
        }
    }, [])
    return <div
        className='music-container'
        style={{
            backgroundImage: `url(${images.music_bg})`
        }}
    >
        <div
            style={{
                backgroundImage: `url(${images.music_header})`
            }}
            className='music-header'
        />
        {useMemo(() => {
            return <div
                className='content'
                ref={con}
                onScroll={onScroll}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                <div style={{
                    display: 'inline-block'
                }}>
                    {[...dataSource, ...dataSource].map((item, index) => {
                        // if (tmp_data.length === 1) {
                        //     if (item?.cover && item?.title) {
                        //         tmp_data.push(item);
                        //         return <ItemRow
                        //             key={`item-row-${index}`}
                        //             dataSource={tmp_data}
                        //             onChange={onChecked}
                        //         />
                        //     } else {
                        //         return <React.Fragment
                        //             key={`item-row-${index}`}
                        //         />
                        //     }
                        // } else {
                        //     tmp_data = [];
                        //     if (item?.cover && item?.title) {
                        //         tmp_data.push(item);
                        //     }
                        //     return <React.Fragment
                        //         key={`item-row-${index}`}
                        //     />
                        // }

                        return <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                height: 'fit-content',
                                marginTop: `46px`,
                                top: `${index % 2 !== 0 ? 46 * 2 : 46}px`,
                                left: `${index % 2 !== 0 ? `calc(100% - 320px)` : ``}`
                            }}
                            key={`light-disk-${index}`}
                        >
                            <LightDisk
                                key={`light-disk-2`}
                                title={item?.title}
                                cover={item?.cover}
                            // size={tempSize}
                            />
                        </div>
                    })}
                </div>
                <div className='music-limit' />
            </div>
        }, [dataSource, con])}
        <div
            style={{
                backgroundImage: `url(${images.music_bottom})`
            }}
            className='music-bottom'
        />
    </div>
}