import images from '../../../assets/images';
import { InfoToast, LeftCard, RightCard } from './components';
import { DATASOURCE } from './contact/data';
import './index.scss';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
let count = -1,
    timer = null,
    speed = 21,
    tmp_top = 0;
export default function Cultural() {
    const con = useRef();
    const [data, setData] = useState(DATASOURCE);
    const [checked, setChecked] = useState();
    const [showInfoToast, setShowInfoToast] = useState(false);
    function onScroll() {
        if (con.current.scrollTop >= con.current.scrollHeight * 0.4) {
            setData([...data, ...data]);
        }
    }
    function init() {
        clearInterval(timer);
        timer = setInterval(() => {
            tmp_top = tmp_top === 0 ? con.current.scrollHeight : con.current.scrollTop;
            tmp_top -= 1;
            con.current.scrollTop = tmp_top;
        }, speed);
    }
    function onTouchStart() {
        clearInterval(timer);
    }
    function onTouchEnd() {
        clearInterval(timer);
        init();
    }
    function onChecked(data) {
        clearInterval(timer);
        setChecked(data);
        setShowInfoToast(true);
    }
    useEffect(() => {
        clearInterval(timer);
        init();
        return () => {
            clearInterval(timer);
        }
    }, [])
    return <div className='cultural-container' style={{
        backgroundImage: `url(${images.cultural_bg})`
    }}>
        <div className='cultural-title'>
            人类历史上最具影响力的100人
        </div>
        {useMemo(() => {
            return <div
                className='cultural-content'
                ref={con}
                onScroll={onScroll}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                {data.map((item, index) => {
                    if (index % 2 === 0) {
                        count += 1;
                    }
                    return item.direction === 'left' ?
                        <LeftCard
                            data={item}
                            key={`cultural-card-${index}`}
                            style={index > 1 ? { top: `-${230 * count}px` } : {}}
                            renders={true}
                            onClick={() => onChecked(item)}
                        /> : <RightCard
                            data={item}
                            key={`cultural-card-${index}`}
                            style={index > 1 ? { top: `-${230 * count}px` } : {}}
                            renders={true}
                            onClick={() => onChecked(item)}
                        />
                })}
            </div>
        }, [data])}
        <div
            className='cultural-footer'
            style={{
                backgroundImage: `url(${images.cultural_footer})`
            }}
        />
        {showInfoToast && (
            <InfoToast
                data={checked}
                onClose={() => {
                    setShowInfoToast(false);
                    setChecked(false);
                    init();
                }}
            />
        )}
    </div>
}