import axios from 'axios'
import { api_pro } from './config'

const service = axios.create({
    baseURL: ''
})

//响应拦截
service.interceptors.response.use((resp) => {
    if (resp.status === 200) {

        return resp.data
    } else {
        //全局处理错误
        console.log(resp.errMsg)
    }
    return resp.data
})

export default service