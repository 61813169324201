import Marquee from 'react-fast-marquee';
import { Black } from '../../assets';
import './navigation.css';
const Navigation = ({
    onBlack = () => { },
    black = false,
    title,
    style,
    className,
    bottom = true,
    marquee = false
}) => {
    return <div className='navigation-main' id="navigation" style={!black ? { justifyContent: 'center' } : {}}>
        {black && (
            <div className='back-btn' onClick={onBlack}>
                <img src={Black} className='back-icon' />
                <div className='back-label'>
                    返回
                </div>
            </div>
        )}
        <div className='marquee-main' style={!black ? { marginLeft: 0 } : {}} >
            {
                marquee ? (
                    <Marquee
                        gradientColor={'rgba(0,0,0,0)'}
                        style={{ zIndex: 0 }}
                    >
                        <div className={`${className} nav-main-title-text`} style={style}>
                            {title}&nbsp;&nbsp;&nbsp;
                        </div>
                    </Marquee>
                ) : (
                    <div className={`${className} nav-main-title-text`} style={style}>
                        {title}
                    </div>
                )}
        </div >
        {
            bottom ? (
                <div className='nav-main-bottom' ></div>
            ) : <div />}
    </div >
}
export default Navigation