"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Test = exports.ContactPoint = exports.DestructionListener = exports.RegisterTest = exports.g_testEntries = exports.TestEntry = exports.RandomFloat = exports.DRAW_STRING_NEW_LINE = void 0;
var b2 = require("./b2");
var draw_js_1 = require("./draw.js");
var screen_ui_1 = require("./screen_ui");
var particle_parameter_1 = require("./particle_parameter");
var draw_dom_1 = require("./draw_dom");
var box2d_1 = require("./b2/box2d");
exports.DRAW_STRING_NEW_LINE = 16;
function RandomFloat(lo, hi) {
    if (lo === void 0) { lo = -1; }
    if (hi === void 0) { hi = 1; }
    var r = Math.random();
    r = (hi - lo) * r + lo;
    return r;
}
exports.RandomFloat = RandomFloat;
var TestEntry = /** @class */ (function () {
    function TestEntry(category, name, createFcn) {
        this.category = "";
        this.name = "unknown";
        this.category = category;
        this.name = name;
        this.createFcn = createFcn;
    }
    return TestEntry;
}());
exports.TestEntry = TestEntry;
exports.g_testEntries = [];
function RegisterTest(category, name, fcn) {
    return exports.g_testEntries.push(new TestEntry(category, name, fcn));
}
exports.RegisterTest = RegisterTest;
var DestructionListener = /** @class */ (function (_super) {
    __extends(DestructionListener, _super);
    function DestructionListener(test) {
        var _this = _super.call(this) || this;
        _this.test = test;
        return _this;
    }
    DestructionListener.prototype.SayGoodbyeJoint = function (joint) {
        if (this.test.m_mouseJoint === joint) {
            this.test.m_mouseJoint = null;
        }
        else {
            this.test.JointDestroyed(joint);
        }
    };
    DestructionListener.prototype.SayGoodbyeFixture = function (fixture) { };
    // #if B2_ENABLE_PARTICLE
    DestructionListener.prototype.SayGoodbyeParticleGroup = function (group) {
        this.test.ParticleGroupDestroyed(group);
    };
    return DestructionListener;
}(b2.DestructionListener));
exports.DestructionListener = DestructionListener;
var ContactPoint = /** @class */ (function () {
    function ContactPoint() {
        this.normal = new b2.Vec2();
        this.position = new b2.Vec2();
        this.state = b2.PointState.b2_nullState;
        this.normalImpulse = 0;
        this.tangentImpulse = 0;
        this.separation = 0;
    }
    return ContactPoint;
}());
exports.ContactPoint = ContactPoint;
// #if B2_ENABLE_PARTICLE
var QueryCallback2 = /** @class */ (function (_super) {
    __extends(QueryCallback2, _super);
    function QueryCallback2(particleSystem, shape, velocity) {
        var _this = _super.call(this) || this;
        _this.m_particleSystem = particleSystem;
        _this.m_shape = shape;
        _this.m_velocity = velocity;
        return _this;
    }
    QueryCallback2.prototype.ReportFixture = function (fixture) {
        return false;
    };
    QueryCallback2.prototype.ReportParticle = function (particleSystem, index) {
        if (particleSystem !== this.m_particleSystem) {
            return false;
        }
        var xf = b2.Transform.IDENTITY;
        var p = this.m_particleSystem.GetPositionBuffer()[index];
        if (this.m_shape.TestPoint(xf, p)) {
            var v = this.m_particleSystem.GetVelocityBuffer()[index];
            v.Copy(this.m_velocity);
        }
        return true;
    };
    return QueryCallback2;
}(b2.QueryCallback));
// #endif
var Test = exports.Test = /** @class */ (function (_super) {
    __extends(Test, _super);
    function Test() {
        var _this = _super.call(this) || this;
        // #endif
        _this.m_bomb = null;
        _this.m_textLine = 30;
        _this.m_mouseJoint = null;
        _this.m_points = b2.MakeArray(Test.k_maxContactPoints, function (i) { return new ContactPoint(); });
        _this.m_pointCount = 0;
        _this.m_bombSpawnPoint = new b2.Vec2();
        _this.m_bombSpawning = false;
        _this.m_mouseWorld = new b2.Vec2();
        // #if B2_ENABLE_PARTICLE
        _this.m_mouseTracing = false;
        _this.m_mouseTracerPosition = new b2.Vec2();
        _this.m_mouseTracerVelocity = new b2.Vec2();
        // #endif
        _this.m_stepCount = 0;
        _this.m_maxProfile = new b2.Profile();
        _this.m_totalProfile = new b2.Profile();
        // #if B2_ENABLE_PARTICLE
        _this.m_particleParameters = null;
        _this.m_particleParameterDef = null;
        // #endif
        /** 当前选中的泡泡
         ** 当属性不为空时 泡泡放大 封面展示二维码
         ** 当属性为空时 封面展示书籍封面 泡泡缩小
         **
         */
        _this.m_check_id = null;
        /** 泡泡数据 */
        _this.m_data = [];
        /** 当前数据页数 */
        _this.page = 1;
        /** 数据总页数 */
        _this.totalPage = null;
        /** 泡泡数量 */
        _this.count = 40;
        /** 泡泡数据下标 */
        _this.current = _this.count;
        /** 当前是否正在请求数据 */
        _this.requestStatus = false;
        /** 当前的QrCode */
        _this.qrCode = null;
        /** 展示二维码时间 */
        _this.qrTimer = null;
        /** 网络状态 */
        _this.netState = true;
        // #if B2_ENABLE_PARTICLE
        var particleSystemDef = new b2.ParticleSystemDef();
        // #endif
        var gravity = new b2.Vec2(0, -10);
        _this.m_world = new b2.World(gravity);
        // #if B2_ENABLE_PARTICLE
        _this.m_particleSystem = _this.m_world.CreateParticleSystem(particleSystemDef);
        // #endif
        _this.m_bomb = null;
        _this.m_textLine = 30;
        _this.m_mouseJoint = null;
        _this.m_destructionListener = new DestructionListener(_this);
        _this.m_world.SetDestructionListener(_this.m_destructionListener);
        _this.m_world.SetContactListener(_this);
        _this.m_world.SetDebugDraw(draw_js_1.g_debugDraw);
        // #if B2_ENABLE_PARTICLE
        _this.m_particleSystem.SetGravityScale(0.4);
        _this.m_particleSystem.SetDensity(1.2);
        // #endif
        var bodyDef = new b2.BodyDef();
        _this.m_groundBody = _this.m_world.CreateBody(bodyDef);
        var that = _this;
        window.addEventListener('online', function () {
            console.log('设备上线！');
            that.netState = true;
        });
        window.addEventListener('offline', function () {
            console.log('设备离线！');
            that.netState = false;
        });
        return _this;
    }
    Test.prototype.JointDestroyed = function (joint) { };
    // #if B2_ENABLE_PARTICLE
    Test.prototype.ParticleGroupDestroyed = function (group) { };
    // #endif
    Test.prototype.BeginContact = function (contact) { };
    Test.prototype.EndContact = function (contact) { };
    /** 获取数据 */
    Test.prototype.getData = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var type, realType, url, data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.requestStatus)
                            return [2 /*return*/];
                        this.requestStatus = true;
                        type = [1, 2];
                        realType = type[Math.floor(Math.random() * type.length)];
                        url = "https://nest.shijizhitu.com/aggregation/booklist/".concat(realType === 1 ? 483 : 564, "?page=").concat((this.totalPage && (page < this.totalPage)) ? page : 1, "&pageSize=").concat(20, "&bookType=").concat(realType);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch(url, { method: 'GET' })];
                    case 2:
                        data = _a.sent();
                        return [4 /*yield*/, data.json()];
                    case 3:
                        data = _a.sent();
                        if (data.code === 200) {
                            data = data.data;
                            this.page = data.page.page;
                            this.totalPage = data.page.totalPage;
                            this.m_data = __spreadArray(__spreadArray([], this.m_data, true), data.bookList, true);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 5];
                    case 5:
                        this.requestStatus = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    /** 设置泡泡半径 */
    Test.prototype.setRadius = function (body, radius) {
        __spreadArray([], new Array(Math.ceil(radius)), true).map(function (_, index) {
            body.m_fixtureList.m_body.m_fixtureList.m_body.m_next.m_prev.m_fixtureList.m_shape.m_radius = index + 1;
        });
    };
    /** 通过ID获取泡泡半径 */
    Test.prototype.getRadius = function (id) {
        var _a;
        var bubbles = this.getBodyList();
        for (var i in bubbles) {
            if (bubbles[i].id === id) {
                return (_a = bubbles[i].m_fixtureList) === null || _a === void 0 ? void 0 : _a.m_shape.m_radius;
            }
        }
    };
    /** 获取世界中的全部泡泡 */
    Test.prototype.getBodyList = function () {
        var tmpArr = [];
        for (var b = this.m_world.GetBodyList(); b; b = b.m_next) {
            if (b.GetType() === 2) {
                tmpArr.push(b);
            }
        }
        return tmpArr;
    };
    /** 通过ID获取泡泡 */
    Test.prototype.getBody = function (id) {
        var bubbles = this.getBodyList();
        for (var i in bubbles) {
            if (bubbles[i].id === id) {
                return bubbles[i];
            }
        }
    };
    /** 检查数组中是否有重复数据 */
    Test.prototype.hasDuplicate = function (arr) {
        return new Set(arr).size !== arr.length;
    };
    Test.prototype.isJSON = function (str) {
        if (typeof str == 'string') {
            try {
                var obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (e) {
                return false;
            }
        }
    };
    Test.prototype.PreSolve = function (contact, oldManifold) {
        var manifold = contact.GetManifold();
        if (manifold.pointCount === 0) {
            return;
        }
        var fixtureA = contact.GetFixtureA();
        var fixtureB = contact.GetFixtureB();
        var state1 = Test.PreSolve_s_state1;
        var state2 = Test.PreSolve_s_state2;
        b2.GetPointStates(state1, state2, oldManifold, manifold);
        var worldManifold = Test.PreSolve_s_worldManifold;
        contact.GetWorldManifold(worldManifold);
        for (var i = 0; i < manifold.pointCount && this.m_pointCount < Test.k_maxContactPoints; ++i) {
            var cp = this.m_points[this.m_pointCount];
            cp.fixtureA = fixtureA;
            cp.fixtureB = fixtureB;
            cp.position.Copy(worldManifold.points[i]);
            cp.normal.Copy(worldManifold.normal);
            cp.state = state2[i];
            cp.normalImpulse = manifold.points[i].normalImpulse;
            cp.tangentImpulse = manifold.points[i].tangentImpulse;
            cp.separation = worldManifold.separations[i];
            ++this.m_pointCount;
        }
    };
    Test.prototype.PostSolve = function (contact, impulse) { };
    Test.prototype.Keyboard = function (key) { };
    Test.prototype.KeyboardUp = function (key) { };
    Test.prototype.SetTextLine = function (line) {
        this.m_textLine = line;
    };
    Test.prototype.DrawTitle = function (title) {
        draw_js_1.g_debugDraw.DrawString(5, exports.DRAW_STRING_NEW_LINE, title);
        this.m_textLine = 3 * exports.DRAW_STRING_NEW_LINE;
    };
    Test.prototype.resetQrCode = function (type, id) {
        var _this = this;
        if (!this.netState) {
            console.warn("\u8BBE\u5907\u8131\u673A\uFF0C\u65E0\u6CD5\u91CD\u7F6E\u5C0F\u7A0B\u5E8F\u7801\uFF01");
            return new Promise(function (success) { return success(null); });
        }
        return new Promise(function (success) {
            var url = "https://mini.shijizhitu.com/api/v2/mobile/unlimitedQRCode/reset";
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.send("scene=".concat(type, ",").concat(id, "&page=otherPages/pages/book/index&check_path=true&width=430"));
            xhr.onreadystatechange = function () {
                if (_this.isJSON(xhr.responseText)) {
                    var data = JSON.parse(xhr.responseText);
                    if (data.statusCode === 200) {
                        _this.getQrCode(type, id).then(success);
                    }
                }
            };
        });
    };
    Test.prototype.getQrCode = function (type, id) {
        var _this = this;
        if (!this.netState) {
            console.warn("\u8BBE\u5907\u8131\u673A\uFF0C\u65E0\u6CD5\u521B\u5EFA\u5C0F\u7A0B\u5E8F\u7801\uFF01");
            return new Promise(function (success) { return success(null); });
        }
        return new Promise(function (success) {
            var url = "https://mini.shijizhitu.com/api/v2/mobile/unlimitedQRCode";
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.send("scene=".concat(type, ",").concat(id, "&page=otherPages/pages/book/index&width=430"));
            xhr.onreadystatechange = function () {
                if (_this.isJSON(xhr.responseText)) {
                    var data_1 = JSON.parse(xhr.responseText);
                    if (xhr.status === 200 && data_1.statusCode === 200) {
                        var image = new Image();
                        image.src = data_1.data.imgUrl;
                        image.onload = function () {
                            success(data_1.data.imgUrl);
                            var cover_dom = document.getElementById("cover-".concat(_this.m_check_id));
                            if (cover_dom) {
                                cover_dom.style.backgroundImage = "url(".concat(data_1.data.imgUrl, ")");
                                cover_dom.style.backgroundSize = "80% 80%";
                            }
                        };
                    }
                    else if (data_1.statusCode === 40001) {
                        // this.getQrCode(type, id).then(success);
                    }
                    else if (xhr.status === 404) {
                        _this.resetQrCode(type, id).then(success);
                    }
                }
            };
        });
    };
    Test.prototype.MouseDown = function (p) {
        var _this = this;
        this.m_mouseWorld.Copy(p);
        // #if B2_ENABLE_PARTICLE
        this.m_mouseTracing = true;
        this.m_mouseTracerPosition.Copy(p);
        this.m_mouseTracerVelocity.SetZero();
        // #endif
        if (this.m_mouseJoint !== null) {
            this.m_world.DestroyJoint(this.m_mouseJoint);
            this.m_mouseJoint = null;
        }
        var hit_fixture = null; // HACK: tsc doesn't detect calling callbacks
        // 查询世界中的重叠形状。
        this.m_world.QueryPointAABB(p, function (fixture) {
            var body = fixture.GetBody();
            if (body.GetType() === b2.BodyType.b2_dynamicBody) {
                var inside = fixture.TestPoint(p);
                if (inside) {
                    hit_fixture = fixture;
                    return false; // We are done, terminate the query.
                }
            }
            return true; // Continue the query.
        });
        //todo 静态泡泡点击事件
        for (var body = this.m_world.GetBodyList(); body; body = body.GetNext()) {
            if (!body.IsEnabled()) {
                var fixture = body.GetFixtureList();
                if (fixture) {
                    if (fixture.TestPoint(new box2d_1.b2Vec2(p.x, p.y))) {
                        body.SetEnabled(true);
                        this.m_check_id = null;
                        this.setRadius(body, body.r);
                        break;
                    }
                }
            }
        }
        // console.log(p);
        if (hit_fixture) {
            var frequencyHz = 5.0;
            var dampingRatio = 0.7;
            var body_1 = hit_fixture.GetBody();
            var jd = new b2.MouseJointDef();
            jd.bodyA = this.m_groundBody;
            jd.bodyB = body_1;
            jd.target.Copy(p);
            jd.maxForce = 1000 * body_1.GetMass();
            b2.LinearStiffness(jd, frequencyHz, dampingRatio, jd.bodyA, jd.bodyB);
            var eventBubble = function () {
                var _a, _b, _c, _d, _e, _f;
                clearTimeout(_this.qrTimer);
                if (_this.m_check_id === body_1.id) {
                    _this.setRadius(body_1, body_1.r);
                    _this.m_check_id = null;
                }
                else {
                    if (_this.m_check_id) {
                        var bubble = _this.getBody(_this.m_check_id);
                        bubble === null || bubble === void 0 ? void 0 : bubble.SetEnabled(true);
                        _this.setRadius(bubble, body_1.r);
                    }
                    var r = RandomFloat(150, 180);
                    //todo 显示二维码
                    var id = ((_b = (_a = _this.m_data[body_1.current]) === null || _a === void 0 ? void 0 : _a.resourceIDs) === null || _b === void 0 ? void 0 : _b.read) || ((_d = (_c = _this.m_data[body_1.current]) === null || _c === void 0 ? void 0 : _c.resourceIDs) === null || _d === void 0 ? void 0 : _d.audio);
                    var type = ((_f = (_e = _this.m_data[body_1.current]) === null || _e === void 0 ? void 0 : _e.resourceIDs) === null || _f === void 0 ? void 0 : _f.read) ? 'r' : 'a';
                    _this.getQrCode(type, id).then(function (src) {
                        if (src) {
                            body_1.qrCode = src;
                        }
                    });
                    body_1.SetEnabled(false);
                    _this.setRadius(body_1, r);
                    _this.m_check_id = body_1.id;
                    _this.qrTimer = setTimeout(function () {
                        if (_this.m_check_id) {
                            var bubble = _this.getBody(_this.m_check_id);
                            bubble === null || bubble === void 0 ? void 0 : bubble.SetEnabled(true);
                            _this.setRadius(bubble, body_1.r);
                            _this.m_check_id = null;
                        }
                    }, 10000);
                }
            };
            if (body_1.blank) {
                body_1.blank = false;
            }
            else {
                eventBubble();
            }
            this.m_mouseJoint = this.m_world.CreateJoint(jd);
            body_1.SetAwake(false);
        }
    };
    /** 绘制全部泡泡DOM */
    Test.prototype.drawBubbleDOM = function () {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var bubbles = this.getBodyList();
        if ((this.count > this.m_data.length + 30) || (this.m_data.length - 10 < this.current)) {
            this.getData(this.page + 1);
        }
        for (var i in bubbles) {
            var x = (_a = bubbles[i].m_sweep.c, _a.x), y = _a.y;
            var r = this.getRadius(bubbles[i].id) || 0;
            //todo 设置当物体超出屏幕底部时 从0开始
            if (window.innerHeight - ((window.innerHeight / 2) + y) - r < -(r * 2)) {
                if (this.m_check_id === bubbles[i].id) {
                    this.setRadius(bubbles[i], bubbles[i].r);
                    this.m_check_id = null;
                }
                if (Math.random() > 0.8) {
                    if (this.current < this.m_data.length) {
                        if (this.netState) {
                            this.current += 1;
                            bubbles[i].current = this.current;
                        }
                        else {
                            console.warn("\u5F53\u524D\u8BBE\u5907\u8131\u673A\uFF0C\u65E0\u6CD5\u521B\u5EFA\u66F4\u591A\u6CE1\u6CE1\uFF01");
                        }
                    }
                }
                //2023-03-27展会修改 因：空白泡泡出现太多，原0.8
                var random = Math.random();
                if (random) {
                    if (random > 0.9) {
                        bubbles[i].blank = true;
                    }
                    else {
                        bubbles[i].blank = false;
                    }
                }
                else {
                    bubbles[i].blank = false;
                }
                bubbles[i].SetPositionXY(RandomFloat((window.innerWidth / 2) - r, -(window.innerWidth / 2) + r), (-window.innerHeight / 2 - r * 4) /*(window.innerHeight / 2) + (r * 2)*/);
            }
            //todo left
            if ((window.innerWidth / 2) + x - r < 0) {
                bubbles[i].SetPositionXY(-(window.innerWidth) / 2 + 5 + r, y);
            }
            //todo right
            if ((window.innerWidth / 2) + x + r > window.innerWidth) {
                bubbles[i].SetPositionXY((window.innerWidth) / 2 - 5 - r, y);
            }
            if (bubbles[i].id === this.m_check_id) {
                //todo top
                if ((window.innerHeight / 2) + y + r > window.innerHeight) {
                    bubbles[i].SetPositionXY(x, (window.innerHeight) / 2 - 5 - r);
                }
                //todo bottom
                if (y + r + (r / 2) < -(window.innerWidth)) {
                    bubbles[i].SetPositionXY(x, -(window.innerWidth) - r - (r / 2));
                }
            }
            new draw_dom_1.drawDOM({
                id: bubbles[i].id,
                x: (window.innerWidth / 2) + x - r,
                y: window.innerHeight - ((window.innerHeight / 2) + y) - r,
                r: r,
                cover: (_b = this.m_data[bubbles[i].current]) === null || _b === void 0 ? void 0 : _b.cover,
                check_id: this.m_check_id,
                data: ((_d = (_c = this.m_data[bubbles[i].current]) === null || _c === void 0 ? void 0 : _c.resourceIDs) === null || _d === void 0 ? void 0 : _d.read) || ((_f = (_e = this.m_data[bubbles[i].current]) === null || _e === void 0 ? void 0 : _e.resourceIDs) === null || _f === void 0 ? void 0 : _f.audio),
                title: (_g = this.m_data[bubbles[i].current]) === null || _g === void 0 ? void 0 : _g.title,
                summary: (_h = this.m_data[bubbles[i].current]) === null || _h === void 0 ? void 0 : _h.summary,
                blank: bubbles[i].blank || false,
                type: ((_k = (_j = this.m_data[bubbles[i].current]) === null || _j === void 0 ? void 0 : _j.resourceIDs) === null || _k === void 0 ? void 0 : _k.read) ? 'r' : 'a',
                qrCode: bubbles[i].qrCode
            });
        }
    };
    Test.prototype.SpawnBomb = function (worldPt) {
        this.m_bombSpawnPoint.Copy(worldPt);
        this.m_bombSpawning = true;
    };
    Test.prototype.CompleteBombSpawn = function (p) {
        if (!this.m_bombSpawning) {
            return;
        }
        var multiplier = 30;
        var vel = b2.Vec2.SubVV(this.m_bombSpawnPoint, p, new b2.Vec2());
        vel.SelfMul(multiplier);
        this.LaunchBombAt(this.m_bombSpawnPoint, vel);
        this.m_bombSpawning = false;
    };
    Test.prototype.ShiftMouseDown = function (p) {
        this.m_mouseWorld.Copy(p);
        if (this.m_mouseJoint !== null) {
            return;
        }
        this.SpawnBomb(p);
    };
    Test.prototype.MouseUp = function (p) {
        // #if B2_ENABLE_PARTICLE
        this.m_mouseTracing = false;
        // #endif
        if (this.m_mouseJoint) {
            this.m_world.DestroyJoint(this.m_mouseJoint);
            this.m_mouseJoint = null;
        }
        if (this.m_bombSpawning) {
            this.CompleteBombSpawn(p);
        }
    };
    Test.prototype.MouseMove = function (p) {
        this.m_mouseWorld.Copy(p);
        if (this.m_mouseJoint) {
            this.m_mouseJoint.SetTarget(p);
        }
    };
    Test.prototype.LaunchBomb = function () {
        var p = new b2.Vec2(b2.RandomRange(-15, 15), 30);
        var v = b2.Vec2.MulSV(-5, p, new b2.Vec2());
        this.LaunchBombAt(p, v);
    };
    Test.prototype.LaunchBombAt = function (position, velocity) {
        if (this.m_bomb) {
            this.m_world.DestroyBody(this.m_bomb);
            this.m_bomb = null;
        }
        var bd = new b2.BodyDef();
        bd.type = b2.BodyType.b2_dynamicBody;
        bd.position.Copy(position);
        bd.bullet = true;
        this.m_bomb = this.m_world.CreateBody(bd);
        this.m_bomb.SetLinearVelocity(velocity);
        var circle = new b2.CircleShape();
        circle.m_radius = 0.3;
        var fd = new b2.FixtureDef();
        fd.shape = circle;
        fd.density = 20;
        fd.restitution = 0;
        // b2.Vec2 minV = position - b2.Vec2(0.3f,0.3f);
        // b2.Vec2 maxV = position + b2.Vec2(0.3f,0.3f);
        // b2.AABB aabb;
        // aabb.lowerBound = minV;
        // aabb.upperBound = maxV;
        this.m_bomb.CreateFixture(fd);
    };
    Test.prototype.Step = function (settings) {
        var timeStep = settings.m_hertz > 0 ? 1 / settings.m_hertz : 0;
        if (settings.m_pause) {
            if (settings.m_singleStep) {
                settings.m_singleStep = false;
            }
            else {
                timeStep = 0;
            }
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "****PAUSED****");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
        }
        var flags = b2.DrawFlags.e_none;
        if (settings.m_drawShapes) {
            flags |= b2.DrawFlags.e_shapeBit;
        }
        // #if B2_ENABLE_PARTICLE
        if (settings.m_drawParticles) {
            flags |= b2.DrawFlags.e_particleBit;
        }
        // #endif
        if (settings.m_drawJoints) {
            flags |= b2.DrawFlags.e_jointBit;
        }
        if (settings.m_drawAABBs) {
            flags |= b2.DrawFlags.e_aabbBit;
        }
        if (settings.m_drawCOMs) {
            flags |= b2.DrawFlags.e_centerOfMassBit;
        }
        // #if B2_ENABLE_CONTROLLER
        if (settings.m_drawControllers) {
            flags |= b2.DrawFlags.e_controllerBit;
        }
        // #endif
        draw_js_1.g_debugDraw.SetFlags(flags);
        this.m_world.SetAllowSleeping(settings.m_enableSleep);
        this.m_world.SetWarmStarting(settings.m_enableWarmStarting);
        this.m_world.SetContinuousPhysics(settings.m_enableContinuous);
        this.m_world.SetSubStepping(settings.m_enableSubStepping);
        // #if B2_ENABLE_PARTICLE
        this.m_particleSystem.SetStrictContactCheck(settings.m_strictContacts);
        // #endif
        this.m_pointCount = 0;
        // #if B2_ENABLE_PARTICLE
        this.m_world.Step(timeStep, settings.m_velocityIterations, settings.m_positionIterations, settings.m_particleIterations);
        // #else
        // this.m_world.Step(timeStep, settings.velocityIterations, settings.positionIterations);
        // #endif
        this.m_world.DebugDraw();
        if (timeStep > 0) {
            ++this.m_stepCount;
        }
        if (settings.m_drawStats) {
            var bodyCount = this.m_world.GetBodyCount();
            var contactCount = this.m_world.GetContactCount();
            var jointCount = this.m_world.GetJointCount();
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "bodies/contacts/joints = " + bodyCount + "/" + contactCount + "/" + jointCount);
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            // #if B2_ENABLE_PARTICLE
            var particleCount = this.m_particleSystem.GetParticleCount();
            var groupCount = this.m_particleSystem.GetParticleGroupCount();
            var pairCount = this.m_particleSystem.GetPairCount();
            var triadCount = this.m_particleSystem.GetTriadCount();
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "particles/groups/pairs/triads = " + particleCount + "/" + groupCount + "/" + pairCount + "/" + triadCount);
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            // #endif
            var proxyCount = this.m_world.GetProxyCount();
            var height = this.m_world.GetTreeHeight();
            var balance = this.m_world.GetTreeBalance();
            var quality = this.m_world.GetTreeQuality();
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "proxies/height/balance/quality = " + proxyCount + "/" + height + "/" + balance + "/" + quality.toFixed(2));
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
        }
        // Track maximum profile times
        {
            var p = this.m_world.GetProfile();
            this.m_maxProfile.step = b2.Max(this.m_maxProfile.step, p.step);
            this.m_maxProfile.collide = b2.Max(this.m_maxProfile.collide, p.collide);
            this.m_maxProfile.solve = b2.Max(this.m_maxProfile.solve, p.solve);
            this.m_maxProfile.solveInit = b2.Max(this.m_maxProfile.solveInit, p.solveInit);
            this.m_maxProfile.solveVelocity = b2.Max(this.m_maxProfile.solveVelocity, p.solveVelocity);
            this.m_maxProfile.solvePosition = b2.Max(this.m_maxProfile.solvePosition, p.solvePosition);
            this.m_maxProfile.solveTOI = b2.Max(this.m_maxProfile.solveTOI, p.solveTOI);
            this.m_maxProfile.broadphase = b2.Max(this.m_maxProfile.broadphase, p.broadphase);
            this.m_totalProfile.step += p.step;
            this.m_totalProfile.collide += p.collide;
            this.m_totalProfile.solve += p.solve;
            this.m_totalProfile.solveInit += p.solveInit;
            this.m_totalProfile.solveVelocity += p.solveVelocity;
            this.m_totalProfile.solvePosition += p.solvePosition;
            this.m_totalProfile.solveTOI += p.solveTOI;
            this.m_totalProfile.broadphase += p.broadphase;
        }
        if (settings.m_drawProfile) {
            var p = this.m_world.GetProfile();
            var aveProfile = new b2.Profile();
            if (this.m_stepCount > 0) {
                var scale = 1 / this.m_stepCount;
                aveProfile.step = scale * this.m_totalProfile.step;
                aveProfile.collide = scale * this.m_totalProfile.collide;
                aveProfile.solve = scale * this.m_totalProfile.solve;
                aveProfile.solveInit = scale * this.m_totalProfile.solveInit;
                aveProfile.solveVelocity = scale * this.m_totalProfile.solveVelocity;
                aveProfile.solvePosition = scale * this.m_totalProfile.solvePosition;
                aveProfile.solveTOI = scale * this.m_totalProfile.solveTOI;
                aveProfile.broadphase = scale * this.m_totalProfile.broadphase;
            }
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "step [ave] (max) = " + p.step.toFixed(2) + " [" + aveProfile.step.toFixed(2) + "] (" + this.m_maxProfile.step.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "collide [ave] (max) = " + p.collide.toFixed(2) + " [" + aveProfile.collide.toFixed(2) + "] (" + this.m_maxProfile.collide.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "solve [ave] (max) = " + p.solve.toFixed(2) + " [" + aveProfile.solve.toFixed(2) + "] (" + this.m_maxProfile.solve.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "solve init [ave] (max) = " + p.solveInit.toFixed(2) + " [" + aveProfile.solveInit.toFixed(2) + "] (" + this.m_maxProfile.solveInit.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "solve velocity [ave] (max) = " + p.solveVelocity.toFixed(2) + " [" + aveProfile.solveVelocity.toFixed(2) + "] (" + this.m_maxProfile.solveVelocity.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "solve position [ave] (max) = " + p.solvePosition.toFixed(2) + " [" + aveProfile.solvePosition.toFixed(2) + "] (" + this.m_maxProfile.solvePosition.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "solveTOI [ave] (max) = " + p.solveTOI.toFixed(2) + " [" + aveProfile.solveTOI.toFixed(2) + "] (" + this.m_maxProfile.solveTOI.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
            draw_js_1.g_debugDraw.DrawString(5, this.m_textLine, "broad-phase [ave] (max) = " + p.broadphase.toFixed(2) + " [" + aveProfile.broadphase.toFixed(2) + "] (" + this.m_maxProfile.broadphase.toFixed(2) + ")");
            this.m_textLine += exports.DRAW_STRING_NEW_LINE;
        }
        // #if B2_ENABLE_PARTICLE
        if (this.m_mouseTracing && !this.m_mouseJoint) {
            var delay = 0.1;
            ///b2Vec2 acceleration = 2 / delay * (1 / delay * (m_mouseWorld - m_mouseTracerPosition) - m_mouseTracerVelocity);
            var acceleration = new b2.Vec2();
            acceleration.x = 2 / delay * (1 / delay * (this.m_mouseWorld.x - this.m_mouseTracerPosition.x) - this.m_mouseTracerVelocity.x);
            acceleration.y = 2 / delay * (1 / delay * (this.m_mouseWorld.y - this.m_mouseTracerPosition.y) - this.m_mouseTracerVelocity.y);
            ///m_mouseTracerVelocity += timeStep * acceleration;
            this.m_mouseTracerVelocity.SelfMulAdd(timeStep, acceleration);
            ///m_mouseTracerPosition += timeStep * m_mouseTracerVelocity;
            this.m_mouseTracerPosition.SelfMulAdd(timeStep, this.m_mouseTracerVelocity);
            var shape = new b2.CircleShape();
            shape.m_p.Copy(this.m_mouseTracerPosition);
            shape.m_radius = 2 * this.GetDefaultViewZoom();
            ///QueryCallback2 callback(m_particleSystem, &shape, m_mouseTracerVelocity);
            var callback = new QueryCallback2(this.m_particleSystem, shape, this.m_mouseTracerVelocity);
            var aabb = new b2.AABB();
            var xf = new b2.Transform();
            xf.SetIdentity();
            shape.ComputeAABB(aabb, xf, 0);
            this.m_world.QueryAABB(callback, aabb);
        }
        // #endif
        if (this.m_bombSpawning) {
            var c = new b2.Color(0, 0, 1);
            draw_js_1.g_debugDraw.DrawPoint(this.m_bombSpawnPoint, 4, c);
            c.SetRGB(0.8, 0.8, 0.8);
            draw_js_1.g_debugDraw.DrawSegment(this.m_mouseWorld, this.m_bombSpawnPoint, c);
        }
        if (settings.m_drawContactPoints) {
            var k_impulseScale = 0.1;
            var k_axisScale = 0.3;
            for (var i = 0; i < this.m_pointCount; ++i) {
                var point = this.m_points[i];
                if (point.state === b2.PointState.b2_addState) {
                    // Add
                    draw_js_1.g_debugDraw.DrawPoint(point.position, 10, new b2.Color(0.3, 0.95, 0.3));
                }
                else if (point.state === b2.PointState.b2_persistState) {
                    // Persist
                    draw_js_1.g_debugDraw.DrawPoint(point.position, 5, new b2.Color(0.3, 0.3, 0.95));
                }
                if (settings.m_drawContactNormals) {
                    var p1 = point.position;
                    var p2 = b2.Vec2.AddVV(p1, b2.Vec2.MulSV(k_axisScale, point.normal, b2.Vec2.s_t0), new b2.Vec2());
                    draw_js_1.g_debugDraw.DrawSegment(p1, p2, new b2.Color(0.9, 0.9, 0.9));
                }
                else if (settings.m_drawContactImpulse) {
                    var p1 = point.position;
                    var p2 = b2.Vec2.AddVMulSV(p1, k_impulseScale * point.normalImpulse, point.normal, new b2.Vec2());
                    draw_js_1.g_debugDraw.DrawSegment(p1, p2, new b2.Color(0.9, 0.9, 0.3));
                }
                if (settings.m_drawFrictionImpulse) {
                    var tangent = b2.Vec2.CrossVOne(point.normal, new b2.Vec2());
                    var p1 = point.position;
                    var p2 = b2.Vec2.AddVMulSV(p1, k_impulseScale * point.tangentImpulse, tangent, new b2.Vec2());
                    draw_js_1.g_debugDraw.DrawSegment(p1, p2, new b2.Color(0.9, 0.9, 0.3));
                }
            }
        }
    };
    Test.prototype.ShiftOrigin = function (newOrigin /* XY */) {
        this.m_world.ShiftOrigin(newOrigin);
    };
    Test.prototype.GetDefaultViewZoom = function () {
        return 1.0;
    };
    /**
     * Apply a preset range of colors to a particle group.
     *
     * A different color out of k_ParticleColors is applied to each
     * particlesPerColor particles in the specified group.
     *
     * If particlesPerColor is 0, the particles in the group are
     * divided into k_ParticleColorsCount equal sets of colored
     * particles.
     */
    Test.prototype.ColorParticleGroup = function (group, particlesPerColor) {
        // DEBUG: b2.Assert(group !== null);
        var colorBuffer = this.m_particleSystem.GetColorBuffer();
        var particleCount = group.GetParticleCount();
        var groupStart = group.GetBufferIndex();
        var groupEnd = particleCount + groupStart;
        var colorCount = Test.k_ParticleColors.length;
        if (!particlesPerColor) {
            particlesPerColor = Math.floor(particleCount / colorCount);
            if (!particlesPerColor) {
                particlesPerColor = 1;
            }
        }
        for (var i = groupStart; i < groupEnd; i++) {
            ///colorBuffer[i].Copy(box2d.Testbed.Test.k_ParticleColors[Math.floor(i / particlesPerColor) % colorCount]);
            colorBuffer[i] = Test.k_ParticleColors[Math.floor(i / particlesPerColor) % colorCount].Clone();
        }
    };
    /**
     * Remove particle parameters matching "filterMask" from the set
     * of particle parameters available for this test.
     */
    Test.prototype.InitializeParticleParameters = function (filterMask) {
        var defaultNumValues = particle_parameter_1.ParticleParameter.k_defaultDefinition[0].numValues;
        var defaultValues = particle_parameter_1.ParticleParameter.k_defaultDefinition[0].values;
        ///  m_particleParameters = new ParticleParameter::Value[defaultNumValues];
        this.m_particleParameters = [];
        // Disable selection of wall and barrier particle types.
        var numValues = 0;
        for (var i = 0; i < defaultNumValues; i++) {
            if (defaultValues[i].value & filterMask) {
                continue;
            }
            ///memcpy(&m_particleParameters[numValues], &defaultValues[i], sizeof(defaultValues[0]));
            this.m_particleParameters[numValues] = new particle_parameter_1.ParticleParameterValue(defaultValues[i]);
            numValues++;
        }
        this.m_particleParameterDef = new particle_parameter_1.ParticleParameterDefinition(this.m_particleParameters, numValues);
        ///m_particleParameterDef.values = m_particleParameters;
        ///m_particleParameterDef.numValues = numValues;
        Test.SetParticleParameters([this.m_particleParameterDef], 1);
    };
    /**
     * Restore default particle parameters.
     */
    Test.prototype.RestoreParticleParameters = function () {
        if (this.m_particleParameters) {
            Test.SetParticleParameters(particle_parameter_1.ParticleParameter.k_defaultDefinition, 1);
            ///  delete [] m_particleParameters;
            this.m_particleParameters = null;
        }
    };
    /**
     * Set whether to restart the test on particle parameter
     * changes. This parameter is re-enabled when the test changes.
     */
    Test.SetRestartOnParticleParameterChange = function (enable) {
        Test.particleParameter.SetRestartOnChange(enable);
    };
    /**
     * Set the currently selected particle parameter value.  This
     * value must match one of the values in
     * Main::k_particleTypes or one of the values referenced by
     * particleParameterDef passed to SetParticleParameters().
     */
    Test.SetParticleParameterValue = function (value) {
        var index = Test.particleParameter.FindIndexByValue(value);
        // If the particle type isn't found, so fallback to the first entry in the
        // parameter.
        Test.particleParameter.Set(index >= 0 ? index : 0);
        return Test.particleParameter.GetValue();
    };
    /**
     * Get the currently selected particle parameter value and
     * enable particle parameter selection arrows on Android.
     */
    Test.GetParticleParameterValue = function () {
        // Enable display of particle type selection arrows.
        Test.fullscreenUI.SetParticleParameterSelectionEnabled(true);
        return Test.particleParameter.GetValue();
    };
    /**
     * Override the default particle parameters for the test.
     */
    Test.SetParticleParameters = function (particleParameterDef, particleParameterDefCount) {
        if (particleParameterDefCount === void 0) { particleParameterDefCount = particleParameterDef.length; }
        Test.particleParameter.SetDefinition(particleParameterDef, particleParameterDefCount);
    };
    // #if B2_ENABLE_PARTICLE
    Test.fullscreenUI = new screen_ui_1.FullScreenUI();
    Test.particleParameter = new particle_parameter_1.ParticleParameter();
    // #endif
    Test.k_maxContactPoints = 2048;
    Test.PreSolve_s_state1 = [ /*b2.maxManifoldPoints*/];
    Test.PreSolve_s_state2 = [ /*b2.maxManifoldPoints*/];
    Test.PreSolve_s_worldManifold = new b2.WorldManifold();
    // #if B2_ENABLE_PARTICLE
    Test.k_ParticleColors = [
        new b2.Color().SetByteRGBA(0xff, 0x00, 0x00, 0xff),
        new b2.Color().SetByteRGBA(0x00, 0xff, 0x00, 0xff),
        new b2.Color().SetByteRGBA(0x00, 0x00, 0xff, 0xff),
        new b2.Color().SetByteRGBA(0xff, 0x8c, 0x00, 0xff),
        new b2.Color().SetByteRGBA(0x00, 0xce, 0xd1, 0xff),
        new b2.Color().SetByteRGBA(0xff, 0x00, 0xff, 0xff),
        new b2.Color().SetByteRGBA(0xff, 0xd7, 0x00, 0xff),
        new b2.Color().SetByteRGBA(0x00, 0xff, 0xff, 0xff), // cyan
    ];
    Test.k_ParticleColorsCount = Test.k_ParticleColors.length;
    return Test;
}(b2.ContactListener));
