import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { request } from '../../scripts';
import BookCard from './components/bookcard';
import BookInfo from './components/bookinfo';
import './index.scss';
let timer = null,
    tmp_count = 0,
    speed = 20,//15 ~ 19 
    searchKey = [
        '党',
        '习近平',
        '毛主席',
        '毛泽东',
        '周恩来',
        '伟大复兴',
        '江泽民',
        '孙中山',
        '李大钊',
        '朱德',
        '邓小平',
        '袁隆平'
    ],
    /** 筛选禁用书籍 */
    disableBook = [
        '拍娃党的自白书',
        '侠盗鲁平：半个羽党',
        '学生人格教育',
        '我的学术小传',
        '石牌坊',
        '西行记',
        '道源乡情—王子文集（一）'
    ],
    tmp_source = [],
    max_bottom = null,
    fetch_init = false;
const RenderBooks = ({ dataSource, onClick }) => {
    let tmpArr = [];
    return dataSource?.map((item, index) => {
        if ((index + 1) % 3 === 0) {
            tmpArr.push(item)
            return <div key={`book-card-${index}`} className={'row'}>
                {tmpArr.map((items, indexs) => {
                    if (indexs === tmpArr.length - 1) {
                        tmpArr = [];
                    }
                    return <BookCard
                        dataSource={items}
                        style={{
                            ...indexs % 2 === 0 && {
                                marginTop: `3.24vw`
                            }
                        }}
                        key={`book-card-${index}-${indexs}`}
                        onClick={onClick}
                    />
                })}
            </div>
        } else {
            tmpArr.push(item)
        }
    })
}
export default function PowerFall() {
    const scrollRef = useRef();
    const [columns, setColumns] = useState([]);
    const [checked, setChecked] = useState();
    const [current, setCurrent] = useState(0);
    function init() {
        clearInterval(timer);
        timer = setInterval(() => {
            tmp_count = scrollRef.current.el.scrollTop;
            tmp_count = tmp_count - 1;
            if (tmp_count <= 0) {
                tmp_count = scrollRef.current.el.scrollHeight;
                scrollRef.current.el.scrollTop = scrollRef.current.el.scrollHeight;
            } else {
                scrollRef.current.el.scrollTop = tmp_count;
            }
        }, speed)
    }
    function fetchMoreData() {
        if (current > searchKey.length - 1) {
            if (!fetch_init) {
                fetch_init = true;
                tmp_source = [...tmp_source, ...tmp_source];
                setColumns(tmp_source);
            } else {
                clearInterval(timer);
                max_bottom = scrollRef.current.el.scrollTop;
                init();
            }
        } else {
            getSource({ page: 1, pageSize: 20 })
        }
    }
    function onTouchStart() {
        clearInterval(timer);
    }
    function onTouchEnd() {
        clearInterval(timer);
        init();
    }
    function randomSort(a, b) { return Math.random() > 0.5 ? -1 : 1; }
    function getRespones({ page, pageSize, bookType, keyword }) {
        let tmp_data;
        request({
            url: `https://nest.shijizhitu.com/aggregation/search`,
            method: 'GET',
            params: {
                libraryCode: '667a56fe529e516c533a',
                page,
                pageSize,
                bookType,
                keyword,
            }
        }).then(data => {
            tmp_data = data;
            data = data.data;
            tmp_source = [...tmp_source, ...data.bookList];

            const res = new Map()
            tmp_source = tmp_source.filter((a) => disableBook.indexOf(a.title) === -1 && !res.has(a.title) && res.set(a.title, 1))
            tmp_source.length = tmp_source.length - tmp_source.length % 4;
            tmp_source.sort(randomSort);
            setColumns(tmp_source);
            // setParams(data.page)
        }).catch(() => {
        })
        return tmp_data;
    }
    function getSource({ page, pageSize }) {
        if (current > searchKey.length - 1) return;
        getRespones({ page, pageSize, bookType: 1, keyword: searchKey[current] })
        getRespones({ page, pageSize, bookType: 2, keyword: searchKey[current] })
        setCurrent(current + 1);
    }
    useEffect(() => {
        clearInterval(timer);
        getSource({ page: 1, pageSize: 50 });
    }, [])
    useEffect(() => {
        clearInterval(timer);
        if (!checked) {
            init();
        }
    }, [checked])
    useEffect(() => {
        if (columns.length < 20) {
            setTimeout(() => {
                getSource({ page: 1, pageSize: 50 })
            })
        }
    }, [columns, current])
    return <React.Fragment>
        <div
            className='power-fall-model'
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
        >
            <InfiniteScroll
                ref={scrollRef}
                dataLength={columns.length}
                next={fetchMoreData}
                hasMore={true}
                height={730}
                className='power-fall-container'
                style={{
                    height: "calc(100vh - 13.0625rem - 8.625rem)"
                }}

            >
                <RenderBooks dataSource={columns} onClick={setChecked} />
            </InfiniteScroll>
        </div>
        {checked && <BookInfo dataSource={checked} onClose={() => setChecked(undefined)} />}
    </React.Fragment>
}