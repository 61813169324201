"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
var b2 = require("./b2");
var Settings = /** @class */ (function () {
    function Settings() {
        this.m_testIndex = 0;
        this.m_windowWidth = window.innerWidth;
        this.m_windowHeight = window.innerHeight;
        this.m_hertz = 60;
        this.m_velocityIterations = 0;
        this.m_positionIterations = 100;
        // #if B2_ENABLE_PARTICLE
        // Particle iterations are needed for numerical stability in particle
        // simulations with small particles and relatively high gravity.
        // b2CalculateParticleIterations helps to determine the number.
        this.m_particleIterations = b2.CalculateParticleIterations(10, 0.04, 1 / this.m_hertz);
        // #endif
        this.m_drawShapes = false;
        // #if B2_ENABLE_PARTICLE
        this.m_drawParticles = false;
        // #endif
        this.m_drawJoints = false;
        this.m_drawAABBs = false;
        this.m_drawContactPoints = false;
        this.m_drawContactNormals = false;
        this.m_drawContactImpulse = false;
        this.m_drawFrictionImpulse = false;
        this.m_drawCOMs = false;
        this.m_drawControllers = false;
        this.m_drawStats = false;
        this.m_drawProfile = false;
        this.m_enableWarmStarting = false;
        this.m_enableContinuous = false;
        this.m_enableSubStepping = false;
        this.m_enableSleep = false;
        this.m_pause = false;
        this.m_singleStep = false;
        // #if B2_ENABLE_PARTICLE
        this.m_strictContacts = false;
    }
    // #endif
    Settings.prototype.Reset = function () {
        this.m_testIndex = 0;
        this.m_windowWidth = window.innerWidth;
        this.m_windowHeight = window.innerHeight;
        this.m_hertz = 60;
        this.m_velocityIterations = 8;
        this.m_positionIterations = 500;
        // #if B2_ENABLE_PARTICLE
        // Particle iterations are needed for numerical stability in particle
        // simulations with small particles and relatively high gravity.
        // b2CalculateParticleIterations helps to determine the number.
        this.m_particleIterations = b2.CalculateParticleIterations(10, 0.04, 1 / this.m_hertz);
        // #endif
        this.m_drawShapes = true;
        // #if B2_ENABLE_PARTICLE
        this.m_drawParticles = true;
        // #endif
        this.m_drawJoints = true;
        this.m_drawAABBs = false;
        this.m_drawContactPoints = false;
        this.m_drawContactNormals = false;
        this.m_drawContactImpulse = false;
        this.m_drawFrictionImpulse = false;
        this.m_drawCOMs = false;
        // #if B2_ENABLE_CONTROLLER
        this.m_drawControllers = true;
        // #endif
        this.m_drawStats = false;
        this.m_drawProfile = false;
        this.m_enableWarmStarting = true;
        this.m_enableContinuous = true;
        this.m_enableSubStepping = false;
        this.m_enableSleep = true;
        this.m_pause = false;
        this.m_singleStep = false;
        // #if B2_ENABLE_PARTICLE
        this.m_strictContacts = false;
        // #endif
    };
    Settings.prototype.Save = function () { };
    Settings.prototype.Load = function () { };
    return Settings;
}());
exports.Settings = Settings;
