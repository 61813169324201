import './index.scss';
let last_color = 'blue',
    open = false,
    last_open = false;
export default function BookCard({
    style,
    data,
    current,
    onClick,
    color
}) {
    function catchColor(current) {
        if (color) {
            return color;
        }
        if ((current + 1) % 2 === 0) {
            last_open = open;
            open = !open;
            let color = last_color === 'blue' ? 'yellow' : 'blue';
            last_color = color;
            return color;
        } else {
            return last_color;
        }
    }
    return <div
        onClick={onClick}
        className='book-card-container'
        style={{
            ...style, ...{
                backgroundImage: `url(${data?.cover})`
            }
        }}
    >
        <div className={`title-container ${catchColor(current)}`}>
            <div className={`title`}>
                {data?.title}
            </div>
        </div>
    </div>
}